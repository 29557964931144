/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PersonRelation { 
    /**
     * id of the first person of the realtionship
     */
    p1id?: number;
    /**
     * id of the second person of the realtionship
     */
    p2id?: number;
    /**
     * kind of relationship
     */
    relationship?: string;
}
