/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PhysicalSupport { 
    /**
     * ID of the physical support
     */
    id?: number;
    linkId?: number;
    /**
     * city where physical support is located
     */
    city: string;
    /**
     * place where physical support is locate
     */
    conservationPlace: string;
    /**
     * inventory of the physical support
     */
    inventory: string;
    /**
     * inventory of the physical support
     */
    physicalDescription?: string;
    /**
     * height of the physical support
     */
    height?: string;
    /**
     * width of the physical support
     */
    width?: string;
    /**
     * the material the physical support is made of (papyrus, clay tablet, slate, parchment)
     */
    material?: string;
    /**
     * comment
     */
    comment?: string;
    state?: string;
    /**
     * tags divisi da un separatore tipo ,
     */
    tags?: Array<string>;
    monthCreation?: string;
    monthLastUpdate?: string;
    userCreation?: string;
    userLastUpdate?: string;
}
