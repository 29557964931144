import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DefaultService, Bibliography, BookSeries, Book } from '../rest';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent, of } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Citation } from '../rest/model/citation';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './publication-citation.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationCitationModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public citations;
  
  citationsColumns = ['documentId', 'role', 'number', 'pages', 'plate', 'tome', 'createdAt', 'userCreation', 'gotodoc']
  @ViewChild('citationsPaginator', { read: MatPaginator, static: false }) citationsPaginator: MatPaginator;
  citationsDataSource: CitationsDataSource;

  ngOnInit() {
    this.citationsDataSource = new CitationsDataSource()
    this.citationsDataSource.loadCitations(this.citations);
  }

  ngAfterViewInit() {
    merge(this.citationsPaginator.page)
      .pipe(
        tap(() => this.loadCitations(this.citations, false))
      )
      .subscribe();
  }

  loadCitations(citations, pageIndexReset = true) {
    if (pageIndexReset == true) {
      this.citationsPaginator.pageIndex = 0
    }
    this.citationsDataSource.loadCitations(
      citations,
      this.citationsPaginator.pageIndex,
      this.citationsPaginator.pageSize
    )
  }

  selectDoc(documentId: any): void {
    window.open("/docdetail/" + documentId, "_blank")
  }
}

export class CitationsDataSource implements DataSource<Citation> {

  public lessonsSubject = new BehaviorSubject<Citation[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor() { }

  connect(collectionViewer: CollectionViewer): Observable<Citation[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadCitations(citationsArray, pageIndex: number = 0, pageSize: number = 20) {
    this.totalCount = citationsArray.length
    this.lessonsSubject.next(citationsArray.slice(pageIndex*pageSize, pageIndex*pageSize + pageSize));
  }
}

export class PublicationDataSource implements DataSource<Bibliography> {

  public lessonsSubject = new BehaviorSubject<Bibliography[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Bibliography[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPublications(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesList = null, bookList = null, typeList = null) {
    this.defaultApi.readAllBibliographies(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesList, bookList, typeList).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.publications);
      });
  }
}

export class BookSeriesDataSource implements DataSource<BookSeries> {

  public lessonsSubject = new BehaviorSubject<BookSeries[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<BookSeries[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadBookSeries(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesId = null) {
    this.defaultApi.readBookSeries(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesId).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.bookSeries);
      });
  }
}

export class BookDataSource implements DataSource<Book> {

  private lessonsSubject = new BehaviorSubject<Book[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private selectedTypes = null

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Book[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadBooks(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesList = null, bookId = null) {
    this.defaultApi.readBook(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesList, bookId).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.books);
      });
  }
}

@Component({
  selector: 'publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationComponent implements OnInit {

  publicationSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  bookSeriesSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  bookSelection: SelectionModel<any> = new SelectionModel<any>(false, []);

  displayedColumns = ['select', 'id', 'publication', 'authors', 'title', 'number', 'year', 'url', 'pages', 'place', 'plate', /*'fromImport',*/ 'createdAt', 'userCreation', 'citations', 'modify', 'delete'];
  displayedBookSeriesColumns = ['select', 'id', 'abbreviation', 'extendedTitle', /*'fromImport',*/ 'createdAt', 'userCreation', 'modify', 'delete'];
  displayedBookColumns = ['select', 'id', 'title', 'extendedTitle', 'volume', 'authors', 'place', 'year', /*'fromImport',*/ 'createdAt', 'userCreation', 'modify', 'delete'];
  @ViewChild('publicationPaginator', { read: MatPaginator, static: false }) publicationPaginator: MatPaginator;
  @ViewChild('publicationSort', { read: MatSort, static: false }) publicationSort: MatSort;
  @ViewChild('bookSeriesPaginator', { read: MatPaginator, static: false }) bookSeriesPaginator: MatPaginator;
  @ViewChild('bookSeriesSort', { read: MatSort, static: false }) bookSeriesSort: MatSort;
  @ViewChild('bookPaginator', { read: MatPaginator, static: false }) bookPaginator: MatPaginator;
  @ViewChild('bookSort', { read: MatSort, static: false }) bookSort: MatSort;

  dataSource: PublicationDataSource;
  bookSeriesDataSource: BookSeriesDataSource;
  bookDataSource: BookDataSource;

  lastFilter: string = ''
  lastBookSeriesFilter: string = ''
  lastBookFilter: string = ''

  publicationSelected: number = null;
  bookSeriesSelected: number = null;
  bookSelected: number = null;

  gsElementArray: any = {}

  addBibliographyDetail: any = {}
  addBookV2Detail: any = {}
  addContainerBookSeriesDetail: any = {}
  addBookSeriesDetail: any = {}

  importBtnIsCheckedPublication: Boolean = false;
  importBtnIsCheckedBookSeries: Boolean = false;
  importBtnIsCheckedBook: Boolean = false;

  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.bookDataSource = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadPublications()
    this.bookSeriesDataSource.loadBookSeries()
    this.bookDataSource.loadBooks()

    this.gsElementArray[0] = { value: 'principal', checked: false }
    this.gsElementArray[1] = { value: 'otherIdentifier', checked: false }
    this.gsElementArray[2] = { value: 'edition', checked: false }
    this.gsElementArray[3] = { value: 'bibliography', checked: false }
    this.gsElementArray[4] = { value: 'facsimile', checked: false }
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;
  @ViewChild('lastBookFilterVC', { static: false }) lastBookFilterVC: ElementRef;
  @ViewChild('lastBookSeriesFilterVC', { static: false }) lastBookSeriesFilterVC: ElementRef;

  ngAfterViewInit() {
    merge(this.publicationSort.sortChange, this.publicationPaginator.page)
      .pipe(
        tap(() => this.changePublicationPage(this.lastFilter, false))
      )
      .subscribe();

    merge(this.bookSeriesSort.sortChange, this.bookSeriesPaginator.page)
      .pipe(
        tap(() => this.loadBookSeries(this.lastBookSeriesFilter, false))
      )
      .subscribe();

    merge(this.bookSort.sortChange, this.bookPaginator.page)
      .pipe(
        tap(() => this.loadBooks(this.lastBookSeriesFilter, false))
      )
      .subscribe();

    fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => this.loadPublications(text));

    fromEvent(this.lastBookFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => this.loadBooks(text));

    fromEvent(this.lastBookSeriesFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => this.loadBookSeries(text));
  }

  changePublicationPage(filterValue: string = '', pageIndexReset = true) {
    if (this.publicationSelected != null) {
      this.bookSelected = null
      this.bookSeriesSelected = null
      this.loadBooks(this.lastBookFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
    }
    this.publicationSelected = null;
    this.loadPublications(filterValue, pageIndexReset)
  }

  loadPublications(filterValue: string = '', pageIndexReset = true) {
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.publicationPaginator.pageIndex = 0
    }
    let typeList = null
    Object.keys(this.gsElementArray).forEach(element => {
      if (this.gsElementArray[element].checked == true) {
        if (typeList == null) {
          typeList = []
        }
        typeList.push(this.gsElementArray[element].value)
      }
    });
    if (this.importBtnIsCheckedPublication) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.dataSource.loadPublications(
      filterValue,
      this.publicationSort.active,
      this.publicationSort.direction,
      this.publicationPaginator.pageIndex,
      this.publicationPaginator.pageSize,
      this.bookSeriesSelected != null ? [this.bookSeriesSelected] : null,
      this.bookSelected != null ? [this.bookSelected] : null,
      typeList
    )
  }

  changeBookSeriesPage(filterValue: string = '', pageIndexReset = true) {
    if (this.bookSeriesSelected != null) {
      this.publicationSelected = null
      this.bookSelected = null
      this.loadBooks(this.lastBookFilter);
      this.loadPublications(this.lastBookSeriesFilter);
    }
    this.bookSeriesSelected = null;
    this.loadBookSeries(filterValue, pageIndexReset)
  }

  loadBookSeries(filterValue: string = '', pageIndexReset = true) {
    if (pageIndexReset == true) {
      this.bookSeriesPaginator.pageIndex = 0
    }
    if (this.importBtnIsCheckedBookSeries) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.bookSeriesDataSource.loadBookSeries(
      filterValue,
      this.bookSeriesSort.active,
      this.bookSeriesSort.direction,
      this.bookSeriesPaginator.pageIndex,
      this.bookSeriesPaginator.pageSize,
      this.bookSeriesSelected != null ? [this.bookSeriesSelected] : null
    )
  }

  changeBookPage(filterValue: string = '', pageIndexReset = true) {
    if (this.bookSelected != null) {
      this.bookSeriesSelected = null
      this.publicationSelected = null
      this.loadBookSeries(this.lastBookFilter);
      this.loadPublications(this.lastBookSeriesFilter);
    }
    this.bookSelected = null;
    this.loadBooks(filterValue, pageIndexReset)
  }

  loadBooks(filterValue: string = '', pageIndexReset = true) {
    this.lastBookFilter = filterValue
    if (pageIndexReset == true) {
      this.bookPaginator.pageIndex = 0
    }
    if (this.importBtnIsCheckedBook) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.bookDataSource.loadBooks(
      filterValue,
      this.bookSort.active,
      this.bookSort.direction,
      this.bookPaginator.pageIndex,
      this.bookPaginator.pageSize,
      this.bookSeriesSelected != null ? [this.bookSeriesSelected] : null,
      this.bookSelected != null ? [this.bookSelected] : null
    )
  }

  selectPublication(row: any): void {
    this.publicationSelection.toggle(row);
    if (this.publicationSelection.isSelected(row)) {
      this.publicationSelected = row.id;
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookDataSource = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      if (row.book != null) {
        console.log(row.book);
        if (row.book.bookSeries != null) {
          this.bookSeriesSelected = row.book.bookSeries.id;
        } else {
          this.bookSeriesSelected = -1;
        }
        this.bookSelected = row.book.id;
      } else {
        this.bookSelected = -1;
        this.bookSeriesSelected = -1;
      }
      this.loadBooks(this.lastBookFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookDataSource = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.publicationSelected = null;
      this.bookSeriesSelected = null;
      this.bookSelected = null;
      this.loadBooks(this.lastBookFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
      this.loadPublications(this.lastFilter);
    }
  }

  selectBookSeries(row: any): void {
    this.bookSeriesSelection.toggle(row);
    if (this.bookSeriesSelection.isSelected(row)) {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookDataSource = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesSelected = row.id;
      this.loadPublications(this.lastFilter)
      this.loadBooks(this.lastBookFilter)
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookDataSource = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSelected = null;
      this.bookSeriesSelected = null;
      this.publicationSelected = null;
      this.loadPublications(this.lastFilter)
      this.loadBooks(this.lastBookFilter)
      this.loadBookSeries(this.lastBookSeriesFilter)
    }
  }

  selectBook(row: any): void {
    this.bookSelection.toggle(row);
    if (this.bookSelection.isSelected(row)) {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSelected = row.id;
      if (row.bookSeries != null) {
        this.bookSeriesSelected = row.bookSeries.id;
      } else {
        this.bookSeriesSelected = -1;
      }
      this.loadPublications(this.lastFilter)
      this.loadBookSeries(this.lastBookSeriesFilter)
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookDataSource  = new BookDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSelected = null;
      this.bookSeriesSelected = null;
      this.publicationSelected = null;
      this.loadPublications(this.lastFilter)
      this.loadBookSeries(this.lastBookSeriesFilter);
      this.loadBooks(this.lastBookFilter);
    }
  }

  bookSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addBibliographyDetail.book = null} return []}))
        : this.documentApi.readBook(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
          map(value => {
            if (value.books.length > 0) {
              return value.books
            } else {
              return []
            }
          })
        ))
    )

  bibliographyBookFormatter = (x: Book) => {
    this.addBibliographyDetail.year = x.year
    return this.bibliographyStringifyBook(x)
  }

  bibliographyStringifyBook(x: Book): string {
    if (x.id == null) {
      return null;
    }
    return x.title;
  }

  modifyBibliographyModal(addBibliographyModalTemplate, toModify): void {
    this.addBibliographyDetail = Object.assign({}, toModify)
    this.addBibliographyDetail.windowTitle = 'Modify '
    if (this.addBibliographyDetail.url != null){
      this.urls = this.addBibliographyDetail.url.split('||^||').filter(Boolean); // fill url list
    } else {this.urls = [];} // url list is empty
    this.modalService.open(addBibliographyModalTemplate, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      this.addBibliographyDetail.modified = true;
      this.addBibliographyDetail.citations = [];
      this.addBibliographyDetail.url = '';
      for (var i = 0; i < this.urls.length; i++) {
        this.addBibliographyDetail.url = this.addBibliographyDetail.url.concat(this.urls[i]);
        this.addBibliographyDetail.url = this.addBibliographyDetail.url.concat('||^||'); // delimiter
      }
      this.documentApi.updateBibliographyId(toModify.id, this.addBibliographyDetail, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => { this.loadPublications(this.lastFilter, false) })
    }, (reason) => {
    });
  }

  showCitations(bibliographyItem) {
    const modalRef = this.modalService.open(PublicationCitationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.citations = bibliographyItem.citations
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  modifyBook(addBookModal, toModify = null): void {
    if (toModify == null) {
      this.addBookV2Detail.bookSeries = null;
      this.addBookV2Detail.bookSeriesId = null;
      this.addBookV2Detail.title = null;
      this.addBookV2Detail.volume = null;
      this.addBookV2Detail.authors = null;
      this.addBookV2Detail.place = null;
      this.addBookV2Detail.year = null;
      this.addBookV2Detail.titleOther = null;
      this.addBookV2Detail.publicationVolume = null;
      this.addBookV2Detail.type = null;
      this.addBookV2Detail.extendedTitle = null;
    } else {
      this.addBookV2Detail = Object.assign({}, toModify)
      if (this.addBookV2Detail.title == this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume) {
        this.addBookV2Detail.titleOther = 1
      } else if (this.addBookV2Detail.title == this.addBookV2Detail.authors + ' ' + this.addBookV2Detail.year) {
        this.addBookV2Detail.titleOther = 2
      } else if (this.addBookV2Detail.title == this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume + ' ' + this.addBookV2Detail.year) {
        this.addBookV2Detail.titleOther = 3
      } else if (this.addBookV2Detail.title == this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.year) {
        this.addBookV2Detail.titleOther = 4
      } else {
        this.addBookV2Detail.titleOther = 'Other'
      }
    }

    this.modalService.open(addBookModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      let newBook: Book = {
        bookSeries: {
          id: this.addBookV2Detail.bookSeriesId
        },
        title: this.addBookV2Detail.title,
        volume: this.addBookV2Detail.volume,
        authors: this.addBookV2Detail.authors,
        place: this.addBookV2Detail.place,
        year: this.addBookV2Detail.year,
        extendedTitle: this.addBookV2Detail.extendedTitle,
        publicationVolume: this.addBookV2Detail.publicationVolume,
        type: this.addBookV2Detail.type
      }
      if (this.addBookV2Detail.titleOther != 'Other') {
        if (this.addBookV2Detail.titleOther == "1") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume
        } else if (this.addBookV2Detail.titleOther == "2") {
          newBook.title = this.addBookV2Detail.authors + ' ' + this.addBookV2Detail.year
        } else if (this.addBookV2Detail.titleOther == "3") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.volume + ' ' + this.addBookV2Detail.year
        } else if (this.addBookV2Detail.titleOther == "4") {
          newBook.title = this.addBookV2Detail.bookSeries.abbreviation + ' ' + this.addBookV2Detail.year
        }
      }
      if (toModify == null) {
        this.documentApi.createBook(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addBibliographyDetail.book = value;
            this.addBibliographyDetail.year = newBook.year;
          }
        )
      } else {
        this.documentApi.updateBookId(toModify.id, newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.loadBooks(this.lastBookFilter, false)
          }
        )
      }
    }, (reason) => {
    });
  }

  addContainerBookSeriesModal(addBookSeriesModal): void {
    this.addContainerBookSeriesDetail.title = null;
    this.addContainerBookSeriesDetail.abbreviation = null;
    this.modalService.open(addBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      let newBook: BookSeries = {
        extendedTitle: this.addContainerBookSeriesDetail.title,
        abbreviation: this.addContainerBookSeriesDetail.abbreviation,
      }
      this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.addBookSeriesDetail.containerSeriesId = value.id;
          this.addBookSeriesDetail.containerSeries = value;
          this.loadBookSeries(this.lastBookSeriesFilter, false)
        }
      )
    }, (reason) => {
    });
  }

  refresh(): void {
    this.dataSource.loadPublications(
      this.lastFilter,
      this.publicationSort.active,
      this.publicationSort.direction,
      this.publicationPaginator.pageIndex,
      this.publicationPaginator.pageSize)

    this.bookDataSource.loadBooks(
      this.lastBookFilter,
      this.bookSort.active,
      this.bookSort.direction,
      this.bookPaginator.pageIndex,
      this.bookPaginator.pageSize
    )

    this.bookSeriesDataSource.loadBookSeries(
      this.lastBookSeriesFilter,
      this.bookSeriesSort.active,
      this.bookSeriesSort.direction,
      this.bookSeriesPaginator.pageIndex,
      this.bookSeriesPaginator.pageSize
    )
  }

  identifierBookSeriesFormatter = (x: BookSeries) => {
    this.addBookV2Detail.bookSeriesId = x.id
    return this.stringifyBookSeries(x)
  }
  identifierContainerBookSeriesFormatter = (x: BookSeries) => {
    this.addBookSeriesDetail.containerSeriesId = x.id
    return this.stringifyContainerBookSeries(x)
  }

  stringifyBookSeries(x: BookSeries): string {
    if (x.id == null) {
      return null
    }
    return x.abbreviation + (x.extendedTitle == null ? "" : " - " + x.extendedTitle);
  }
  stringifyContainerBookSeries(x: BookSeries): string {
    if (x.id == null) {
      return null
    }
    return x.abbreviation + (x.extendedTitle == null ? "" : " - " + x.extendedTitle);
  }

  bookSeriesBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addBookSeriesDetail.containerBookSeries = null; this.addBookSeriesDetail.containerSeriesId = null} return []}))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  bookBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addBookV2Detail.bookSeriesId = null; this.addBookV2Detail.bookSeries = null; } return []}))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  modifyBookSeries(addBookSeriesModal, toModify = null): void {
    if (toModify == null) {
      this.addBookSeriesDetail.title = null;
      this.addBookSeriesDetail.abbreviation = null;
      this.addBookSeriesDetail.containerSeries = null,
        this.addBookSeriesDetail.containerSeriesId = null
    } else {
      this.addBookSeriesDetail = Object.assign({}, toModify)
    }

    this.modalService.open(addBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      let newBook: BookSeries = {
        extendedTitle: this.addBookSeriesDetail.extendedTitle,
        abbreviation: this.addBookSeriesDetail.abbreviation,
        containerBookSeries: {
          id: this.addBookSeriesDetail.containerSeriesId
        }
      }
      if (toModify == null) {
        this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addBookV2Detail.bookSeriesId = value.id;
            this.addBookV2Detail.bookSeries = value;
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      } else {
        this.documentApi.updateBookSeriesById(toModify.id, newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      }

    }, (reason) => {
    });
  }

  deleteBibliography(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this bibliography item?')) {
      this.documentApi.deleteBibliographyId(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadPublications(this.lastFilter, false);
        }
      )
    }
  }

  deleteBookSeries(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this book series?')) {
      this.documentApi.deleteBookSeriesById(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadBookSeries(this.lastBookSeriesFilter, false);
        }
      )
    }
  }

  deleteBook(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this book?')) {
      this.documentApi.deleteBookId(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadBooks(this.lastBookFilter, false);
        }
      )
    }
  }

  urls: string[] = [];

  openURL(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }


  toggleCheckImportPublicationBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedPublication = true;
      this.loadPublications();
    } else {
      this.importBtnIsCheckedPublication = false;
      this.loadPublications();
    }
  }

  toggleCheckImportBSBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedBookSeries = true;
      this.loadBookSeries();
    } else {
      this.importBtnIsCheckedBookSeries = false;
      this.loadBookSeries();
    }
  }

  toggleCheckImportBooksBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedBook = true;
      this.loadBooks();
    } else {
      this.importBtnIsCheckedBook = false;
      this.loadBooks();
    }
  }
}
