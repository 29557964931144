import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DefaultService, GraphicSymbol } from '../rest';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl, NgForm } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


export class GraphicSymbolDataSource implements DataSource<GraphicSymbol> {

  private lessonsSubject = new BehaviorSubject<GraphicSymbol[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0
  
  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<GraphicSymbol[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadGraphicSymbols(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    //this.defaultApi.readAllGS(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, filter).subscribe(
    this.defaultApi.readAllGS(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.graphicSymbols);
      });
  }

}


@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './graphicsymbols-detail.component.html',
  styleUrls: ['./graphicsymbols.component.css']
})
export class GsDetailModal implements OnInit {
  tagsGS: any[];
  suggestedTagsGS: any[];

  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService, private modalService: NgbModal) { }

  @Input() public toModify;
  @Input() public mainApp;
  private addGsDetail: any = {};
  private gsElementArray: any = {}
  private currentPictureX = null
  private enhancedPictureX_1 = null
  private enhancedPictureX_2 = null
  private urlsTP: string[];
  private urlsTPPrefixes: Map<string,string>;
  
  ngOnInit() {
    Object.assign(this.addGsDetail, this.toModify)
    this.currentPictureX = null
    this.enhancedPictureX_1 = null
    this.enhancedPictureX_2 = null
    this.gsElementArray[0] = { value: 'Alphabetical Signs', checked: false }
    this.gsElementArray[1] = { value: 'Tachigraphic / Tironian notes', checked: false }
    this.gsElementArray[2] = { value: 'Strokes', checked: false }
    this.checkboxFromString(this.addGsDetail.elements, this.gsElementArray)
    this.addTagsGSFromDB(this.addGsDetail.id);
    this.addSuggestedTagsGSFromDB();
    //fill "Other Authors" list
    if (this.addGsDetail.otherAuthors != null) {
      this.selectedAuthorsGS = this.addGsDetail.otherAuthors.split("||^||").filter(Boolean);
    } else {this.selectedAuthorsGS = []}
    if (this.addGsDetail.picture != null) {
      let pictureSplit = this.addGsDetail.picture.split(';base64,')
      this.documentApi.fileDownload(pictureSplit[1], this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.currentPictureX = pictureSplit[0] + ';base64,' + value.file
        }
      )
      //console.log(pictureSplit)
      let pictureSplit_enhanced_1 = "enhanced_images/output_version1/"+ pictureSplit[1] + "_enhanced0.png"
      this.documentApi.fileDownload(pictureSplit_enhanced_1, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          if(value!=null){
            this.enhancedPictureX_1 = pictureSplit[0] + ';base64,' + value.file
          }
        }
      )
      let pictureSplit_enhanced_2 = "enhanced_images/output_version2/"+ pictureSplit[1] + "_enhanced4.png"
      this.documentApi.fileDownload(pictureSplit_enhanced_2, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          if(value!=null){
            this.enhancedPictureX_2 = pictureSplit[0] + ';base64,' + value.file
          }
        }
      )
    }
  }

  ngAfterViewInit() {}

  closeModal(saveAlert: any): void {
    this.documentApi.getHand(this.addGsDetail.handId, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        this.addGsDetail.elements = this.stringFromCheckbox(this.gsElementArray);
        // other authors
        var typeBUFFER = ''
        this.selectedAuthorsGS.forEach(t => {
          typeBUFFER = typeBUFFER.concat(t)
          typeBUFFER = typeBUFFER.concat('||^||')//delimiter
        });
        if (typeBUFFER != ''){
          this.addGsDetail.otherAuthors = typeBUFFER
        } else {this.addGsDetail.otherAuthors=''}
        this.documentApi.updateGSid(value.documentId,this.addGsDetail.handId, this.addGsDetail.id, this.addGsDetail, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {this.modal.close(value)});
        // update or create tags
        this.updateGeneralTagsToDB(this.addGsDetail.id, this.tagsGS, 'graphic-symbols', 'tags-gs')
      }
    );
  }

  checkboxFromString(s, elementArray): void {
    Object.keys(elementArray).forEach(function (drId) {
      if (s != null) {
        if (s.includes(elementArray[drId].value + ";")) {
          elementArray[drId].checked = true
        } else {
          elementArray[drId].checked = false
        }
      }
    })
  }

  stringFromCheckbox(elementArray): string {
    let s = null
    Object.keys(elementArray).forEach(function (drId) {
      if (elementArray[drId].checked == true) {
        if (s == null) {
          s = ""
        }
        s += elementArray[drId].value + "; "
      }
    })
    return s
  }

  private addTagsGSFromDB(gsId: any): void {
    this.tagsGS = [];
    this.documentApi.retrieveTags('graphic-symbols', gsId, 'tags-gs', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value.value);
          this.tagsGS = value.value.split('||^||').filter(Boolean);
        }
      }
    );
  }

  private addSuggestedTagsGSFromDB(): void {
    this.suggestedTagsGS = [];
    this.documentApi.retrieveSuggestedTags('graphic-symbols', 'tags-gs', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value);
          this.suggestedTagsGS = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedTagsGS = this.suggestedTagsGS.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

  private updateGeneralTagsToDB(gsId: any, tags: any, table_name: string, table_field_name: string): void {
    let updatedTagsGS = {
      table: table_name,
      table_id: gsId,
      tagged_field: table_field_name,
      value: ""
    }
    if (tags != null) {
      tags.forEach(function (item, index) {
        updatedTagsGS.value = updatedTagsGS.value.concat(item);
        updatedTagsGS.value = updatedTagsGS.value.concat('||^||');
      });
      this.documentApi.updateTags(gsId, updatedTagsGS, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {}
      )
    }
  }

  handleFileInput(files: FileList) {
    this.documentApi.fileUpload(this.mainApp.user.email, this.mainApp.user.idToken, files.item(0)).subscribe(
      value => {
        this.addGsDetail.picture = value.fileId
        var reader = new FileReader();
        reader.readAsDataURL(files.item(0));
        reader.onload = (_event) => {
          this.currentPictureX = reader.result;
          this.addGsDetail.picture = this.currentPictureX.split(';base64,')[0] + ';base64,' + this.addGsDetail.picture
          console.log(this.addGsDetail.picture)
        }
      })
  }

  /*handleFileENHANCEDInput(files: FileList) {
    this.documentApi.fileENHANCEDUpload(this.mainApp.user.email, this.mainApp.user.idToken, files.item(0), this.addGsDetail.picture).subscribe(
      value => {
        //this.gsDetail.picture = value.fileId
        var reader = new FileReader();
        console.log(files.item(0))
        reader.readAsDataURL(files.item(0));
        reader.onload = (_event) => {
          this.enhancedPictureX = reader.result;
          //this.gsDetail.picture = this.enhancedPictureX.split(';base64,')[0] + ';base64,' + this.gsDetail.picture
          console.log(reader.result)
        }
      })
  }*/

  // image popup overlay
  showImagePopup = false;
  showImageENHANCEDPopup_1 = false;
  showImageENHANCEDPopup_2 = false;

  private openImagePopup(value: boolean): void {
    this.showImagePopup = value;
  }

  private openImageENHANCEDPopup_1(value: boolean): void {
    this.showImageENHANCEDPopup_1 = value;
  }
  private openImageENHANCEDPopup_2(value: boolean): void {
    this.showImageENHANCEDPopup_2 = value;
  }


  // multiple authors
  authorListGS: string[] =  ['antonella.ghignoli@uniroma1.it', 'nina.sietis@uniroma1.it', 'anna.monte@uniroma1.it'
 , 'maria.boccuzzi@uniroma1.it', 'livia.briasco@uniroma1.it' , 'aneta.skalec@uniroma1.it'
 , 'marta.marucci@uniroma1.it', 'luciaconsuelo.colella@uniroma1.it']
  selectedAuthorsGS: string[] = [];

  dropdownAuthorSettings:IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 15,
    allowSearchFilter: false,
    enableCheckAll:false,
    maxHeight: 400
  };

}


@Component({
  selector: 'graphicsymbols',
  templateUrl: './graphicsymbols.component.html',
  styleUrls: ['./graphicsymbols.component.css']
})
export class GraphicSymbolComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('tagInput', {static: false}) tagInputGS: ElementRef<HTMLInputElement>;
  @ViewChild('gsForm', { read: NgForm, static: false }) gsForm;

  graphicSymbols: any;
  displayedColumns = ['id', 'shape', 'category', 'elements', 'description', 'position', 'tags', 'docId', 'principalIdentifier', 'handOrdinal', 'handScript', 'handPosition', 'image', /*'fromImport',*/ 'createdAt', 'updatedAt', 'userCreation', 'modify', 'delete'];
  dataSource: GraphicSymbolDataSource;
  lastFilter: string = ''
  gsSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  gsSelected: number = null;
  gsDetail: any = {};
  gsElementArray: any = {}
  currentPictureX = null
  //enhancedPictureX = null
  enhancedPictureX_1 = null
  enhancedPictureX_2 = null
  importBtnIsChecked: Boolean = false;
  isAdvancedSearch = false
  lastDocumentFilter: string = ''
  filterfield: string = 'all'
  tagsGSDictionary = {}

  // show validated assets
  showValidated: Boolean = false;
  showNotValidated: Boolean = false;
  showAll: Boolean = true;


  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent, private router: Router,
    private modalService: NgbModal) { }

    ngOnInit() {
      this.dataSource = new GraphicSymbolDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
      this.dataSource.loadGraphicSymbols()
      this.showAll = true;
    }
  
    @ViewChild('lastFilterVC', {static: false}) lastFilterVC: ElementRef;
  
    ngAfterViewInit() {
      // on sort or paginate events, load a new page
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => this.loadGraphicSymbols(this.lastFilter, false))
        )
        .subscribe();
  
      fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
        // get value
        map((evt: any) => evt.target.value),
        // text length must be > 2 chars
        //.filter(res => res.length > 2)
        // emit after 1s of silence
        debounceTime(500),
        // emit only if data changes since the last emit       
        distinctUntilChanged())
        // subscription
        .subscribe((text: string) => {
        if (!this.isAdvancedSearch) {
          this.removeAllTags()
        }
        if ((text || '').trim()) {
          this.tagsGSDictionary[this.filterfield] = text
        } else {
          this.removeTagGS(this.filterfield)
        }
        this.loadGraphicSymbols(text)
      });
    }
  
    loadGraphicSymbols(filterValue: string = '', pageIndexReset = true) {
      let thisObject = this
      this.lastFilter = filterValue
      if (pageIndexReset == true) {
        this.paginator.pageIndex = 0
      }
      let actualFilter = ""
      Object.keys(this.tagsGSDictionary).forEach(function (key) {
        if (thisObject.tagsGSDictionary[key] != "") {
          if (actualFilter != "") {
            actualFilter += "##^##"
          }
          actualFilter += key + '||^||' + thisObject.tagsGSDictionary[key]
        }
      });
      if (actualFilter == "") {
        actualFilter = this.filterfield + '||^||' + filterValue
      }
      if (this.importBtnIsChecked) { // if the "from import" option is selected...
        actualFilter += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
      }
      if (this.showValidated) { 
        actualFilter += '**^**' + 'validated'
      }
      if (this.showNotValidated) { 
        actualFilter += '**^**' + 'notValidated'
      }
      this.dataSource.loadGraphicSymbols(
        actualFilter,
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
    }

    removeAllTags(): void {
      let thisObject = this
      Object.keys(this.tagsGSDictionary).forEach(function (key) { delete thisObject.tagsGSDictionary[key]; });
    }
  
    clearTags(): void {
      this.removeAllTags()
      this.lastFilterVC.nativeElement.value = ""
      this.lastFilter = ""
      this.loadGraphicSymbols()
    }
  
    removeTagGS(tag: string): void {
      delete this.tagsGSDictionary[tag]
      if (this.filterfield == tag) {
        this.lastFilterVC.nativeElement.value = ""
        this.lastFilter = ""
      }
      this.loadGraphicSymbols(this.lastFilter)
    }
  
    changeAdvancedSearch(completed: boolean) {
      this.removeAllTags()
      if ((this.lastFilter || '').trim()) {
        this.tagsGSDictionary[this.filterfield] = this.lastFilter
      }
      this.loadGraphicSymbols(this.lastFilter)
    }
  
    onFieldChange(ob) {
      if (this.isAdvancedSearch) {
        this.lastFilterVC.nativeElement.value = this.tagsGSDictionary[this.filterfield] || ""
        this.lastFilter = this.tagsGSDictionary[this.filterfield] || ""
      } else {
        this.removeAllTags()
        if ((this.lastFilter || '').trim()) {
          this.tagsGSDictionary[this.filterfield] = this.lastFilter
        }
        this.loadGraphicSymbols(this.lastFilter)
      }
    }
  
    refresh(): void {
      //console.log(this.lastFilter)
      //console.log(this.filterfield)
      this.dataSource.loadGraphicSymbols(
        this.lastFilter,
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex,
        this.paginator.pageSize)
    }

    selectedGS: GraphicSymbol;
    onSelect(gs: GraphicSymbol): void {
      this.selectedGS = gs;
    }
  
    selectGS(row: any): void {
      this.gsSelection.toggle(row);
      if (this.gsSelection.isSelected(row)) {
        this.gsSelected = row.id;
        //console.log(row);
        this.currentPictureX = null
        //this.enhancedPictureX = null
        this.enhancedPictureX_1 = null
        this.enhancedPictureX_2 = null
        this.gsElementArray[0] = { value: 'Alphabetical Signs', checked: false }
        this.gsElementArray[1] = { value: 'Tachigraphic / Tironian notes', checked: false }
        this.gsElementArray[2] = { value: 'Strokes', checked: false }
        this.gsDetail = Object.assign({}, row)
        this.checkboxFromString(this.gsDetail.elements, this.gsElementArray)
        this.addTagsGSFromDB(this.gsDetail.id);
        this.addSuggestedTagsGSFromDB();
        if (this.gsDetail.picture != null) {
          let pictureSplit = this.gsDetail.picture.split(';base64,')
          this.documentApi.fileDownload(pictureSplit[1], this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              this.currentPictureX = pictureSplit[0] + ';base64,' + value.file
            }
          )
          //console.log(pictureSplit)
          let pictureSplit_enhanced_1 = "enhanced_images/output_version1/"+ pictureSplit[1] + "_enhanced0.png"
          this.documentApi.fileDownload(pictureSplit_enhanced_1, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              if(value!=null){
                this.enhancedPictureX_1 = pictureSplit[0] + ';base64,' + value.file
              }
            }
          )
          let pictureSplit_enhanced_2 = "enhanced_images/output_version2/"+ pictureSplit[1] + "_enhanced4.png"
          this.documentApi.fileDownload(pictureSplit_enhanced_2, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {
              if(value!=null){
                this.enhancedPictureX_2 = pictureSplit[0] + ';base64,' + value.file
              }
            }
          )
        }
      } else {
        this.dataSource = new GraphicSymbolDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
        this.gsSelected = null;
        this.loadGraphicSymbols(this.lastFilter);
      }
    }

    checkboxFromString(s, elementArray): void {
      Object.keys(elementArray).forEach(function (drId) {
        if (s != null) {
          if (s.includes(elementArray[drId].value + ";")) {
            elementArray[drId].checked = true
          } else {
            elementArray[drId].checked = false
          }
        }
      })
    }

    stringFromCheckbox(elementArray): string {
      let s = null
      Object.keys(elementArray).forEach(function (drId) {
        if (elementArray[drId].checked == true) {
          if (s == null) {
            s = ""
          }
          s += elementArray[drId].value + "; "
        }
      })
      return s
    }
  

    deleteGS(gsid: number, handid: number): void {
      if (confirm("Are you sure you want to delete this graphic symbol?")) {
        this.documentApi.getHand(handid,this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.documentApi.deleteGSid(value.documentId, handid, gsid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
              value => {
                console.log(value);
                this.dataSource.loadGraphicSymbols(
                  this.lastFilter,
                  this.sort.active,
                  this.sort.direction,
                  this.paginator.pageIndex,
                  this.paginator.pageSize)
              }
            )
          }
        )
      }
    }

    showRelatedDoc(event: MouseEvent, docid: any): void {
      //avoid to open GS details
      event.preventDefault();
      event.stopPropagation();

      window.open("/docdetail/" + docid, "_blank");
    }
  
    saveGS(row: any, saveAlert: any): void {
      this.documentApi.getHand(this.gsDetail.handId, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.gsDetail.elements = this.stringFromCheckbox(this.gsElementArray);
          this.documentApi.updateGSid(value.documentId,this.gsDetail.handId, this.gsDetail.id, this.gsDetail, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
            value => {});
          // update or create tags
          this.updateGeneralTagsToDB(this.gsDetail.id, this.tagsGS, 'graphic-symbols', 'tags-gs')

          this.modalService.open(saveAlert, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
            this.gsSelection.toggle(row);
            this.dataSource = new GraphicSymbolDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
            this.gsSelected = null;
            this.gsDetail = {};
            this.loadGraphicSymbols(this.lastFilter);
          }, (reason) => {
            this.gsSelection.toggle(row);
            this.dataSource = new GraphicSymbolDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
            this.gsSelected = null;
            this.gsDetail = {};
            this.loadGraphicSymbols(this.lastFilter);
          });
        }
      );
    }

    discardGS(row: any): void {
      this.gsSelection.toggle(row);
      this.dataSource = new GraphicSymbolDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.gsSelected = null;
      this.gsDetail = {};
      this.loadGraphicSymbols(this.lastFilter);
    }
    
    handleFileInput(files: FileList) {
      this.documentApi.fileUpload(this.mainApp.user.email, this.mainApp.user.idToken, files.item(0)).subscribe(
        value => {
          this.gsDetail.picture = value.fileId
          var reader = new FileReader();
          reader.readAsDataURL(files.item(0));
          reader.onload = (_event) => {
            this.currentPictureX = reader.result;
            this.gsDetail.picture = this.currentPictureX.split(';base64,')[0] + ';base64,' + this.gsDetail.picture
            console.log(this.gsDetail.picture)
          }
        })
    }

    /*handleFileENHANCEDInput(files: FileList) {
      this.documentApi.fileENHANCEDUpload(this.mainApp.user.email, this.mainApp.user.idToken, files.item(0)).subscribe(
        value => {
          //this.gsDetail.picture = value.fileId
          var reader = new FileReader();
          console.log(files.item(0))
          reader.readAsDataURL(files.item(0));
          reader.onload = (_event) => {
            this.enhancedPictureX = reader.result;
            //this.gsDetail.picture = this.enhancedPictureX.split(';base64,')[0] + ';base64,' + this.gsDetail.picture
            console.log(reader.result)
          }
        })
    }*/

    tagsGS: string[] = [];
    suggestedTagsGS: string[] = [];
  
    private addTagsGSFromDB(gsId: any): void {
      this.tagsGS = [];
      this.documentApi.retrieveTags('graphic-symbols', gsId, 'tags-gs', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          if (value != null) {
            //console.log(value.value);
            this.tagsGS = value.value.split('||^||').filter(Boolean);
          }
        }
      );
    }
  
    private addSuggestedTagsGSFromDB(): void {
      this.suggestedTagsGS = [];
      this.documentApi.retrieveSuggestedTags('graphic-symbols', 'tags-gs', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          if (value != null) {
            //console.log(value);
            this.suggestedTagsGS = value.suggestions.split('||^||').filter(Boolean);
            this.suggestedTagsGS = this.suggestedTagsGS.filter(function(item, pos, self) {
                return self.indexOf(item) == pos;}) // delete duplicates
          }
        }
      );
    }

    private updateGeneralTagsToDB(gsId: any, tags: any, table_name: string, table_field_name: string): void {
      let updatedTagsGS = {
        table: table_name,
        table_id: gsId,
        tagged_field: table_field_name,
        value: ""
      }
      if (tags != null) {
        tags.forEach(function (item, index) {
          updatedTagsGS.value = updatedTagsGS.value.concat(item);
          updatedTagsGS.value = updatedTagsGS.value.concat('||^||');
        });
        this.documentApi.updateTags(gsId, updatedTagsGS, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {}
        )
      }
    }


    // image popup overlay
    showImagePopup = false;
    showImageENHANCEDPopup = false;

    private openImagePopup(value: boolean): void {
      this.showImagePopup = value;
    }

    private openImageENHANCEDPopup(value: boolean): void {
      this.showImageENHANCEDPopup = value;
    }

    toggleCheckImportBtn(event): void {
      if ( event.target.checked ) {
        this.importBtnIsChecked = true;
        this.loadGraphicSymbols();
      } else {
        this.importBtnIsChecked = false;
        this.loadGraphicSymbols();
      }
    }

    toggleShowValidatedBtn(event): void {
      //console.log(event.srcElement.value)
      if (event.srcElement.value == 'validated'){
        if (event.target.checked) {
          this.showValidated = true;
          this.showNotValidated = false;
          this.showAll = false;
          this.loadGraphicSymbols();
        } else {
          this.showValidated = false;
          this.loadGraphicSymbols();
        }
      } else if (event.srcElement.value == 'notValidated'){
        if (event.target.checked) {
          this.showNotValidated = true;
          this.showValidated = false;
          this.showAll = false;
          this.loadGraphicSymbols();
        } else {
          this.showNotValidated = false;
          this.loadGraphicSymbols();
        }
      } else { // show all docs
        if (event.target.checked) {
          this.showAll = true;
          this.showNotValidated = false;
          this.showValidated = false;
          this.loadGraphicSymbols();
        } else {
          this.showAll = false;
          this.loadGraphicSymbols();
        }
      }
    }

    modifyGSModal(toModify = null) {
      const modalRef = this.modalService.open(GsDetailModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass'})
      modalRef.componentInstance.toModify = toModify
      modalRef.componentInstance.mainApp = this.mainApp
      modalRef.result.then((result) => {
        this.dataSource.loadGraphicSymbols(
          this.lastFilter,
          this.sort.active,
          this.sort.direction,
          this.paginator.pageIndex,
          this.paginator.pageSize)
      }, (reason) => {}).catch((res) => {});;
    }
    
  }