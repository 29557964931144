/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Tags { 
    id?: number;
    /**
     * the table that contains the tag
     */
    table?: string;
    /**
     * the id of the object that contains the tag inside the table
     */
    tableId?: number;
    /**
     * the field that uses the tag inside a certain table
     */
    taggedField?: string;
    /**
     * tags separated by this ||^|| delimiter
     */
    value?: string;
    userCreation?: string;
    userLastUpdate?: string;
    createdAt?: string;
    updatedAt?: string;
}
