/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TagsSuggestionResponse { 
    /**
     * tags separated by this ||^|| delimiter
     */
    suggestions?: string;
    /**
     * table-IDs of tags retrieved: the document and its related tags are separated to the others by !!-!!
     */
    tagsIds?: string;
}
