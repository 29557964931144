/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GraphicSymbol { 
    /**
     * graphic symbol id
     */
    id?: number;
    handId?: number;
    /**
     * category of the graphic symbol
     */
    shape?: string;
    /**
     * category of the graphic symbol
     */
    category?: string;
    /**
     * elements present in the category
     */
    elements?: string;
    /**
     * description of the graphic symboò
     */
    description?: string;
    /**
     * where the graphic symbol is located on the document part
     */
    position?: string;
    /**
     * picture of the graphic symbol
     */
    picture?: string;
    /**
     * author(s) divisi da un separatore ||^||
     */
    otherAuthors?: string;
    /**
     * comment
     */
    comment?: string;
    /**
     * tags divisi da un separatore tipo ,
     */
    tags?: string;
    monthCreation?: string;
    monthLastUpdate?: string;
    userCreation?: string;
    userLastUpdate?: string;
    uncertainAssignment?: boolean;
}
