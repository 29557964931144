import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DefaultService, Book } from '../rest';
import { Location } from '../rest/model/location';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentDataSource } from '../documents/documents.component';

export class LocationDataSource implements DataSource<Location> {

  private lessonsSubject = new BehaviorSubject<Location[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Location[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadLocations(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readAllLocations(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.locations);
      });
  }
}

@Component({
  selector: 'app-location',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationComponent implements OnInit {

  location: any;
  show: boolean = false;
  displayedColumns = ['select','id', 'name', 'latitude', 'longitude', 'createdAt', 'userCreation', /*'modify',*/'delete'];
  displayedDocColumnsOrigin = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'provenance', 'maybeSymbols', 'state', 'digitalReproductions', 'createdAt', 'userCreation'];
  displayedDocColumnsProvenance = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'provenance', 'maybeSymbols', 'state', 'digitalReproductions', 'createdAt', 'userCreation'];

  //@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  //@ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('placePaginator', { read: MatPaginator, static: false }) placePaginator: MatPaginator;
  @ViewChild('placeSort', { read: MatSort, static: false }) placeSort: MatSort;
  @ViewChild('documentPaginatorOri', { read: MatPaginator, static: false }) documentPaginatorOri: MatPaginator;
  @ViewChild('documentSortOri', { read: MatSort, static: false }) documentSortOri: MatSort; 
  @ViewChild('documentPaginatorPro', { read: MatPaginator, static: false }) documentPaginatorPro: MatPaginator;
  @ViewChild('documentSortPro', { read: MatSort, static: false }) documentSortPro: MatSort;
  dataSource: LocationDataSource;
  lastFilter: string = ''
  lastDocumentFilterOri: string = ''
  lastDocumentFilterPro: string = ''
  placeSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  docDataSourceOrigin: DocumentDataSource;
  docDataSourceProvenance: DocumentDataSource;
  placeSelected: number = null;

  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent, private router: Router) { }

  ngOnInit() {
    this.dataSource = new LocationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.docDataSourceOrigin = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.docDataSourceProvenance = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadLocations()
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;
  @ViewChild('lastDocumentFilterVCOri', { static: false }) lastDocumentFilterVCOri: ElementRef;
  @ViewChild('lastDocumentFilterVCPro', { static: false }) lastDocumentFilterVCPro: ElementRef;

  ngAfterViewInit() {
    // reset the paginator after sorting
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    merge(this.placeSort.sortChange, this.placePaginator.page)
      .pipe(
        tap(() => this.loadLocations(this.lastFilter, false))
      )
      .subscribe();

    merge(this.documentSortOri.sortChange, this.documentPaginatorOri.page)
      .pipe(
        tap(() => this.loadPlaceDocumentsOri(this.lastDocumentFilterOri, false))
      )
      .subscribe();
    
    merge(this.documentSortPro.sortChange, this.documentPaginatorPro.page)
    .pipe(
      tap(() => this.loadPlaceDocumentsPro(this.lastDocumentFilterPro, false))
    )
    .subscribe();

    fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => this.loadLocations(text));

    fromEvent(this.lastDocumentFilterVCOri.nativeElement, 'keyup').pipe(
        // get value
        map((evt: any) => evt.target.value),
        // text length must be > 2 chars
        //.filter(res => res.length > 2)
        // emit after 1s of silence
        debounceTime(500),
        // emit only if data changes since the last emit       
        distinctUntilChanged())
        // subscription
        .subscribe((text: string) => {
          this.loadPlaceDocumentsOri(text)});

    fromEvent(this.lastDocumentFilterVCPro.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        this.loadPlaceDocumentsPro(text)});

  }

  loadLocations(filterValue: string = '', pageIndexReset = true) {
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.placePaginator.pageIndex = 0
    }
    this.dataSource.loadLocations(
      filterValue,
      this.placeSort.active,
      this.placeSort.direction,
      this.placePaginator.pageIndex,
      this.placePaginator.pageSize
    )
  }
  refresh(): void {
    this.dataSource.loadLocations(
      this.lastFilter,
      this.placeSort.active,
      this.placeSort.direction,
      this.placePaginator.pageIndex,
      this.placePaginator.pageSize
    )
  }
  selectLocation(location: any): void {
    window.open("/placedetail/" + location.id, "_blank")
  }
  delete(locid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this location?')) {
      this.documentApi.deleteLocById(locid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.dataSource.loadLocations(
            this.lastFilter,
            this.placeSort.active,
            this.placeSort.direction,
            this.placePaginator.pageIndex,
            this.placePaginator.pageSize
          )
        }
      )
    }
  }

  modify(locid: number): void {
    var theUserObj = this;
    theUserObj.router.navigate(['/placedetail/' + locid])
  }

  selectPlace(row: any): void {
    this.placeSelection.toggle(row);
    if (this.placeSelection.isSelected(row)) {
      this.docDataSourceOrigin = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.docDataSourceProvenance = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.placeSelected = row.id;
      this.loadPlaceDocumentsOri()
      this.loadPlaceDocumentsPro()
    } else {
      this.placeSelected = null;
    }
  }

  loadPlaceDocumentsOri(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentFilterOri = filterValue
    if (pageIndexReset == true) {
      this.documentPaginatorOri.pageIndex = 0
    }
    if (this.placeSelected != null) {
      this.docDataSourceOrigin.loadPlaceDocuments(
        this.placeSelected,
        filterValue + '**^**origin',
        this.documentSortOri.active,
        this.documentSortOri.direction,
        this.documentPaginatorOri.pageIndex,
        this.documentPaginatorOri.pageSize
      )
    }
  }

  loadPlaceDocumentsPro(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentFilterPro = filterValue
    if (pageIndexReset == true) {
      this.documentPaginatorPro.pageIndex = 0
    }
    if (this.placeSelected != null) {
      this.docDataSourceProvenance.loadPlaceDocuments(
        this.placeSelected,
        filterValue + '**^**provenance',
        this.documentSortPro.active,
        this.documentSortPro.direction,
        this.documentPaginatorPro.pageIndex,
        this.documentPaginatorPro.pageSize
      )
    }
  }

  modifyDocument(doc): void {
    window.open('/docdetail/' + doc.id, '_blank');
  }

  openDigitalReproduction(event: MouseEvent, doc): void {
    if (doc.digitalReproductions[0]!=undefined){
      //avoid to open document
      event.preventDefault();
      event.stopPropagation();

      // open DR url
      let url = doc.digitalReproductions[0].url;
      let fixed_url: string = '';
      if (!/^http[s]?:\/\//.test(url.trim())) {
        fixed_url += 'http://';
      }
      fixed_url += url.trim();
      window.open(fixed_url, '_blank');
    }
  }
}
