
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest , HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/do';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('\n\n\n\n INTERCEPTOR \n\n\n\n');
        return next.handle(req).timeout(30000).do(event => {}, err => {
            if(err instanceof HttpErrorResponse){
                console.log("Error Caught By Interceptor");
                Observable.throw(err);
            }
        }); // 30000 (30 sec) is the global timeout for any http request
    }
}