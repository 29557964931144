import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentsComponent } from './documents/documents.component';
import { PhysicalsupportComponent} from './physicalsupport/physicalsupport.component';
import { PublicationComponent} from './publications/publications.component';
import {DocumentDetailComponent} from './document-detail/document-detail.component';
import { AddPhysicalsupportComponent } from './add-physicalsupport/add-physicalsupport.component';
import { PersonComponent } from './persons/persons.component';
import { GraphicSymbolComponent } from './graphicsymbols/graphicsymbols.component';

import { PendingChangesGuard } from './pending-changes.guard'
import { BibliographyComponent } from './bibliography/bibliography.component';
import { LocationComponent } from './locations/locations.component';
import { LocMapComponent } from './locmap/locmap.component';
import { AddLocationComponent } from './add-location/add-location.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/documents',
    pathMatch: 'full'},
  {
    path: 'documents',
    component: DocumentsComponent
  },
  {
    path: 'physicalsupports',
    component: PhysicalsupportComponent
  },
  {
    path:'docdetail',
    children: [
      { path: '', component: DocumentDetailComponent, canDeactivate: [PendingChangesGuard] },
      { path: ':id', component: DocumentDetailComponent, canDeactivate: [PendingChangesGuard] },
    ]
  },
  {
    path:'psdetail',
    children: [
      { path: '', component: AddPhysicalsupportComponent, canDeactivate: [PendingChangesGuard] },
      { path: ':id', component: AddPhysicalsupportComponent, canDeactivate: [PendingChangesGuard] }
    ]
  },
  {
    path:'publications',
    children: [
      { path: '', component: PublicationComponent }
    ]
  },
  {
    path:'bibliography',
    children: [
      { path: '', component: BibliographyComponent }
    ]
  },
  {
    path:'persons',
    children: [
      { path: '', component: PersonComponent }
    ]
  },
  {
    path:'graphicsymbols',
    children: [
      { path: '', component: GraphicSymbolComponent }
    ]
  },
  {
    path:'places',
    children: [
      { path: '', component: LocationComponent }
    ]
  },
  {
    path:'placemap',
    children: [
      { path: '', component: LocMapComponent }
    ]
  },
  {
    path:'placedetail',
    children: [
      { path: '', component: AddLocationComponent },
      { path: ':id', component: AddLocationComponent }
    ]
  },
  { path:'refreshdoc/:id',
    redirectTo: 'docdetail/:id',
    pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  }), RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
