import { Document, DefaultService, Book } from '../rest'
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap'
import { AppComponent } from '../app.component';
import { BehaviorSubject, merge, Subject, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

export class DocumentDataSource implements DataSource<Document> {

  private lessonsSubject = new BehaviorSubject<Document[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Document[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadDocuments(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readAllDoc(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.documents);
      });
  }

  loadPersonDocuments(pid: number, filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readAllPersonDoc(pid, this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.documents);
      });
  }

  loadPlaceDocuments(placeId, filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readAllPlaceDoc(placeId, this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.documents);
      });
  }
}


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, AfterViewInit {
  documents: any;
  displayedColumns = ['id', 'PrincipalIdentifier', 'title', 'date', 'originalArchive', 'language', 'maybeSymbols', 'state', 'tags', /*/*'fromImport',*/ 'needReview', 'createdAt', 'userCreation', 'DELETE'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: DocumentDataSource;
  lastFilter: string = ''
  filterfield: string = 'all'
  tagsGSDictionary = {}
  isAdvancedSearch = false
  importBtnIsChecked: Boolean = false;
  reviewBtnIsChecked: Boolean = false;
  // show validated assets
  showValidated: Boolean = false;
  showNotValidated: Boolean = false;
  showAll: Boolean = true;

  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent, private router: Router) { }

  removeAllTags(): void {
    let thisObject = this
    Object.keys(this.tagsGSDictionary).forEach(function (key) { delete thisObject.tagsGSDictionary[key]; });
  }

  clearTags(): void {
    this.removeAllTags()
    this.lastFilterVC.nativeElement.value = ""
    this.lastFilter = ""
    this.loadDocuments()
  }

  removeTagGS(tag: string): void {
    delete this.tagsGSDictionary[tag]
    if (this.filterfield == tag) {
      this.lastFilterVC.nativeElement.value = ""
      this.lastFilter = ""
    }
    this.loadDocuments(this.lastFilter)
  }

  ngOnInit() {
    this.dataSource = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadDocuments()
    this.showAll = true;
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;

  ngAfterViewInit() {
    // reset the paginator after sorting
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDocuments(this.lastFilter, false))
      )
      .subscribe();

    fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        if (!this.isAdvancedSearch) {
          this.removeAllTags()
        }
        if ((text || '').trim()) {
          this.tagsGSDictionary[this.filterfield] = text
        } else {
          this.removeTagGS(this.filterfield)
        }
        this.loadDocuments(text)
      });
  }

  loadDocuments(filterValue: string = '', pageIndexReset = true) {
    let thisObject = this
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.paginator.pageIndex = 0
    }
    let actualFilter = ""
    Object.keys(this.tagsGSDictionary).forEach(function (key) {
      if (thisObject.tagsGSDictionary[key] != "") {
        if (actualFilter != "") {
          actualFilter += "##^##"
        }
        actualFilter += key + '||^||' + thisObject.tagsGSDictionary[key]
      }
    });
    if (actualFilter == "") {
      actualFilter = this.filterfield + '||^||' + filterValue
    }
    if (this.importBtnIsChecked) { // if the "from import" option is selected...
      actualFilter += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    if (this.reviewBtnIsChecked) { // if the "need review" option is selected...
      actualFilter += '**^**' + 'needReview' // ...then append this string at the end of the classic filter string
    }
    if (this.showValidated) { 
      actualFilter += '**^**' + 'validated'
    }
    if (this.showNotValidated) { 
      actualFilter += '**^**' + 'notValidated'
    }
    this.dataSource.loadDocuments(
      actualFilter,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    )
  }

  onFieldChange(ob) {
    if (this.isAdvancedSearch) {
      this.lastFilterVC.nativeElement.value = this.tagsGSDictionary[this.filterfield] || ""
      this.lastFilter = this.tagsGSDictionary[this.filterfield] || ""
    } else {
      this.removeAllTags()
      if ((this.lastFilter || '').trim()) {
        this.tagsGSDictionary[this.filterfield] = this.lastFilter
      }
      this.loadDocuments(this.lastFilter)
    }
  }

  //da rivedere se possibile fare nested rest call ricavando i valori di book.abbreviation book.volume e docid.number partendo da DocIDP

  delete(docid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this document?')) {
      this.documentApi.deleteDoc(docid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value)
          this.dataSource.loadDocuments(
            this.lastFilter,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
          )
        }
      )
    }
  }

  refresh(): void {
    this.loadDocuments(this.lastFilter, false)
  }

  selectDoc(document: any): void {
    window.open("/docdetail/" + document.id, "_blank")
  }

  selectedDocument: Document;
  onSelect(document: Document): void {
    this.selectedDocument = document;
  }

  // loading spinner
  showLoadingSpinner = false;

  private showLoadingScreen(value: boolean): void {
    this.showLoadingSpinner = value;
  }

  changeAdvancedSearch(completed: boolean) {
    this.removeAllTags()
    if ((this.lastFilter || '').trim()) {
      this.tagsGSDictionary[this.filterfield] = this.lastFilter
    }
    this.loadDocuments(this.lastFilter)
  }

  /*onSelect(document: Document): void {
    this.selectedDocument = document;
    this.documentApi.readDocId(selectedDocument.id).subscribe(
      value => {
        setTimeout(() => this.selectedDocument.push(...value), 2000);
      },

    )
    
  }*/

  toggleCheckImportBtn(event): void {
    if (event.target.checked) {
      this.importBtnIsChecked = true;
      this.loadDocuments();
    } else {
      this.importBtnIsChecked = false;
      this.loadDocuments();
    }
  }

  toggleCheckReviewBtn(event): void {
    if (event.target.checked) {
      this.reviewBtnIsChecked = true;
      this.loadDocuments();
    } else {
      this.reviewBtnIsChecked = false;
      this.loadDocuments();
    }
  }

  toggleShowValidatedBtn(event): void {
    //console.log(event.srcElement.value)
    if (event.srcElement.value == 'validated'){
      if (event.target.checked) {
        this.showValidated = true;
        this.showNotValidated = false;
        this.showAll = false;
        this.loadDocuments();
      } else {
        this.showValidated = false;
        this.loadDocuments();
      }
    } else if (event.srcElement.value == 'notValidated'){
      if (event.target.checked) {
        this.showNotValidated = true;
        this.showValidated = false;
        this.showAll = false;
        this.loadDocuments();
      } else {
        this.showNotValidated = false;
        this.loadDocuments();
      }
    } else { // show all docs
      if (event.target.checked) {
        this.showAll = true;
        this.showNotValidated = false;
        this.showValidated = false;
        this.loadDocuments();
      } else {
        this.showAll = false;
        this.loadDocuments();
      }
    }
  }


  addDocument() {
    window.open('/docdetail', '_blank');
  }

}