import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, NgModule, Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DefaultService, Person } from '../rest';
import { AppComponent } from '../app.component'
import { DocumentDataSource } from '../documents/documents.component'
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbHighlight, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionModel } from '@angular/cdk/collections'
import { IDropdownSettings } from 'ng-multiselect-dropdown';


export class PersonDataSource implements DataSource<Person> {

  private lessonsSubject = new BehaviorSubject<Person[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Person[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPersons(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readP(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.persons);
      });
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './persons-detail.component.html',
  styleUrls: ['./persons.component.css']
})
export class PersonDetailModal implements OnInit {
  displayedColumns = ['select', 'id', 'name', 'role', 'personRoles', 'status', 'placeOfOrigin', 'date', 'placesOfActivity', 'comment', 'state', 'createdAt', 'userCreation', 'modify', 'delete'];
  displayedDocColumns = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'maybeSymbols', 'state', 'rolesindocument', 'paleographicalAttribution', 'digitalReproductions','createdAt', 'userCreation'];
  @ViewChild('documentDetailPaginator', { read: MatPaginator, static: false }) documentDetailPaginator: MatPaginator;
  @ViewChild('documentDetailSort', { read: MatSort, static: false }) documentDetailSort: MatSort;
  @ViewChild('lastDocumentDetailFilterVC', { static: false }) lastDocumentDetailFilterVC: ElementRef;

  lastDocumentDetailFilter: string = ''

  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService, private modalService: NgbModal) { }

  @Input() public toModify;
  @Input() public mainApp;
  showSaveBtn: boolean;
  private addPersonDetail: any = {};
  private urlsTP: string[];
  private urlsTPPrefixes: Map<string,string>;
  docDetailDataSource: DocumentDataSource;

  private zoteroLinks: string[] = []; //zotero for pros. and biblio. resources
  
  ngOnInit() {
    this.urlsTP = [];
    this.urlsTPPrefixes = new Map<string,string>();
    if (this.toModify == null) {
      this.addPersonDetail.windowTitle = 'Add person'
      this.addPersonDetail.state = 'inprocess'
      this.zoteroLinks = [];
    } else {
      Object.assign(this.addPersonDetail, this.toModify)
      this.addPersonDetail.windowTitle = 'Modify person'
      // init zotero links
      if (this.addPersonDetail.zotero != '' && this.addPersonDetail.zotero != null){
        this.zoteroLinks = this.addPersonDetail.zotero.split('||^||').filter(Boolean); // fill url list
      } else {this.zoteroLinks = [];} // url list is empty

      if (this.addPersonDetail.trismegistosID != null){
        this.urlsTP = this.addPersonDetail.trismegistosID.split('||^||').filter(Boolean); // fill TP url list
        for (var i = 0; i < this.urlsTP.length; i++) { // format url string from list, show only the IDs (final part of the url)
          if (this.urlsTP[i].includes("trismegistos.org/person/")) {
            var fullUrl = this.urlsTP[i];
            var position = this.urlsTP[i].lastIndexOf("/");
            this.urlsTP[i] = fullUrl.substring(position+1); // show only the ID
            this.urlsTPPrefixes.set(this.urlsTP[i],"https://www.trismegistos.org/person/" + fullUrl.substring(position+1))
          }
          /*var position = this.urlsTP[i].lastIndexOf("/");
          this.urlsTP[i] = this.urlsTP[i].substring(position+1);
          this.urlsTPPrefixes.set(this.urlsTP[i].substring(position+1),this.urlsTP[i].substring(0,position))*/
        }
      } else {this.urlsTP = [];} // the list is empty
      //fill "Other Authors" list
      if (this.addPersonDetail.otherAuthors != null) {
        this.selectedAuthors = this.addPersonDetail.otherAuthors.split("||^||").filter(Boolean);
      } else {this.selectedAuthors = []}
      this.docDetailDataSource = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.docDetailDataSource.loadPersonDocuments(this.addPersonDetail.id);
      if (this.showSaveBtn==false){
        this.addPersonDetail.windowTitle = 'Person\'s Details'
      }
    }
  }

  ngAfterViewInit() {
    merge(this.documentDetailSort.sortChange, this.documentDetailPaginator.page)
      .pipe(
        tap(() => this.loadDetailDocuments(this.lastDocumentDetailFilter, false))
      )
      .subscribe();
      
      fromEvent(this.lastDocumentDetailFilterVC.nativeElement, 'keyup').pipe(
        // get value
        map((evt: any) => evt.target.value),
        // text length must be > 2 chars
        //.filter(res => res.length > 2)
        // emit after 1s of silence
        debounceTime(500),
        // emit only if data changes since the last emit       
        distinctUntilChanged())
        // subscription
        .subscribe((text: string) => {
          this.loadDetailDocuments(text)});
  }

  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (this.urlsTPPrefixes.has(url)){
      fixed_url += this.urlsTPPrefixes.get(url)
      //console.log(fixed_url)
      window.open(fixed_url, '_blank');
    } else {
      fixed_url += url.trim();
      window.open(fixed_url, '_blank');
    }
    /*if (!/^http[s]?:\/\//.test(url.trim())) {
      //fixed_url += this.urlsTPPrefixes[url]; //get the prefix
      fixed_url += 'http://trismegistos.org/person/';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');*/
  }

  openZoteroLink(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }

  closeModal() {
      let newPerson: Person = {
        name: this.addPersonDetail.name,
        trismegistosID: '',
        otherAuthors: '',
        date: this.addPersonDetail.date,
        status: this.addPersonDetail.status,
        state: this.addPersonDetail.state,
        gender: this.addPersonDetail.gender,
        placeOfOrigin: this.addPersonDetail.placeOfOrigin,
        prosographicalResources: this.addPersonDetail.prosographicalResources,
        zotero: this.addPersonDetail.zotero,
        comment: this.addPersonDetail.comment,
        _function: this.addPersonDetail.function
      }
      for (var i = 0; i < this.urlsTP.length; i++) { // format url string from list
        if (this.urlsTPPrefixes.has(this.urlsTP[i])){ //the url is a trismegistos link
          newPerson.trismegistosID = newPerson.trismegistosID.concat("https://www.trismegistos.org/person/" + this.urlsTP[i]);
          newPerson.trismegistosID = newPerson.trismegistosID.concat('||^||'); // delimiter
        } else { // url different from trismegistos (papyri and other websites)
          newPerson.trismegistosID = newPerson.trismegistosID.concat(this.urlsTP[i]);
          newPerson.trismegistosID = newPerson.trismegistosID.concat('||^||'); // delimiter
        }
      }
      // zotero links
      var typeBUFFER = ''
      this.zoteroLinks.forEach(name => {
        typeBUFFER = typeBUFFER.concat(name)
        typeBUFFER = typeBUFFER.concat('||^||')//delimiter
      });
      if (typeBUFFER != ''){
        newPerson.zotero = typeBUFFER
      } else {newPerson.zotero = ''}
      // other authors
      var typeBUFFER = ''
      this.selectedAuthors.forEach(t => {
        typeBUFFER = typeBUFFER.concat(t)
        typeBUFFER = typeBUFFER.concat('||^||')//delimiter
      });
      if (typeBUFFER != ''){
        newPerson.otherAuthors = typeBUFFER
      } else {newPerson.otherAuthors=''}
      if (this.toModify != null) {
        this.documentApi.updatePid(this.addPersonDetail.id, newPerson, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.modal.close(value)
          })
      } else {
        this.documentApi.createP(newPerson, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.modal.close(value)
          })
      }
  }

  // multiple authors
  authorList: string[] =  ['antonella.ghignoli@uniroma1.it', 'nina.sietis@uniroma1.it', 'anna.monte@uniroma1.it'
 , 'maria.boccuzzi@uniroma1.it', 'livia.briasco@uniroma1.it' , 'aneta.skalec@uniroma1.it'
 , 'marta.marucci@uniroma1.it', 'luciaconsuelo.colella@uniroma1.it']
  selectedAuthors: string[] = [];

  dropdownAuthorSettings:IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 15,
    allowSearchFilter: false,
    enableCheckAll:false,
    maxHeight: 400
  };

  loadDetailDocuments(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentDetailFilter = filterValue
    if (pageIndexReset == true) {
      this.documentDetailPaginator.pageIndex = 0
    }
    if (this.addPersonDetail != null) {
      this.docDetailDataSource.loadPersonDocuments(
        this.addPersonDetail.id,
        filterValue,
        this.documentDetailSort.active,
        this.documentDetailSort.direction,
        this.documentDetailPaginator.pageIndex,
        this.documentDetailPaginator.pageSize
      )
    }
  }

  modifyDocument(doc): void {
    window.open('/docdetail/' + doc.id, '_blank');
  }

  openDigitalReproduction(event: MouseEvent, doc): void {
    if (doc.digitalReproductions[0]!=undefined){
      //avoid to open document
      event.preventDefault();
      event.stopPropagation();

      // open DR url
      let url = doc.digitalReproductions[0].url;
      let fixed_url: string = '';
      if (!/^http[s]?:\/\//.test(url.trim())) {
        fixed_url += 'http://';
      }
      fixed_url += url.trim();
      window.open(fixed_url, '_blank');
    }
  }
}


@Component({
  selector: 'persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.css']
})
export class PersonComponent implements OnInit {
  displayedColumns = ['select', 'id', 'name', 'gender', /*'role',*/ 'personRoles', 'status', 'placeOfOrigin', 'date', 'placesOfActivity', 'comment', 'state', 'createdAt', 'userCreation', 'modify', 'delete'];
  displayedDocColumns = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'maybeSymbols', 'state', 'rolesindocument', 'paleographicalAttribution', 'digitalReproductions', 'createdAt', 'userCreation'];
  @ViewChild('personPaginator', { read: MatPaginator, static: false }) personPaginator: MatPaginator;
  @ViewChild('personSort', { read: MatSort, static: false }) personSort: MatSort;
  @ViewChild('documentPaginator', { read: MatPaginator, static: false }) documentPaginator: MatPaginator;
  @ViewChild('documentSort', { read: MatSort, static: false }) documentSort: MatSort;

  personSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  dataSource: PersonDataSource;
  docDataSource: DocumentDataSource;
  personSelected: number = null;
  lastFilter: string = ''
  lastDocumentFilter: string = ''
  filterfield: string = 'all'
  tagsGSDictionary = {}
  isAdvancedSearch = false

  // show validated assets
  showValidated: Boolean = false;
  showNotValidated: Boolean = false;
  showAll: Boolean = true;

  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.dataSource = new PersonDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.docDataSource = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadPersons()
    this.showAll = true;
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;
  @ViewChild('lastDocumentFilterVC', { static: false }) lastDocumentFilterVC: ElementRef;
  
  ngAfterViewInit() {
    /*this.personSort.sortChange.subscribe(() => this.personPaginator.pageIndex = 0);
    this.documentSort.sortChange.subscribe(() => this.documentPaginator.pageIndex = 0);*/

    // on sort or paginate events, load a new page
    merge(this.personSort.sortChange, this.personPaginator.page)
      .pipe(
        tap(() => this.loadPersons(this.lastFilter, false))
      )
      .subscribe();

    merge(this.documentSort.sortChange, this.documentPaginator.page)
      .pipe(
        tap(() => this.loadDocuments(this.lastDocumentFilter, false))
      )
      .subscribe();

      fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
        // get value
        map((evt: any) => evt.target.value),
        // text length must be > 2 chars
        //.filter(res => res.length > 2)
        // emit after 1s of silence
        debounceTime(500),
        // emit only if data changes since the last emit       
        distinctUntilChanged())
        // subscription
        .subscribe((text: string) => {
          if (!this.isAdvancedSearch) {
            this.removeAllTags()
          }
          if ((text || '').trim()) {
            this.tagsGSDictionary[this.filterfield] = text
          } else {
            this.removeTagGS(this.filterfield)
          }
          this.loadPersons(text)});

        fromEvent(this.lastDocumentFilterVC.nativeElement, 'keyup').pipe(
          // get value
          map((evt: any) => evt.target.value),
          // text length must be > 2 chars
          //.filter(res => res.length > 2)
          // emit after 1s of silence
          debounceTime(500),
          // emit only if data changes since the last emit       
          distinctUntilChanged())
          // subscription
          .subscribe((text: string) => {
            this.loadDocuments(text)});
  }

  removeAllTags(): void {
    let thisObject = this
    Object.keys(this.tagsGSDictionary).forEach(function (key) { delete thisObject.tagsGSDictionary[key]; });
  }

  clearTags(): void {
    this.removeAllTags()
    this.lastFilterVC.nativeElement.value = ""
    this.lastFilter = ""
    this.loadDocuments()
  }

  removeTagGS(tag: string): void {
    delete this.tagsGSDictionary[tag]
    if (this.filterfield == tag) {
      this.lastFilterVC.nativeElement.value = ""
      this.lastFilter = ""
    }
    this.loadDocuments(this.lastFilter)
  }

  changeAdvancedSearch(completed: boolean) {
    this.removeAllTags()
    if ((this.lastFilter || '').trim()) {
      this.tagsGSDictionary[this.filterfield] = this.lastFilter
    }
    this.loadDocuments(this.lastFilter)
  }

  onFieldChange(ob) {
    if (this.isAdvancedSearch) {
      this.lastFilterVC.nativeElement.value = this.tagsGSDictionary[this.filterfield] || ""
      this.lastFilter = this.tagsGSDictionary[this.filterfield] || ""
    } else {
      this.removeAllTags()
      if ((this.lastFilter || '').trim()) {
        this.tagsGSDictionary[this.filterfield] = this.lastFilter
      }
      this.loadDocuments(this.lastFilter)
    }
  }

  delete(pid: string): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this person?')) {
      this.documentApi.deletePid(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.dataSource.loadPersons();
        }
      )
    }
  }

  refresh(): void {
    this.loadPersons(this.lastFilter, false)
  }

  loadPersons(filterValue: string = '', pageIndexReset = true) {
    let thisObject = this
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.personPaginator.pageIndex = 0
    }
    this.personSelected = null
    this.personSelection.clear()
    let actualFilter = ""
    Object.keys(this.tagsGSDictionary).forEach(function (key) {
      if (thisObject.tagsGSDictionary[key] != "") {
        if (actualFilter != "") {
          actualFilter += "##^##"
        }
        actualFilter += key + '||^||' + thisObject.tagsGSDictionary[key]
      }
    });
    if (actualFilter == "") {
      actualFilter = this.filterfield + '||^||' + filterValue
    }
    if (this.showValidated) { 
      actualFilter += '**^**' + 'validated'
    }
    if (this.showNotValidated) { 
      actualFilter += '**^**' + 'notValidated'
    }
    this.dataSource.loadPersons(
      actualFilter,
      this.personSort.active,
      this.personSort.direction,
      this.personPaginator.pageIndex,
      this.personPaginator.pageSize
    )
  }

  addPersonModal(toModify = null) {
    const modalRef = this.modalService.open(PersonDetailModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomVLModalClass'})
    modalRef.componentInstance.toModify = toModify
    modalRef.componentInstance.mainApp = this.mainApp
    modalRef.result.then((result) => {
      this.dataSource.loadPersons(
        this.lastFilter,
        this.personSort.active,
        this.personSort.direction,
        this.personPaginator.pageIndex,
        this.personPaginator.pageSize
      )
    }, (reason) => {
    }).catch((res) => {});;
  }

  selectPerson(row: any): void {
    this.personSelection.toggle(row);
    if (this.personSelection.isSelected(row)) {
      this.docDataSource = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.personSelected = row.id;
      this.loadDocuments()
    } else {
      this.personSelected = null;
    }
  }

  loadDocuments(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentFilter = filterValue
    if (pageIndexReset == true) {
      this.documentPaginator.pageIndex = 0
    }
    if (this.personSelected != null) {
      this.docDataSource.loadPersonDocuments(
        this.personSelected,
        filterValue,
        this.documentSort.active,
        this.documentSort.direction,
        this.documentPaginator.pageIndex,
        this.documentPaginator.pageSize
      )
    }
  }

  modifyDocument(doc): void {
    window.open('/docdetail/' + doc.id, '_blank');
  }

  openDigitalReproduction(event: MouseEvent, doc): void {
    if (doc.digitalReproductions[0]!=undefined){
      //avoid to open document
      event.preventDefault();
      event.stopPropagation();

      // open DR url
      let url = doc.digitalReproductions[0].url;
      let fixed_url: string = '';
      if (!/^http[s]?:\/\//.test(url.trim())) {
        fixed_url += 'http://';
      }
      fixed_url += url.trim();
      window.open(fixed_url, '_blank');
    }
  }

  toggleShowValidatedBtn(event): void {
    //console.log(event.srcElement.value)
    if (event.srcElement.value == 'validated'){
      if (event.target.checked) {
        this.showValidated = true;
        this.showNotValidated = false;
        this.showAll = false;
        this.loadPersons();
      } else {
        this.showValidated = false;
        this.loadPersons();
      }
    } else if (event.srcElement.value == 'notValidated'){
      if (event.target.checked) {
        this.showNotValidated = true;
        this.showValidated = false;
        this.showAll = false;
        this.loadPersons();
      } else {
        this.showNotValidated = false;
        this.loadPersons();
      }
    } else { // show all docs
      if (event.target.checked) {
        this.showAll = true;
        this.showNotValidated = false;
        this.showValidated = false;
        this.loadPersons();
      } else {
        this.showAll = false;
        this.loadPersons();
      }
    }
  }

}