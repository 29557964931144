import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { DefaultService, BookSeries, Reference, Publication } from '../rest';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent, of } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Citation } from '../rest/model/citation';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './bibliography-citation.component.html',
  styleUrls: ['./bibliography.component.css']
})
export class BibliographyCitationModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public citations;
  
  citationsColumns = ['documentId', 'role', 'number', 'pages', 'plate', 'tome', 'createdAt', 'userCreation', 'gotodoc']
  @ViewChild('citationsPaginator', { read: MatPaginator, static: false }) citationsPaginator: MatPaginator;
  citationsDataSource: CitationsDataSource;

  ngOnInit() {
    this.citationsDataSource = new CitationsDataSource()
    this.citationsDataSource.loadCitations(this.citations);
  }

  ngAfterViewInit() {
    merge(this.citationsPaginator.page)
      .pipe(
        tap(() => this.loadCitations(this.citations, false))
      )
      .subscribe();
  }

  loadCitations(citations, pageIndexReset = true) {
    if (pageIndexReset == true) {
      this.citationsPaginator.pageIndex = 0
    }
    this.citationsDataSource.loadCitations(
      citations,
      this.citationsPaginator.pageIndex,
      this.citationsPaginator.pageSize
    )
  }

  selectDoc(documentId: any): void {
    window.open("/docdetail/" + documentId, "_blank")
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './bibliography-publication.component.html',
  styleUrls: ['./bibliography.component.css']
})
export class BibliographyPublicationModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addPublicationDetail;
  @Input() public urls;
  @Input() public bibliographyComponent;

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }

  openURL(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }

  associatedReferenceSelected(event) {
    if (event != null) {
      if (event.associatedPublication != null && event.associatedPublication.id != null) {
        alert("WARNING!!! Impossible to select this reference as it is connected to another publication");
        this.addPublicationDetail.auxiliaryReference = null
      }
    }
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './bibliography-reference.component.html',
  styleUrls: ['./bibliography.component.css']
})
export class BibliographyReferenceModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addReferenceDetail;
  @Input() public bibliographyComponent;
  @Input() public zoteroLinks: string[] = [];

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }

  associatedPublicationSelected(event) {
    let associationAllowed = true;
    if (event != null) {
      if ((event.authors != null && this.addReferenceDetail.authors != event.authors) ||
      (event.year != null && this.addReferenceDetail.year != event.year) || (event.title != null && this.addReferenceDetail.title != event.title)) {
        associationAllowed = confirm("WARNING!!! Incoherent Data\n\nReference Data:\n   Authors: " + this.addReferenceDetail.authors + "\n   Title: " + this.addReferenceDetail.title + "\n   Year: " + this.addReferenceDetail.year
          + "\nPublication Data: \n   Authors: " + event.authors + "\n   Title: " + event.title + "\n   Year: " + event.year + "\n\nDo you want to confirm?");
      }
    }
    if (associationAllowed == true) {
      this.addReferenceDetail.associatedPublication = event
      if (this.addReferenceDetail.type == null || this.addReferenceDetail.type == "") {
        this.addReferenceDetail.type = event.type
      }
    } else {
      this.addReferenceDetail.associatedPublication = null
    }
  }

  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './bibliography-bookseries.component.html',
  styleUrls: ['./bibliography.component.css']
})
export class BibliographyBookSeriesModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addBookSeriesDetail;
  @Input() public bibliographyComponent;
  @Input() public isForContainer;

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }
}

export class CitationsDataSource implements DataSource<Citation> {

  public lessonsSubject = new BehaviorSubject<Citation[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor() { }

  connect(collectionViewer: CollectionViewer): Observable<Citation[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadCitations(citationsArray, pageIndex: number = 0, pageSize: number = 20) {
    this.totalCount = citationsArray.length
    this.lessonsSubject.next(citationsArray.slice(pageIndex*pageSize, pageIndex*pageSize + pageSize));
  }
}

export class PublicationDataSource implements DataSource<Publication> {

  public lessonsSubject = new BehaviorSubject<Publication[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Publication[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPublications(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesList = null, bookList = null, citedAsList = null, typeList = null) {
    this.defaultApi.readAllPublications(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesList, bookList, citedAsList, typeList).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.publications);
      });
  }
}

export class BookSeriesDataSource implements DataSource<BookSeries> {

  public lessonsSubject = new BehaviorSubject<BookSeries[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0
  
  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<BookSeries[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadBookSeries(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesId = null) {
    this.defaultApi.readBookSeries(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesId).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.bookSeries);
      });
  }
}

export class ReferenceDataSource implements DataSource<Reference> {

  private lessonsSubject = new BehaviorSubject<Reference[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private selectedTypes = null

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0
  
  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<Reference[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadReferences(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20, bookSeriesList = null, bookList = null, citedAsList = null, typeList = null) {
    this.defaultApi.readAllReferences(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter, bookSeriesList, bookList, citedAsList, typeList).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.references);
      });
  }
}

@Component({
  selector: 'bibliography',
  templateUrl: './bibliography.component.html',
  styleUrls: ['./bibliography.component.css']
})
export class BibliographyComponent implements OnInit {

  publicationSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  bookSeriesSelection: SelectionModel<any> = new SelectionModel<any>(false, []);
  referenceSelection: SelectionModel<any> = new SelectionModel<any>(false, []);

  displayedColumns = ['select', 'id', 'type', 'bookseries', 'volume', 'publication', 'authors', 'title', 'year', /*'fromImport',*/ 'createdAt', 'userCreation', 'citations', 'modify', 'delete'];
  displayedBookSeriesColumns = ['select', 'id', 'abbreviation', 'extendedTitle', /*'fromImport',*/ 'createdAt', 'userCreation', 'modify', 'delete'];
  referenceDisplayedColumns = ['select', 'id', 'type', 'reference', 'bookseries', 'volume', 'authors', 'title', 'year', /*'fromImport',*/ 'createdAt', 'userCreation', 'citations', 'modify', 'delete'];
  @ViewChild('publicationPaginator', { read: MatPaginator, static: false }) publicationPaginator: MatPaginator;
  @ViewChild('publicationSort', { read: MatSort, static: false }) publicationSort: MatSort;
  @ViewChild('bookSeriesPaginator', { read: MatPaginator, static: false }) bookSeriesPaginator: MatPaginator;
  @ViewChild('bookSeriesSort', { read: MatSort, static: false }) bookSeriesSort: MatSort;
  @ViewChild('referencePaginator', { read: MatPaginator, static: false }) referencePaginator: MatPaginator;
  @ViewChild('referenceSort', { read: MatSort, static: false }) referenceSort: MatSort;

  dataSource: PublicationDataSource;
  bookSeriesDataSource: BookSeriesDataSource;
  referenceDataSource: ReferenceDataSource;

  lastFilter: string = ''
  lastBookSeriesFilter: string = ''
  lastReferenceFilter: string = ''
  importBtnIsCheckedReferences: Boolean = false;
  importBtnIsCheckedPublications: Boolean = false;
  importBtnIsCheckedSerialPublications: Boolean = false;

  publicationSelected: number = null;
  bookSeriesSelected: number = null;
  referenceSelected: number = null;

  gsElementArray: any = {}
  gs2ElementArray: any = {}
  gs3ElementArray: any = {}
  gs4ElementArray: any = {}

  addPublicationDetail: any = {}
  addContainerPublicationDetail: any = {}
  addReferenceDetail: any = {}
  addContainerReferenceDetail: any = {}
  addContainerBookSeriesDetail: any = {}
  addBookSeriesDetail: any = {}

  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.referenceDataSource = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadPublications()
    this.bookSeriesDataSource.loadBookSeries()
    this.referenceDataSource.loadReferences()

    this.gsElementArray[0] = { value: 'principal', checked: false }
    this.gsElementArray[1] = { value: 'otherIdentifier', checked: false }
    this.gsElementArray[2] = { value: 'edition', checked: false }
    this.gsElementArray[3] = { value: 'bibliography', checked: false }
    this.gsElementArray[4] = { value: 'facsimile', checked: false }

    this.gs2ElementArray[0] = { value: 'principal', checked: false }
    this.gs2ElementArray[1] = { value: 'otherIdentifier', checked: false }
    this.gs2ElementArray[2] = { value: 'edition', checked: false }
    this.gs2ElementArray[3] = { value: 'bibliography', checked: false }
    this.gs2ElementArray[4] = { value: 'facsimile', checked: false }

    this.gs3ElementArray[0] = { value: 'Collection', checked: false }
    this.gs3ElementArray[1] = { value: 'Monograph', checked: false }
    this.gs3ElementArray[2] = { value: 'Journal Issue', checked: false }
    this.gs3ElementArray[3] = { value: 'Monograph Paper', checked: false }
    this.gs3ElementArray[4] = { value: 'Journal Paper', checked: false }
    this.gs3ElementArray[5] = { value: 'Not Associated', checked: false }
    this.gs3ElementArray[6] = { value: 'Associated', checked: false }
    this.gs3ElementArray[7] = { value: 'Collection Paper', checked: false }

    this.gs4ElementArray[0] = { value: 'Collection', checked: false }
    this.gs4ElementArray[1] = { value: 'Monograph', checked: false }
    this.gs4ElementArray[2] = { value: 'Journal Issue', checked: false }
    this.gs4ElementArray[3] = { value: 'Monograph Paper', checked: false }
    this.gs4ElementArray[4] = { value: 'Journal Paper', checked: false }
    this.gs4ElementArray[5] = { value: 'Not Associated', checked: false }
    this.gs4ElementArray[6] = { value: 'Associated', checked: false }
    this.gs4ElementArray[7] = { value: 'Collection Paper', checked: false }
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;
  @ViewChild('lastReferenceFilterVC', { static: false }) lastReferenceFilterVC: ElementRef;
  @ViewChild('lastBookSeriesFilterVC', { static: false }) lastBookSeriesFilterVC: ElementRef;

  ngAfterViewInit() {
    merge(this.publicationSort.sortChange, this.publicationPaginator.page)
      .pipe(
        tap(() => this.changePublicationPage(this.lastFilter, false))
      )
      .subscribe();

    merge(this.bookSeriesSort.sortChange, this.bookSeriesPaginator.page)
      .pipe(
        tap(() => this.changeBookSeriesPage(this.lastBookSeriesFilter, false))
      )
      .subscribe();

    merge(this.referenceSort.sortChange, this.referencePaginator.page)
      .pipe(
        tap(() => this.changeReferencePage(this.lastReferenceFilter, false))
      )
      .subscribe();

    fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        if (this.publicationSelected != null) {
          this.referenceSelected = null
          this.bookSeriesSelected = null
          this.loadReferences(this.lastReferenceFilter);
          this.loadBookSeries(this.lastBookSeriesFilter);
        }
        this.publicationSelected = null;
        this.loadPublications(text)
      });

    fromEvent(this.lastReferenceFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        if (this.referenceSelected != null) {
          this.bookSeriesSelected = null
          this.publicationSelected = null
          this.loadBookSeries(this.lastReferenceFilter);
          this.loadPublications(this.lastBookSeriesFilter);
        }
        this.referenceSelected = null;
        this.loadReferences(text)
      });

    fromEvent(this.lastBookSeriesFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        if (this.bookSeriesSelected != null) {
          this.publicationSelected = null
          this.referenceSelected = null
          this.loadReferences(this.lastReferenceFilter);
          this.loadPublications(this.lastBookSeriesFilter);
        }
        this.bookSeriesSelected = null;
        this.loadBookSeries(text)
      });
  }

  changePublicationPage(filterValue: string = '', pageIndexReset = true) {
    if (this.publicationSelected != null) {
      this.referenceSelected = null
      this.bookSeriesSelected = null
      this.loadReferences(this.lastReferenceFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
    }
    this.publicationSelected = null;
    this.loadPublications(filterValue, pageIndexReset)
  }

  loadPublications(filterValue: string = '', pageIndexReset = true) {
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.publicationPaginator.pageIndex = 0
    }
    let typeList = null
    Object.keys(this.gs4ElementArray).forEach(element => {
      if (this.gs4ElementArray[element].checked == true) {
        if (typeList == null) {
          typeList = []
        }
        typeList.push(this.gs4ElementArray[element].value)
      }
    });
    let citedAsList = null
    Object.keys(this.gs2ElementArray).forEach(element => {
      if (this.gs2ElementArray[element].checked == true) {
        if (citedAsList == null) {
          citedAsList = []
        }
        citedAsList.push(this.gs2ElementArray[element].value)
      }
    });
    if (this.importBtnIsCheckedPublications) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.dataSource.loadPublications(
      filterValue,
      this.publicationSort.active,
      this.publicationSort.direction,
      this.publicationPaginator.pageIndex,
      this.publicationPaginator.pageSize,
      this.bookSeriesSelected != null ? [this.bookSeriesSelected] : null,
      this.publicationSelected != null ? [this.publicationSelected] : null,
      citedAsList,
      typeList
    )
  }

  changeBookSeriesPage(filterValue: string = '', pageIndexReset = true) {
    if (this.bookSeriesSelected != null) {
      this.publicationSelected = null
      this.referenceSelected = null
      this.loadReferences(this.lastReferenceFilter);
      this.loadPublications(this.lastBookSeriesFilter);
    }
    this.bookSeriesSelected = null;
    this.loadBookSeries(filterValue, pageIndexReset)
  }

  loadBookSeries(filterValue: string = '', pageIndexReset = true) {
    this.lastBookSeriesFilter = filterValue
    if (pageIndexReset == true) {
      this.bookSeriesPaginator.pageIndex = 0
    }
    if (this.importBtnIsCheckedSerialPublications) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.bookSeriesDataSource.loadBookSeries(
      filterValue,
      this.bookSeriesSort.active,
      this.bookSeriesSort.direction,
      this.bookSeriesPaginator.pageIndex,
      this.bookSeriesPaginator.pageSize,
      this.bookSeriesSelected != null ? this.bookSeriesSelected : null
    )
  }

  changeReferencePage(filterValue: string = '', pageIndexReset = true) {
    if (this.referenceSelected != null) {
      this.bookSeriesSelected = null
      this.publicationSelected = null
      this.loadBookSeries(this.lastReferenceFilter);
      this.loadPublications(this.lastBookSeriesFilter);
    }
    this.referenceSelected = null;
    this.loadReferences(filterValue, pageIndexReset)
  }

  loadReferences(filterValue: string = '', pageIndexReset = true) {
    this.lastReferenceFilter = filterValue
    if (pageIndexReset == true) {
      this.referencePaginator.pageIndex = 0
    }
    let typeList = null
    Object.keys(this.gs3ElementArray).forEach(element => {
      if (this.gs3ElementArray[element].checked == true) {
        if (typeList == null) {
          typeList = []
        }
        typeList.push(this.gs3ElementArray[element].value)
      }
    });
    let citedAsList = null
    Object.keys(this.gsElementArray).forEach(element => {
      if (this.gsElementArray[element].checked == true) {
        if (citedAsList == null) {
          citedAsList = []
        }
        citedAsList.push(this.gsElementArray[element].value)
      }
    });
    if (this.importBtnIsCheckedReferences) { // if the "from import" option is selected...
      filterValue += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    this.referenceDataSource.loadReferences(
      filterValue,
      this.referenceSort.active,
      this.referenceSort.direction,
      this.referencePaginator.pageIndex,
      this.referencePaginator.pageSize,
      this.bookSeriesSelected != null ? [this.bookSeriesSelected] : null,
      this.publicationSelected != null ? [this.publicationSelected] : null,
      citedAsList,
      typeList
    )
  }

  selectPublication(row: any): void {
    this.publicationSelection.toggle(row);
    if (this.publicationSelection.isSelected(row)) {
      this.publicationSelected = row.id;
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceDataSource = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
        if (row.bookSeries.id != null) {
          this.bookSeriesSelected = row.bookSeries.id;
        } else {
          this.bookSeriesSelected = -1;
        }
      this.loadReferences(this.lastReferenceFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceDataSource = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.publicationSelected = null;
      this.bookSeriesSelected = null;
      this.referenceSelected = null;
      this.loadReferences(this.lastReferenceFilter);
      this.loadBookSeries(this.lastBookSeriesFilter);
      this.loadPublications(this.lastFilter);
    }
  }

  selectBookSeries(row: any): void {
    this.bookSeriesSelection.toggle(row);
    if (this.bookSeriesSelection.isSelected(row)) {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceDataSource = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesSelected = row.id;
      this.loadPublications(this.lastFilter)
      this.loadReferences(this.lastReferenceFilter)
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceDataSource = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceSelected = null;
      this.bookSeriesSelected = null;
      this.publicationSelected = null;
      this.loadPublications(this.lastFilter)
      this.loadReferences(this.lastReferenceFilter)
      this.loadBookSeries(this.lastBookSeriesFilter)
    }
  }

  selectReference(row: any): void {
    this.referenceSelection.toggle(row);
    if (this.referenceSelection.isSelected(row)) {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceSelected = row.id;
      if (row.associatedPublication.id != null) {
        this.publicationSelected = row.associatedPublication.id
        if (row.associatedPublication.bookSeries.id != null) {
          this.bookSeriesSelected = row.associatedPublication.bookSeries.id;
        } else {
          this.bookSeriesSelected = -1;
        }
      } else {
        if (row.bookSeries.id != null) {
          this.bookSeriesSelected = row.bookSeries.id;
        } else {
          this.bookSeriesSelected = -1;
        }
      }
      this.loadPublications(this.lastFilter)
      this.loadBookSeries(this.lastBookSeriesFilter)
    } else {
      this.dataSource = new PublicationDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.bookSeriesDataSource = new BookSeriesDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceDataSource  = new ReferenceDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken);
      this.referenceSelected = null;
      this.bookSeriesSelected = null;
      this.publicationSelected = null;
      this.loadPublications(this.lastFilter)
      this.loadBookSeries(this.lastBookSeriesFilter);
      this.loadReferences(this.lastReferenceFilter);
    }
  }

  bookSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addPublicationDetail.book = null} return []}))
        : this.documentApi.readBook(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null).pipe(
          map(value => {
            if (value.books.length > 0) {
              return value.books
            } else {
              return []
            }
          })
        ))
    )

  modifyPublicationModal(toModify, destinationBuffer = this.addPublicationDetail, fromScreen = 0, forcedReference = null): void {
    let urls: string[] = [];
    let initialReference = null;
    destinationBuffer.allowEditReference = true
    if (toModify == null || toModify.id == null) {
      destinationBuffer.windowTitle = 'Add publication'
      destinationBuffer.containerPublication = null;
      destinationBuffer.pages = null;
      destinationBuffer.url = null;
      destinationBuffer.comment = null;
      destinationBuffer.place = null;
      destinationBuffer.tome = null;
      destinationBuffer.volumeTitle = null;
      destinationBuffer.numberOfVolumes = null;
      destinationBuffer.references = null;
      destinationBuffer.auxiliaryReference = null;
      if (fromScreen == 1) {
        destinationBuffer.type = forcedReference.type;
        destinationBuffer.year = forcedReference.year;
        destinationBuffer.title = forcedReference.title;
        destinationBuffer.volume = forcedReference.volume;
        destinationBuffer.authors = forcedReference.authors;
        destinationBuffer.bookSeries = forcedReference.bookSeries;
      } else {
        destinationBuffer.type = null;
        if (fromScreen == 2) {
          if (this.addPublicationDetail.type == "Monograph Paper") {
            destinationBuffer.type = "Monograph"
          } else if (this.addPublicationDetail.type == "Journal Paper") {
            destinationBuffer.type = "Journal Issue"
          } else if (this.addPublicationDetail.type == "Collection Paper") {
            destinationBuffer.type = "Collection"
          }
        }
        destinationBuffer.year = null;
        destinationBuffer.title = null;
        destinationBuffer.volume = null;
        destinationBuffer.authors = null;
        destinationBuffer.bookSeries = null;
      }
    } else {
      if (fromScreen == 1 || fromScreen == 0) {
        this.addPublicationDetail = Object.assign({}, toModify);
        destinationBuffer = this.addPublicationDetail;
      } else if (fromScreen == 2) {
        this.addContainerPublicationDetail = Object.assign({}, toModify);
        destinationBuffer = this.addContainerPublicationDetail;
      }
      if (toModify.references != null && toModify.references.length > 0) {
        destinationBuffer.auxiliaryReference = toModify.references[0];
        initialReference = toModify.references[0];
      }
      destinationBuffer.windowTitle = 'Modify publication';
      if (destinationBuffer.url != null){
        urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
      }
    }
    if (forcedReference != null) {
      destinationBuffer.references = [forcedReference];
      destinationBuffer.allowEditReference = false
    }
    const modalRef = this.modalService.open(BibliographyPublicationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.addPublicationDetail = destinationBuffer
    modalRef.componentInstance.urls = urls
    modalRef.componentInstance.bibliographyComponent = this
    modalRef.result.then((result) => {
      destinationBuffer.modified = true;
      destinationBuffer.url = '';
      for (var i = 0; i < urls.length; i++) {
        destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
        destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
      }
      let initialReferenceId = (initialReference != null ? initialReference.id : null);
      let auxiliaryReferenceId = (destinationBuffer.auxiliaryReference != null ? destinationBuffer.auxiliaryReference.id : null)
      destinationBuffer.updateReferenceId = initialReferenceId;
      if (initialReferenceId != auxiliaryReferenceId) {
        destinationBuffer.updateReferenceId = auxiliaryReferenceId;
      }
      if (toModify == null || toModify.id == null) {
        this.documentApi.createPublication(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.loadPublications(this.lastFilter, false)
            this.loadReferences(this.lastReferenceFilter, false)
            if (fromScreen == 1) {
              forcedReference.associatedPublication = value;
              if (destinationBuffer.auxiliaryReference != null) {
                forcedReference.associatedPublication.references = [Object.assign({}, destinationBuffer.auxiliaryReference)];
              }
            } else if (fromScreen == 2) {
              this.addPublicationDetail.containerPublication = value;
              if (destinationBuffer.auxiliaryReference != null) {
                this.addPublicationDetail.containerPublication.references = [Object.assign({}, destinationBuffer.auxiliaryReference)];
              }
              this.addPublicationDetail.year = value.year;
            }
          }
        )
      } else {
        destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
        this.documentApi.updatePublicationId(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.loadPublications(this.lastFilter, false)
            this.loadReferences(this.lastReferenceFilter, false)
            if (fromScreen == 1) {
              this.addReferenceDetail.associatedPublication = value;
              if (destinationBuffer.auxiliaryReference != null) {
                this.addReferenceDetail.associatedPublication.references = [Object.assign({}, destinationBuffer.auxiliaryReference)];
              }
            } else if (fromScreen == 2) {
              this.addPublicationDetail.containerPublication = value;
              if (destinationBuffer.auxiliaryReference != null) {
                this.addPublicationDetail.containerPublication.references = [Object.assign({}, destinationBuffer.auxiliaryReference)];
              }
              this.addPublicationDetail.year = value.year;
            }
          }
        )
      }
    }, (reason) => {
    });
  }

  showReferenceCitations(bibliographyItem) {
    const modalRef = this.modalService.open(BibliographyCitationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.citations = bibliographyItem.citations
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  showPublicationCitations(bibliographyItem) {
    const modalRef = this.modalService.open(BibliographyCitationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    if (bibliographyItem.references.length > 0) {
      modalRef.componentInstance.citations = bibliographyItem.references[0].citations
    } else {
      modalRef.componentInstance.citations = []
    }
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // zotero link field
  zoteroLinks: string[] = [];

  referenceModal(toModify = null, forcedPublication = null, destinationBuffer = this.addReferenceDetail): void {
    destinationBuffer.allowEditPublication = true;
    if (toModify == null) {
      destinationBuffer.windowTitle = 'Add reference'
      destinationBuffer.bookSeries = (forcedPublication != null ? forcedPublication.bookSeries : null);
      destinationBuffer.title = (forcedPublication != null ? forcedPublication.title : null);
      destinationBuffer.volume = (forcedPublication != null ? forcedPublication.volume : null);
      destinationBuffer.authors = (forcedPublication != null ? forcedPublication.authors : null);
      destinationBuffer.year = (forcedPublication != null ? forcedPublication.year : null);
      destinationBuffer.referenceText = null;
      destinationBuffer.referenceTextType = null;
      destinationBuffer.type = (forcedPublication != null ? forcedPublication.type : null);
      destinationBuffer.associatedPublication = null;
      this.zoteroLinks = [];
    } else {
      Object.assign(destinationBuffer, toModify);
      destinationBuffer.windowTitle = 'Modify reference'
      // init zotero links
      if (this.addReferenceDetail.zotero != '' && this.addReferenceDetail.zotero != null){
        this.zoteroLinks = this.addReferenceDetail.zotero.split('||^||').filter(Boolean); // fill url list
      } else {this.zoteroLinks = [];} // url list is empty
    }
    if (forcedPublication != null) {
      destinationBuffer.associatedPublication = forcedPublication;
      destinationBuffer.allowEditPublication = false
    }
    const modalRef = this.modalService.open(BibliographyReferenceModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.addReferenceDetail = destinationBuffer
    modalRef.componentInstance.bibliographyComponent = this
    modalRef.componentInstance.zoteroLinks = this.zoteroLinks
    
    modalRef.result.then((result) => {
      // zotero links
      var typeBUFFER = ''
      this.zoteroLinks.forEach(name => {
        typeBUFFER = typeBUFFER.concat(name)
        typeBUFFER = typeBUFFER.concat('||^||')//delimiter
      });
      if (typeBUFFER != ''){
        destinationBuffer.zotero = typeBUFFER
      } else {destinationBuffer.zotero = ''}
      
      if (destinationBuffer.referenceTextType != 'Other') {
        if (destinationBuffer.referenceTextType == "1") {
          destinationBuffer.referenceText = destinationBuffer.bookSeries.abbreviation + ' ' + destinationBuffer.volume
        } else if (destinationBuffer.referenceTextType == "2") {
          destinationBuffer.referenceText = destinationBuffer.authors + ' ' + destinationBuffer.year
        } else if (destinationBuffer.referenceTextType == "3") {
          destinationBuffer.referenceText = destinationBuffer.bookSeries.abbreviation + ' ' + destinationBuffer.volume + ' ' + destinationBuffer.year
        } else if (destinationBuffer.referenceTextType == "4") {
          destinationBuffer.referenceText = destinationBuffer.bookSeries.abbreviation + ' ' + destinationBuffer.year
        } else if (destinationBuffer.referenceTextType == "5") {
          destinationBuffer.referenceText = destinationBuffer.authors + ' ' + destinationBuffer.title
        }
      }
      if (toModify == null) {
        this.documentApi.createReference(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (forcedPublication != null) {
              forcedPublication.auxiliaryReference = value;
            }
            this.loadReferences(this.lastReferenceFilter, false)
          }
        )
      } else {
        this.documentApi.updateReferenceId(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (forcedPublication != null) {
              forcedPublication.auxiliaryReference = destinationBuffer;
            }
            this.loadReferences(this.lastReferenceFilter, false)
          }
        )
      }
    }, (reason) => {
    })
  }

  addContainerBookSeriesModal(toModify = null): void {
    if (toModify == null) {
      this.addContainerBookSeriesDetail.extendedTitle = null;
      this.addContainerBookSeriesDetail.abbreviation = null;
    } else {
      this.addContainerBookSeriesDetail = Object.assign({}, toModify)
    }
    const modalRef = this.modalService.open(BibliographyBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.addBookSeriesDetail = this.addContainerBookSeriesDetail
    modalRef.componentInstance.bibliographyComponent = this
    modalRef.componentInstance.isForContainer = true
    modalRef.result.then((result) => {
      let newBook: BookSeries = {
        extendedTitle: this.addContainerBookSeriesDetail.extendedTitle,
        abbreviation: this.addContainerBookSeriesDetail.abbreviation,
      }
      if (toModify == null) {
        this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addBookSeriesDetail.containerBookSeries = value;
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      } else {
        this.documentApi.updateBookSeriesById(toModify.id, newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addPublicationDetail.bookSeries = Object.assign({}, this.addContainerBookSeriesDetail );
            this.addReferenceDetail.bookSeries = Object.assign({}, this.addContainerBookSeriesDetail );
            this.addBookSeriesDetail.containerBookSeries = Object.assign({}, this.addContainerBookSeriesDetail );;
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      }
    }, (reason) => {
    });
  }

  refresh(): void {
    this.selectPublication = null
    this.selectBookSeries = null
    this.selectReference = null

    this.dataSource.loadPublications(
      this.lastFilter,
      this.publicationSort.active,
      this.publicationSort.direction,
      this.publicationPaginator.pageIndex,
      this.publicationPaginator.pageSize)

    this.referenceDataSource.loadReferences(
      this.lastReferenceFilter,
      this.referenceSort.active,
      this.referenceSort.direction,
      this.referencePaginator.pageIndex,
      this.referencePaginator.pageSize
    )

    this.bookSeriesDataSource.loadBookSeries(
      this.lastBookSeriesFilter,
      this.bookSeriesSort.active,
      this.bookSeriesSort.direction,
      this.bookSeriesPaginator.pageIndex,
      this.bookSeriesPaginator.pageSize
    )
  }

  bookSeriesFormatter = (x: BookSeries) => {
    return this.stringifyBookSeries(x)
  }

  stringifyBookSeries(x: BookSeries): string {
    if (x.id == null) {
      return null
    }
    return x.abbreviation + (x.extendedTitle == null ? "" : " - " + x.extendedTitle);
  }

  stringifyReference(x: Reference, includeTitle: boolean): string {
    if (x.id == null) {
      return null
    }
    return x.referenceText + " - " + x.type + (includeTitle ? (x.associatedPublication != null && x.associatedPublication.title != null ? " - " + x.associatedPublication.title : (x.title != null ? " - " + x.title : "")) : "");
  }

  publicationFormatter = (x: Publication) => {
    return this.stringifyPublication(x)
  }

  referenceFormatter = (x: Reference) => {
    return this.stringifyReference(x, false)
  }

  associatedPublicationFormatter = (x: Publication) => {
    return this.stringifyAssociatedPublication(x)
  }

  stringifyPublication(x: Publication): string {
    if (x.id == null) {
      return null
    }
    if (x.references != null && x.references.length > 0) {
      return x.references[0].referenceText;
    } else {
      return "[no ref] " + (x.title == null ? "[no title]" : x.title);
    }
  }

  stringifyAssociatedPublication(x: Publication): string {
    if (x.id == null) {
      return null
    }
    if (x.type == 'Journal Issue' || x.type == 'Collection') {
      let abbr = (x.bookSeries != null ? x.bookSeries.abbreviation : null);
      if (abbr == null || abbr == "") abbr = "[no series]";
      let volume = x.volume;
      if (volume == null || volume == "") volume = "[no volume]";
      if ((x.bookSeries == null || x.bookSeries.abbreviation == null) && (x.volume == null || x.volume == "")) {
        return (x.title == null ? "[no title nor book series]" : x.title) + " " + (x.year == null ? "[no year]" : x.year);
      } else {
        return abbr + " " + volume + " " + (x.year == null ? "[no year]" : x.year);
      }
    } else if (x.type == 'Monograph' || x.type == 'Journal Paper' || x.type == 'Monograph Paper') {
      let authors = x.authors;
      if (authors == null || authors == "") authors = "[no authors]";
      return authors + " " + (x.year == null ? "[no year]" : x.year) + " - " + (x.title == null ? "[no title]" : x.title);
    } else if (x.title != null && x.title != "") {
      return x.title;
    } else if (x.volumeTitle != null && x.volumeTitle != "") {
      return x.volumeTitle;
    } else {
      return "[no info to show] " + x.id;
    }
  }

  retrievePublicationBookSeries(x: Publication): string {
    if ((x.type == 'Monograph' || x.type == 'Journal Paper' || x.type == 'Monograph Paper') && x.containerPublication != null) {
      return (x.containerPublication.bookSeries == null ? null : x.containerPublication.bookSeries.abbreviation);
    } else {
      return (x.bookSeries == null ? null : x.bookSeries.abbreviation);
    }
  }

  referenceSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addPublicationDetail.references = null;} return []}))
        : this.documentApi.readAllReferences(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null, null, this.addPublicationDetail.type == 'To Be Checked' ? ['Monograph', 'Journal Issue', 'Collection'] : [this.addPublicationDetail.type == 'Monograph Paper' ? 'Monograph' : (this.addPublicationDetail.type == 'Journal Paper' ? 'Journal Issue' : 'Collection')] ).pipe(
          map(value => {
            if (value.references.length > 0) {
              return value.references
            } else {
              return []
            }
          })
        ))
    )

  publicationSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addBookSeriesDetail.containerBookSeries = null;} return []}))
        : this.documentApi.readAllPublications(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null, null, this.addPublicationDetail.type == 'To Be Checked' ? ['Monograph', 'Journal Issue', 'Collection'] : [this.addPublicationDetail.type == 'Monograph Paper' ? 'Monograph' : (this.addPublicationDetail.type == 'Journal Paper' ? 'Journal Issue' : 'Collection')] ).pipe(
          map(value => {
            if (value.publications.length > 0) {
              return value.publications
            } else {
              return []
            }
          })
        ))
    )

    associatedPublicationSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addReferenceDetail.associatedPublication = null;} return []}))
        : this.documentApi.readAllPublications(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null, null, null, this.addReferenceDetail.type != null && this.addReferenceDetail.type != "" && this.addReferenceDetail.type != "To Be Checked" ? [this.addReferenceDetail.type] : null).pipe(
          map(value => {
            if (value.publications.length > 0) {
              if (this.addReferenceDetail.type != null && (this.addReferenceDetail.type == 'Journal Issue' || this.addReferenceDetail.type == 'Collection' || this.addReferenceDetail.type == 'Monograph' || this.addReferenceDetail.type == 'Journal Paper' || this.addReferenceDetail.type == 'Monograph Paper')) {
                value.publications.sort((a,b) => (a.year == null ? 0 : a.year) - (b.year == null ? 0 : b.year));
              }
              return value.publications
            } else {
              return []
            }
          })
        ))
    )

  bookSeriesBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addBookSeriesDetail.containerBookSeries = null;} return []}))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  referenceBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addReferenceDetail.bookSeries = null; }return []}))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

    publicationBookSeriesSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.addPublicationDetail.bookSeries = null; } return []}))
        : this.documentApi.readBookSeries(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term, null ).pipe(
          map(value => {
            if (value.bookSeries.length > 0) {
              return value.bookSeries
            } else {
              return []
            }
          })
        ))
    )

  modifyBookSeries(toModify = null, destinationBuffer = this.addBookSeriesDetail): void {
    if (toModify == null) {
      this.addBookSeriesDetail.extendedTitle = null;
      this.addBookSeriesDetail.abbreviation = null;
      this.addBookSeriesDetail.containerBookSeries = null
    } else {
      this.addBookSeriesDetail = Object.assign({}, toModify)
    }
    const modalRef = this.modalService.open(BibliographyBookSeriesModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.addBookSeriesDetail = this.addBookSeriesDetail
    modalRef.componentInstance.bibliographyComponent = this
    modalRef.componentInstance.isForContainer = false
    modalRef.result.then((result) => {
      let newBook: BookSeries = {
        extendedTitle: this.addBookSeriesDetail.extendedTitle,
        abbreviation: this.addBookSeriesDetail.abbreviation,
        containerBookSeries: this.addBookSeriesDetail.containerBookSeries
      }
      if (toModify == null) {
        this.documentApi.createBookSeries(newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addPublicationDetail.bookSeries = Object.assign({}, value );
            this.addReferenceDetail.bookSeries = Object.assign({}, value );
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      } else {
        this.documentApi.updateBookSeriesById(toModify.id, newBook, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.addPublicationDetail.bookSeries = Object.assign({}, this.addBookSeriesDetail );
            this.addReferenceDetail.bookSeries = Object.assign({}, this.addBookSeriesDetail );
            this.loadBookSeries(this.lastBookSeriesFilter, false)
          }
        )
      }

    }, (reason) => {
    });
  }

  deletePublication(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this publication?')) {
      this.documentApi.deletePublicationId(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadPublications(this.lastFilter, false);
        }
      )
    }
  }

  deleteReference(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this publication?')) {
      this.documentApi.deleteReferenceId(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadReferences(this.lastFilter, false);
        }
      )
    }
  }

  deleteBookSeries(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this book series?')) {
      this.documentApi.deleteBookSeriesById(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadBookSeries(this.lastBookSeriesFilter, false);
        }
      )
    }
  }

  deleteBook(pid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this book?')) {
      this.documentApi.deleteBookId(pid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.loadReferences(this.lastReferenceFilter, false);
        }
      )
    }
  }


  toggleCheckImportReferenceBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedReferences = true;
      this.loadReferences();
    } else {
      this.importBtnIsCheckedReferences = false;
      this.loadReferences();
    }
  }

  toggleCheckImportPublicationsBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedPublications = true;
      this.loadPublications()
    } else {
      this.importBtnIsCheckedPublications = false;
      this.loadPublications()
    }
  }

  toggleCheckImportSerialBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsCheckedSerialPublications = true;
      this.loadBookSeries();
    } else {
      this.importBtnIsCheckedSerialPublications = false;
      this.loadBookSeries();
    }
  }
}
