import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DefaultService, PhysicalSupport } from '../rest';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

export class PhysicalSupportDataSource implements DataSource<PhysicalSupport> {

  private lessonsSubject = new BehaviorSubject<PhysicalSupport[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0

  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }

  connect(collectionViewer: CollectionViewer): Observable<PhysicalSupport[]> {
    return this.lessonsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPhysicalSupports(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readAll(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.physicalSupports);
      });
  }
}

@Component({
  selector: 'app-physicalsupport',
  templateUrl: './physicalsupport.component.html',
  styleUrls: ['./physicalsupport.component.css']
})
export class PhysicalsupportComponent implements OnInit {

  ps: any;
  show: boolean = false;
  displayedColumns = ['id', 'city', 'conservationPlace', 'inventory', 'physicalDescription', 'state', /*'height','width', */'tags', /*'fromImport',*/ 'createdAt', 'userCreation', 'delete', 'addtodoc'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: PhysicalSupportDataSource;
  lastFilter: string = ''
  isAdvancedSearch = false
  lastDocumentFilter: string = ''
  filterfield: string = 'all'
  tagsGSDictionary = {}
  importBtnIsChecked: Boolean = false;
  // show validated assets
  showValidated: Boolean = false;
  showNotValidated: Boolean = false;
  showAll: Boolean = true;
  
  constructor(private documentApi: DefaultService,
    private mainApp: AppComponent, private router: Router) { }

  ngOnInit() {
    this.dataSource = new PhysicalSupportDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    this.dataSource.loadPhysicalSupports()
    this.showAll = true;
    /*this.documentApi.readAll(this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        this.ps=value.physicalSupports;
        this.dataSource=new MatTableDataSource(this.ps);
        console.log(this.dataSource);
        this.show=true;
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(this.dataSource);
    
        });
      });*/
  }

  @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;

  ngAfterViewInit() {
    // reset the paginator after sorting
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadPhysicalSupports(this.lastFilter, false))
      )
      .subscribe();

    fromEvent(this.lastFilterVC.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
      if (!this.isAdvancedSearch) {
        this.removeAllTags()
      }
      if ((text || '').trim()) {
        this.tagsGSDictionary[this.filterfield] = text
      } else {
        this.removeTagGS(this.filterfield)
      }
      this.loadPhysicalSupports(text)
    });
  }


  loadPhysicalSupports(filterValue: string = '', pageIndexReset = true) {
    let thisObject = this
    this.lastFilter = filterValue
    if (pageIndexReset == true) {
      this.paginator.pageIndex = 0
    }
    let actualFilter = ""
    Object.keys(this.tagsGSDictionary).forEach(function (key) {
      if (thisObject.tagsGSDictionary[key] != "") {
        if (actualFilter != "") {
          actualFilter += "##^##"
        }
        actualFilter += key + '||^||' + thisObject.tagsGSDictionary[key]
      }
    });
    if (actualFilter == "") {
      actualFilter = this.filterfield + '||^||' + filterValue
    }
    if (this.importBtnIsChecked) { // if the "from import" option is selected...
      actualFilter += '**^**' + 'fromImport' // ...then append this string at the end of the classic filter string
    }
    if (this.showValidated) { 
      actualFilter += '**^**' + 'validated'
    }
    if (this.showNotValidated) { 
      actualFilter += '**^**' + 'notValidated'
    }
    this.dataSource.loadPhysicalSupports(
      actualFilter,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    )
  }
  
  refresh(): void {
    this.dataSource.loadPhysicalSupports(
      this.lastFilter,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    )
  }

  removeAllTags(): void {
    let thisObject = this
    Object.keys(this.tagsGSDictionary).forEach(function (key) { delete thisObject.tagsGSDictionary[key]; });
  }

  clearTags(): void {
    this.removeAllTags()
    this.lastFilterVC.nativeElement.value = ""
    this.lastFilter = ""
    this.loadPhysicalSupports()
  }

  removeTagGS(tag: string): void {
    delete this.tagsGSDictionary[tag]
    if (this.filterfield == tag) {
      this.lastFilterVC.nativeElement.value = ""
      this.lastFilter = ""
    }
    this.loadPhysicalSupports(this.lastFilter)
  }

  changeAdvancedSearch(completed: boolean) {
    this.removeAllTags()
    if ((this.lastFilter || '').trim()) {
      this.tagsGSDictionary[this.filterfield] = this.lastFilter
    }
    this.loadPhysicalSupports(this.lastFilter)
  }

  onFieldChange(ob) {
    if (this.isAdvancedSearch) {
      this.lastFilterVC.nativeElement.value = this.tagsGSDictionary[this.filterfield] || ""
      this.lastFilter = this.tagsGSDictionary[this.filterfield] || ""
    } else {
      this.removeAllTags()
      if ((this.lastFilter || '').trim()) {
        this.tagsGSDictionary[this.filterfield] = this.lastFilter
      }
      this.loadPhysicalSupports(this.lastFilter)
    }
  }


  selectPS(ps: any): void {
    window.open("/psdetail/" + ps.id, "_blank")
  }
  delete(psid: number): void {
    let theUserObj = this
    if (confirm('Are you sure you want to remove this material support?')) {
      this.documentApi.deleteById(psid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          if (value.success == 0) {
            alert(value.description)
          }
          this.dataSource.loadPhysicalSupports(
            this.lastFilter,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
          )
        }
      )
    }
  }

  addToNewDoc(psid: any): void {
    sessionStorage.setItem("PSaddtonewdoc", psid);
    window.open("/docdetail", "_blank");
    sessionStorage.clear();
  }

  toggleCheckImportBtn(event): void {
    if ( event.target.checked ) {
      this.importBtnIsChecked = true;
      this.loadPhysicalSupports();
    } else {
      this.importBtnIsChecked = false;
      this.loadPhysicalSupports();
    }
  }


  toggleShowValidatedBtn(event): void {
    //console.log(event.srcElement.value)
    if (event.srcElement.value == 'validated'){
      if (event.target.checked) {
        this.showValidated = true;
        this.showNotValidated = false;
        this.showAll = false;
        this.loadPhysicalSupports();
      } else {
        this.showValidated = false;
        this.loadPhysicalSupports();
      }
    } else if (event.srcElement.value == 'notValidated'){
      if (event.target.checked) {
        this.showNotValidated = true;
        this.showValidated = false;
        this.showAll = false;
        this.loadPhysicalSupports();
      } else {
        this.showNotValidated = false;
        this.loadPhysicalSupports();
      }
    } else { // show all docs
      if (event.target.checked) {
        this.showAll = true;
        this.showNotValidated = false;
        this.showValidated = false;
        this.loadPhysicalSupports();
      } else {
        this.showAll = false;
        this.loadPhysicalSupports();
      }
    }
  }
  
}
