import { Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import { Location } from '@angular/common'
import { DefaultService,  Location as Loc} from '../rest';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import {AppComponent} from '../app.component'
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { ComponentCanDeactivate } from '../pending-changes.guard';
import { NgForm } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as d3 from 'd3';
import *  as L from 'leaflet';


@Component({
  selector: 'ngbd-modal-confirm',
  templateUrl: './add-physicalsupport-location.component.html',
  styleUrls: ['./add-physicalsupport.component.css']
})
export class PhysicalSupportLocationModal implements OnInit {
  constructor(public modal: NgbActiveModal,
    private documentApi: DefaultService) { }

  @Input() public addLocationDetail;
  @Input() public urls;
  @Input() public physicalSupportComponent;
  @Input() public alternativeNamesTags: string[] = [];
  @Input() public urlsTrismegistos: string[] = [];
  @Input() public urlsPleiades: string[] = [];
  @Input() public urlsSeeFurther: string[] = [];


  ngOnInit() {
    this.makeMap();
  }

  ngAfterViewInit() {
  }


  makeMap(){
    var geojson,
      //tileServer = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileServer = 'http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      tileAttribution = 'Map data: <a href="http://openstreetmap.org">OSM</a>',
      marker,
      latitude,
      longitude,
      map = L.map('map').setView([20, 30], 4);

    //Add basemap
    L.tileLayer(tileServer, {attribution: tileAttribution,  maxZoom: 15, noWrap: true}).addTo(map);

    var psIcon = L.icon({
      iconUrl: './assets/images/psIcon.png'
    });

    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });


    map.on('click', function(e) {
      if(marker){
        map.removeLayer(marker)
      }
      marker = L.marker((<any>e).latlng, {icon: psIcon}).addTo(map);
      latitude = (<any>e).latlng.lat;
      longitude = (<any>e).latlng.lng;
      var popupContent = '<span class="attribute"><span class="label">'+'place'+':</span> '+(<HTMLInputElement>document.getElementById('NewBookV2Name')).value+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Latitude'+':</span> '+latitude+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Longitude'+':</span> '+longitude+'</span>'
      popupContent = '<div class="map-popup">'+popupContent+'</div>';
      marker.bindPopup(popupContent,{offset: L.point(1,-2)});
      (<HTMLInputElement>document.getElementById('NewBookV2Longitude')).value = longitude;
      (<HTMLInputElement>document.getElementById('NewBookV2Latitude')).value = latitude;
      document.getElementById('NewBookV2Latitude').dispatchEvent(new Event('input'))
      document.getElementById('NewBookV2Longitude').dispatchEvent(new Event('input'))
    });

    d3.select("#map-container")
      .style('display','none')
      .style('position','fixed')
      .style('top','20%')
      .style('right',0)
      .style('left',0)
      .style('bottom',0)
      .style('z-index','1');
      
    d3.select('#close')
      .style('position','absolute')
      .style('display','block')
      .style('top','1%')
      .style('right','17.5%')
      .style('background-color','red')
      .style('border-radius','10px')
      .style('color','white')
      .style('padding','10px 15px 10px 15px')
      .style('margin','auto')
      .style('z-index','1000')
      .on('mouseover',function (d){
        d3.select(this).style('cursor','pointer')
      });

    d3.select('#showMapBtn').on('click', function(){
      d3.select("#map-container").style('display','block');
      d3.select("#addPSLocContainer").style('filter','blur(12px)');
    });
    d3.select('#close').on('click', function(){
      d3.select("#map-container").style('display','none');
      d3.select("#addPSLocContainer").style('filter','none');
    })

  }

  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }

}

@Component({
  selector: 'app-add-physicalsupport',
  templateUrl: './add-physicalsupport.component.html',
  styleUrls: ['./add-physicalsupport.component.css']
})

export class AddPhysicalsupportComponent extends ComponentCanDeactivate implements OnInit {
  displayedColumns = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'maybeSymbols', 'state', 'tags', 'createdAt', 'userCreation', 'DELETE'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource = new MatTableDataSource();
  documents: any;
  id: any;
  model: any = {
  };
  closeResult: string;
  submitted = false;
  physicalSupportId: number = null;
  isSaving: boolean = false;

  addLocationDetail: any = {}
  addContainerLocationDetail: any = {}
  associatedLocation = null

  // TAGS variables
  tags: string[] = [];
  suggestedTags: string[] = [];

  constructor(
    private defaultApi: DefaultService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private mainApp: AppComponent,
    private modalService: NgbModal,
  ) { super()}

  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild('psForm', {static: false})
  psForm: NgForm;

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id') == null) {
      this.id = null
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    if (this.id != null) {
      this.defaultApi.readById(parseInt(this.id), this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.model = value;
        },
        error => console.error(JSON.stringify(error)),
        () => console.log('done')
      );
      this.defaultApi.readDPPS(this.id, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.documents = value;
          console.log(this.documents);
          this.dataInit();
        },
        error => console.error(JSON.stringify(error)),
        () => console.log('done')
      );
      // fill tags and suggestions from db for existing doc
      this.addTagsFromDB();
      this.addSuggestedTagsFromDB();
    } else {
      this.model.state = 'inprocess'
      // fill suggested tags from db for new document
      this.addSuggestedTagsFromDB();
    }
    console.log(history.state)
  }

  onSubmit(saved) {
    var theUserObj = this;
    if (this.isSaving) {
      return
    }
    this.isSaving = true;
    //this.submitted = true;
    if (this.id == null) {
      this.defaultApi.create(this.model, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          theUserObj.physicalSupportId = value.id;
          this.updateGeneralTagsToDB(value.id, this.tags, 'ps-detail', 'tags')
          console.log(value);
          this.isSaving = false;
          theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            if (history.state.docData != null) {
              value.linkId = -1
              history.state.docData.physicalSupports[value.id] = value;
              if (history.state.docData.id != null) {
                theUserObj.router.navigate(['/docdetail/' + history.state.docData.id], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags } });
              } else {
                theUserObj.router.navigate(['/docdetail'], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags } });
              }
            } else {
              theUserObj.router.navigate(['/psdetail/' + theUserObj.physicalSupportId])
            }
            theUserObj.closeResult = 'Closed with: ${result}';
          }, (reason) => {
            if (history.state.docData != null) {
              history.state.docData.physicalSupports[value.id] = value;
              if (history.state.docData.id != null) {
                theUserObj.router.navigate(['/docdetail/' + history.state.docData.id], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags } });
              } else {
                theUserObj.router.navigate(['/docdetail'], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags } });
              }
            } else {
              theUserObj.router.navigate(['/psdetail/' + theUserObj.physicalSupportId])
            }
            theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
          });
        }
      )
    } else {
      console.log(this.model)

      this.defaultApi.updateById(this.model.id, this.model, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.isSaving = false;
          console.log(value);
          theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            theUserObj.closeResult = 'Closed with: ${result}';
            theUserObj.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {theUserObj.router.navigate(['/psdetail/' + theUserObj.model.id])});
          }, (reason) => {
            theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
            theUserObj.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {theUserObj.router.navigate(['/psdetail/' + theUserObj.model.id])});
          });
        });
      // update or create tags
      this.updateGeneralTagsToDB(this.model.id, this.tags, 'ps-detail', 'tags')
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.psForm.submitted || !this.psForm.dirty;
  }
  
  goBack(): void {
    if (history.state.docData != null) {
      if (history.state.docData.id != null) {
        this.router.navigate(['/docdetail/' + history.state.docData.id], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags} });
      } else {
        this.router.navigate(['/docdetail'], { state: { docData: history.state.docData, urlsTP: history.state.urlsTP, tags: history.state.tags } });
      }
    } else {
      this.location.back();
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dataInit(): void {
    //let docs= this.documents;
    //for(let i=0; i<docs.length; i++  ){
    let i = 0;
    for (let doc of this.documents) {
      console.log(doc);
      this.dataSource.data.push(doc);
    }
    //to initialize paginator and sort (setTimeout it's not the best solution but works)
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.dataSource.data = this.dataSource.data
  }

  private addTagsFromDB(): void {
    this.defaultApi.retrieveTags('ps-detail', this.id, 'tags', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value.value);
          this.tags = value.value.split('||^||').filter(Boolean);
        }
      }
    );
  }

  private addSuggestedTagsFromDB(): void {
    this.defaultApi.retrieveSuggestedTags('ps-detail', 'tags', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value);
          this.suggestedTags = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedTags = this.suggestedTags.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

  private updateGeneralTagsToDB(gsId: any, tags: any, table_name: string, table_field_name: string): void {
    let updatedTagsGS = {
      table: table_name,
      table_id: gsId,
      tagged_field: table_field_name,
      value: ""
    }
    if (tags != null) {
      tags.forEach(function (item, index) {
        updatedTagsGS.value = updatedTagsGS.value.concat(item);
        updatedTagsGS.value = updatedTagsGS.value.concat('||^||');
      });
      this.defaultApi.updateTags(gsId, updatedTagsGS, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {}
      )
    }
  }

  tagsLoc: string[] = [];
  suggestedTagsLoc: string[] = [];
  alternativeNamesTags: string[] = [];
  urlsTrismegistos: string[] = [];
  urlsPleiades: string[] = [];
  urlsSeeFurther: string[] = [];

  private addTagsLocFromDB(): void {
    this.defaultApi.retrieveTags('locations', this.addLocationDetail.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value.value);
          this.tagsLoc = value.value.split('||^||').filter(Boolean);
        }
      }
    );
  }

  private addSuggestedTagsLocFromDB(): void {
    this.defaultApi.retrieveSuggestedTags('locations', 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value);
          this.suggestedTagsLoc = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedTagsLoc = this.suggestedTagsLoc.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

  modifyLocationModal(toModify, destinationBuffer = this.model, fromScreen = 0): void {
    let urls: string[] = [];
    if (toModify == null || toModify.id == null) {
      destinationBuffer.windowTitle = 'Add City'
      destinationBuffer.containerPublication = null;
      destinationBuffer.name = null;
      destinationBuffer.latitude = null;
      destinationBuffer.longitude = null;
      destinationBuffer.year = null;
      destinationBuffer.url = null;
      this.addSuggestedTagsLocFromDB();
    } else {
      if (fromScreen == 1 || fromScreen == 0) {
        this.addLocationDetail = Object.assign({}, toModify);
        destinationBuffer = this.addLocationDetail;
      } else if (fromScreen == 2) {
        this.addContainerLocationDetail = Object.assign({}, toModify);
        destinationBuffer = this.addContainerLocationDetail;
      }
      destinationBuffer.windowTitle = 'Modify City';
      if (destinationBuffer.url != null){
        urls = destinationBuffer.url.split('||^||').filter(Boolean); // fill url list
      }
      this.addTagsLocFromDB();
      this.addSuggestedTagsLocFromDB();
      this.initOtherTags();
    }
    const modalRef = this.modalService.open(PhysicalSupportLocationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'myCustomModalClass'})
    modalRef.componentInstance.addLocationDetail = destinationBuffer
    modalRef.componentInstance.urls = urls
    modalRef.componentInstance.alternativeNamesTags = this.alternativeNamesTags
    modalRef.componentInstance.urlsTrismegistos = this.urlsTrismegistos
    modalRef.componentInstance.urlsPleiades = this.urlsPleiades
    modalRef.componentInstance.urlsSeeFurther = this.urlsSeeFurther
    modalRef.componentInstance.physicalSupportComponent = this
    modalRef.result.then((result) => {
      destinationBuffer.modified = true;
      destinationBuffer.url = '';
      for (var i = 0; i < urls.length; i++) {
        destinationBuffer.url = destinationBuffer.url.concat(urls[i]);
        destinationBuffer.url = destinationBuffer.url.concat('||^||'); // delimiter
      }
      this.updateOtherTags(destinationBuffer)
      if (toModify == null || toModify.id == null) {
        this.defaultApi.createLoc(destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            this.updateGeneralTagsToDB(value.id, this.tagsLoc, 'locations', 'tags-loc')
            if (fromScreen == 1) {
              this.model.associatedLocation = value;
            } else if (fromScreen == 2) {
              this.addLocationDetail.containerPublication = value;
              this.addLocationDetail.year = value.year;
            }
          }
        )
      } else {
        destinationBuffer.citations = [] //TODO: Troppi dati inviati nel caso di update
        this.updateOtherTags(destinationBuffer)
        this.defaultApi.updateLocById(toModify.id, destinationBuffer, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (fromScreen == 1) {
              this.model.associatedLocation = value;
            } else if (fromScreen == 2) {
              this.addLocationDetail.containerPublication = value;
              this.addLocationDetail.year = value.year;
            }
          }          
        )
        this.updateGeneralTagsToDB(this.addLocationDetail.id, this.tagsLoc, 'locations', 'tags-loc')
      }
    }, (reason) => {
    });
  }

  associatedLocationSelected(event) {
    if (event != undefined){
      let associationAllowed = true;
      if (associationAllowed == true) {
        this.associatedLocation = event
        this.model.associatedLocation = this.associatedLocation
        this.model.city = this.associatedLocation.name
        if (this.model.type == null || this.model.type == "") {
          this.model.type = event.type
        }
      } else {
        this.associatedLocation = null
      }
    }
  }

  associatedLocationFormatter = (x: Loc) => {
    return this.stringifyAssociatedLocation(x)
  }

  stringifyAssociatedLocation(x: Loc): string {
    if (x.id == null) {
      return null
    }
    if (x.name != null && x.name != ''){
      return x.id + ' - ' + x.name;
    } 
    else {
      return x.id + ' - [no name]';
    }
  }

  private initOtherTags(){
    if (this.addLocationDetail.alternativeNames != '' && this.addLocationDetail.alternativeNames != null){
      this.alternativeNamesTags = this.addLocationDetail.alternativeNames.split('||^||').filter(Boolean);
    }
    if (this.addLocationDetail.urlsTrismegistos != '' && this.addLocationDetail.urlsTrismegistos != null){
      this.urlsTrismegistos = this.addLocationDetail.urlsTrismegistos.split('||^||').filter(Boolean);
    }
    if (this.addLocationDetail.urlsPleiades != '' && this.addLocationDetail.urlsPleiades != null){
      this.urlsPleiades = this.addLocationDetail.urlsPleiades.split('||^||').filter(Boolean);
    }
    if (this.addLocationDetail.urlsSeeFurther != '' && this.addLocationDetail.urlsSeeFurther != null){
      this.urlsSeeFurther = this.addLocationDetail.urlsSeeFurther.split('||^||').filter(Boolean);
    }
  }

  private updateOtherTags(addLocationDetail: any){
    // alternative names
    var typeBUFFER = ''
    this.alternativeNamesTags.forEach(name => {
      typeBUFFER = typeBUFFER.concat(name)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationDetail.alternativeNames = typeBUFFER
    } else {addLocationDetail.alternativeNames = ''}
    //trismegistos url
    typeBUFFER = ''
    this.urlsTrismegistos.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationDetail.urlsTrismegistos = typeBUFFER
    } else {addLocationDetail.urlsTrismegistos = ''}
    //pleiades url
    typeBUFFER = ''
    this.urlsPleiades.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationDetail.urlsPleiades = typeBUFFER
    } else {addLocationDetail.urlsPleiades = ''}
    //see further url
    typeBUFFER = ''
    this.urlsSeeFurther.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      addLocationDetail.urlsSeeFurther = typeBUFFER
    } else {addLocationDetail.urlsSeeFurther = ''}
  }

  associatedLocationSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? of([]).pipe(map(value => {if (term.length == 0) {this.associatedLocation = null; this.model.associatedLocation = null;} return []}))
        : this.defaultApi.readAllLocations(this.mainApp.user.email, this.mainApp.user.idToken, undefined, undefined, "id", "ASC", term).pipe(
          map(value => {
            if (value.locations.length > 0) {
              value.locations.sort((a, b) => a.name.localeCompare(b.name))
              return value.locations
            } else {
              return []
            }
          })
        ))
    )
}
