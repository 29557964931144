import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DefaultService } from '../rest';
import { Location } from '../rest/model/location';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AppComponent } from '../app.component'
import { Observable, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { tap, map, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as d3 from 'd3';
import * as d3queue from 'd3-queue';
import * as svgPanZoom from 'svg-pan-zoom';
import { zoom, center } from 'svg-pan-zoom';
import *  as L from 'leaflet';
import * as L1 from 'leaflet.markercluster';


export class LocMapDataSource implements DataSource<Location> {
  
  private lessonsSubject = new BehaviorSubject<Location[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  
  public loading$ = this.loadingSubject.asObservable();
  public totalCount = 0;
  public locations;
  
  constructor(private defaultApi: DefaultService, private userMail: any, private apiKey: any) { }
  
  connect(collectionViewer: CollectionViewer): Observable<Location[]> {
    return this.lessonsSubject.asObservable();
  }
  
  disconnect(collectionViewer: CollectionViewer): void {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }
  
  loadLocations(filter: string = '', sortField: string = 'id', sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = 20) {
    this.defaultApi.readGeoMapData(this.userMail, this.apiKey, pageIndex * pageSize, pageSize, sortField, sortDirection, filter).subscribe(
      value => {
        this.totalCount = value.total
        this.lessonsSubject.next(value.locations);
        this.locations = value.locations;
      });
    }
  }
  
  @Component({
    selector: 'app-LocMap',
    templateUrl: './locmap.component.html',
    styleUrls: ['./locmap.component.css']
  })
export class LocMapComponent implements OnInit {
    
    locmap: any;
    show: boolean = false;
    displayedColumns = ['id', 'name', 'wikiUrl', 'latitude', 'longitude', 'year', 'createdAt', 'userCreation', 'delete'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    dataSource: LocMapDataSource;
    lastFilter: string = '';
    tagsID_entry: null;
    suggestedTagsLocs: string[] = [];
    suggestedTagsLocsWithIds;
    suggestedTagsDocs: string[] = [];
    suggestedTagsDocsWithIds;
    suggestedTagsPs: string[] = [];
    suggestedTagsPsWithIds;
    provenanceCheck: false;
    originCheck: false;
    conservationPlaceCheck: false;
    geojsonPath;    
    
    constructor(private documentApi: DefaultService,
      private mainApp: AppComponent, private router: Router) { }
          
      ngOnInit() {
        this.dataSource = new LocMapDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
        //this.dataSource.loadLocations()
        this.documentApi.readGeoMapData(this.mainApp.user.email, this.mainApp.user.idToken, 0, 9000000000000, 'id', 'asc', '').subscribe(
          async value => {
            this.geojsonPath = value.geojson;
            this.makeMap();
            //await this.loadData(value);
          });
        this.documentApi.retrieveSuggestedTags('locations', 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (value != null) {
              //console.log(value);
              var suggestedTagsLocsWithIds = {};
              if (value.tagsIds != '') {
                this.suggestedTagsLocs = value.suggestions.split('||^||').filter(Boolean);
                this.suggestedTagsLocs = this.suggestedTagsLocs.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;}) // delete duplicate
                var tagString = value.tagsIds.split('!!-!!').filter(Boolean);
                tagString.forEach(function(t){
                  var aux = t.split('@@@')
                  suggestedTagsLocsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
                })
              }
              this.suggestedTagsLocsWithIds = suggestedTagsLocsWithIds;
            }
          }
        );
        this.documentApi.retrieveSuggestedTags('document-detail', 'tags', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (value != null) {
              //console.log(value);
              var suggestedTagsDocsWithIds = {};
              if (value.tagsIds != '') {
                this.suggestedTagsDocs = value.suggestions.split('||^||').filter(Boolean);
                this.suggestedTagsDocs = this.suggestedTagsDocs.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;}) // delete duplicate
                var tagString = value.tagsIds.split('!!-!!').filter(Boolean);
                tagString.forEach(function(t){
                  var aux = t.split('@@@')
                  suggestedTagsDocsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
                })
              }
              this.suggestedTagsDocsWithIds = suggestedTagsDocsWithIds
            }
          }
        );
        this.documentApi.retrieveSuggestedTags('ps-detail', 'tags', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
          value => {
            if (value != null) {
              //console.log(value);
              var suggestedTagsPsWithIds = {};
              if (value.tagsIds != '') {
                this.suggestedTagsPs = value.suggestions.split('||^||').filter(Boolean);
                this.suggestedTagsPs = this.suggestedTagsPs.filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;}) // delete duplicate
                var tagString = value.tagsIds.split('!!-!!').filter(Boolean);
                tagString.forEach(function(t){
                  var aux = t.split('@@@')
                  suggestedTagsPsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
                })
              }
              this.suggestedTagsPsWithIds = suggestedTagsPsWithIds;
            }
          }
        );
      }

      /*async loadData(value){
        var suggestedTagsLocsWithIds = {};
        var suggestedTagsDocsWithIds = {};
        var suggestedTagsPsWithIds = {};
        if (value.locsTagsIds != '') {
          this.suggestedTagsLocs = value.locsTagsSuggestions.split('||^||').filter(Boolean);
          this.suggestedTagsLocs = this.suggestedTagsLocs.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicate
          var tagString = value.locsTagsIds.split('!!-!!').filter(Boolean);
          tagString.forEach(function(t){
            var aux = t.split('@@@')
            suggestedTagsLocsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
          })
        }
        if (value.docsTagsIds != '') {
          this.suggestedTagsDocs = value.docsTagsSuggestions.split('||^||').filter(Boolean);
          this.suggestedTagsDocs = this.suggestedTagsDocs.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicate
          var tagString = value.docsTagsIds.split('!!-!!').filter(Boolean);
          tagString.forEach(function(t){
            var aux = t.split('@@@')
            suggestedTagsDocsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
          })
        }
        if (value.psTagsIds != '') {
          this.suggestedTagsPs = value.psTagsSuggestions.split('||^||').filter(Boolean);
          this.suggestedTagsPs = this.suggestedTagsPs.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicate
          var tagString = value.psTagsIds.split('!!-!!').filter(Boolean);
          tagString.forEach(function(t){
            var aux = t.split('@@@')
            suggestedTagsPsWithIds[aux[0]] = aux[1].split('||^||').filter(Boolean)
          })
        }
        this.geojsonPath = value.geojson
        this.suggestedTagsLocsWithIds = suggestedTagsLocsWithIds
        this.suggestedTagsDocsWithIds = suggestedTagsDocsWithIds
        this.suggestedTagsPsWithIds = suggestedTagsPsWithIds
      }*/
      
      @ViewChild('lastFilterVC', { static: false }) lastFilterVC: ElementRef;

      makeMap(){
        document.getElementById('origin').dispatchEvent(new Event('click'))
        document.getElementById('provenance').dispatchEvent(new Event('click'))
        //BARCHART
        // set the dimensions and margins of the graph
        var marginBC = {top: 30, right: 30, bottom: 150, left: 60},
        widthBC = window.screen.width - 50 - marginBC.left - marginBC.right,
        heightBC = window.screen.height/2 - marginBC.top - marginBC.bottom;

        // append the svg object to the body of the page
        var svgBC = d3.select("#barchart-container")
        .append("svg")
        .attr("width", widthBC + marginBC.left + marginBC.right)
        .attr("height", heightBC + marginBC.top + marginBC.bottom)
        .append("g")
        .attr("transform",
              "translate(" + marginBC.left + "," + marginBC.top + ")");

        // Initialize the X axis
        var xBC = d3.scale.ordinal()
        .rangeRoundBands([ 0, widthBC ], 0.2)
        var xAxisBC = svgBC.append("g")
        .attr("class","x axis")
        .attr("transform", "translate(0," + heightBC + ")")

        // Initialize the Y axis
        var yBC = d3.scale.linear()
        .range([ heightBC, 0]);
        var yAxisBC = svgBC.append("g")
        .attr("class", "y axis")
        
        var geojson,
          metadata,
          filteredData = [],
          dataForTimeline = [],
          dateToDocCount = {},
          featuresBackup = this.geojsonPath.features,
          filteredFeaturesBackup = this.geojsonPath.features,
          suggestedTagsDocsWithIds = this.suggestedTagsDocsWithIds,
          suggestedTagsLocsWithIds = this.suggestedTagsLocsWithIds,
          suggestedTagsPsWithIds = this.suggestedTagsPsWithIds,
          categoryField = '5074', //This is the fieldname for marker category (used in the pie and legend)
          iconField = '5065', //This is the fieldame for marker icon
          popupFields = ['5085','5055','5074'], //Popup will display these fields
          //tileServer = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          tileServer = 'http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          tileAttribution = 'Map data: <a href="http://openstreetmap.org" target="_blank">OSM</a>',
          rmax = 30, //Maximum radius for cluster pies
          markerclusters = L.markerClusterGroup({
            maxClusterRadius: 2*rmax,
            iconCreateFunction: defineClusterIcon //this is where the magic happens
          }),
          map = L.map('map').setView([59.95, 10.78], 8);

        
        //Add basemap
        L.tileLayer(tileServer, {attribution: tileAttribution,  maxZoom: 15}).addTo(map);
        //and the empty markercluster layer
        map.addLayer(markerclusters);
        
        //Ready to go, load the geojson

        geojson = this.geojsonPath;
        metadata = this.geojsonPath.properties;
        var markers = L.geoJSON(geojson, {
          pointToLayer: defineFeature,
          onEachFeature: defineFeaturePopup
        });
        markerclusters.addLayer(markers);

        markerclusters.on("clustermouseover",function(m){
          var text = '<div class="clusterPopup">';
          m.layer.getAllChildMarkers().forEach(function(f){
            var label = ''
            if(f.feature.properties['5074'] == 1){
              label = 'Origin'
            } else if( f.feature.properties['5074'] == 2){
              label = 'Provenance'
            } else if( f.feature.properties['5074'] == 3){
              label = 'Conservation Place'
            }
            if(f.feature.properties['5099'] != undefined){
              text += '<span class="attribute">PS '+ f.feature.properties['5097'] +' in ' + f.feature.properties['5099'] +'</span><br>'
            }
            if(f.feature.properties['5100'] != undefined){
              text += '<span class="attribute">'+ label + ' of ' + f.feature.properties['5100'] +'</span><br>'
            }
          })
          text += "</div>"
          m.layer.bindPopup(text,{offset: L.point(1,-2)});
          m.layer.openPopup()
        })
        /*markerclusters.on("clustermouseout",function(m){
          m.layer.closePopup()
        })*/

        if(geojson.features.length > 0 && geojson.features){
          map.fitBounds(markers.getBounds());
        }
        map.attributionControl.addAttribution(metadata.attribution);
        buildTimelineData();

        d3.select('#origin').on('click', function(){
          filterData()
          buildTimelineData()
        })
        d3.select('#provenance').on('click', function(){
          filterData()
          buildTimelineData()
        })
        d3.select('#conservationPlace').on('click', function(){
          filterData()
          buildTimelineData()
        })
        d3.select('#psButton').on('click', function(){
          if(!this.classList.contains('active')){
            this.classList.remove('unactive')
            this.classList.toggle("active");
            d3.select('#docButton').attr('class','unactive');
          }
          (<HTMLInputElement>document.getElementById('conservationPlace')).checked = true;
          (<HTMLInputElement>document.getElementById('origin')).checked = false;
          (<HTMLInputElement>document.getElementById('provenance')).checked = false;
          (<HTMLDivElement>document.getElementById('legend')).innerHTML = '<div class="box conP"></div><span class="legend">Conservation Place </span>';
          (<HTMLSpanElement>document.getElementById('titleName')).innerHTML = 'Conservation Places of Material Supports';
          d3.select('#filterTime-container').style('display','none')
          d3.select('#filterTagsDocs-container').style('display','none')
          d3.select('#filterTagsPs-container').style('display','block')
          d3.select('#filterTagsLocs-container').style('display','block')
          d3.select('#filterTypeCity-container').style('display','none')

          resetFilters()
        })

        d3.select('#docButton').on('click', function(){
          if(!this.classList.contains('active')){
            this.classList.remove('unactive')
            this.classList.toggle("active");
            d3.select('#psButton').attr('class','unactive');
          }
          (<HTMLInputElement>document.getElementById('conservationPlace')).checked = false;
          (<HTMLInputElement>document.getElementById('origin')).checked = true;
          (<HTMLInputElement>document.getElementById('provenance')).checked = true;
          (<HTMLDivElement>document.getElementById('legend')).innerHTML = '<div class="box ori"></div><span class="legend">Origin </span><br><br><div class="box pro"></div><span class="legend">Provenance </span>';
          (<HTMLSpanElement>document.getElementById('titleName')).innerHTML = 'Origin & Provenance  of Documents';
          d3.select('#filterTime-container').style('display','block')
          d3.select('#filterTagsDocs-container').style('display','block')
          d3.select('#filterTagsPs-container').style('display','none')
          d3.select('#filterTagsLocs-container').style('display','block')
          d3.select('#filterTypeCity-container').style('display','block')

          
          resetFilters()
        })


        d3.select('#showMap').on('click', function(){
          if(!this.classList.contains('active')){
            this.classList.remove('unactive')
            this.classList.toggle("active");
            d3.select('#showBarChart').attr('class','unactive');
          }
          d3.select('#map-container').style("display","block")
          d3.select('#barchart-container').style("display","none")
          d3.select('#legendContainer').style("display","block")
        })
        d3.select('#showBarChart').on('click', function(){
          if(!this.classList.contains('active')){
            this.classList.remove('unactive')
            this.classList.toggle("active");
            d3.select('#showMap').attr('class','unactive');
          }
          d3.select('#map-container').style("display","none")
          d3.select('#barchart-container').style("display","block")
          d3.select('#legendContainer').style("display","none")
        })
        d3.select('#resetFilters').on('click', function(){
          resetFilters()
        })
        
        d3.select('#applyTagsLocsFilter').on('click', function(){
          var text = '';
          tagsLocs.forEach(function(t){
            text += t + ' - '
          });
          text = text.slice(0, -3);
          (<HTMLSpanElement>document.getElementById('titleFiltersLocs')).innerHTML = text;
          filterData();
          buildTimelineData()
        })

        d3.select('#applyTagsDocsFilter').on('click', function(){
          var text = '';
          tagsDocs.forEach(function(t){
            text += t + ' - '
          });
          text = text.slice(0, -3);
          (<HTMLSpanElement>document.getElementById('titleFiltersDocs')).innerHTML = text;
          filterData();
          buildTimelineData()
        })

        d3.select('#applyTagsPsFilter').on('click', function(){
          var text = '';
          tagsPs.forEach(function(t){
            text += t + ' - '
          });
          text = text.slice(0, -3);
          (<HTMLSpanElement>document.getElementById('titleFiltersPs')).innerHTML = text;
          filterData();
          buildTimelineData()
        })

        d3.select('#openNavBtn').on('click', function(){
          document.getElementById("mySidenav").style.width = "30%";
          document.getElementById("panel").style.filter = "brightness(70%)";
          document.getElementById("navBarTop").style.filter = "brightness(70%)";
          document.body.style.backgroundColor = "grey";
        });
        
        d3.select('#closeNavBtn').on('click', function(){
          document.getElementById("mySidenav").style.width = "0";
          document.getElementById("panel").style.filter = "brightness(100%)";
          document.getElementById("navBarTop").style.filter = "brightness(100%)";
          document.body.style.backgroundColor = "white";
        });
        var dropdown = document.getElementsByClassName("dropdown-btn");
        var i;

        for (i = 0; i < dropdown.length; i++) {
          dropdown[i].addEventListener("click", function() {
          this.classList.toggle("active");
          var dropdownContent = this.nextElementSibling;
          if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
          } else {
          dropdownContent.style.display = "block";
          }
          });
        }

        const tagsLocsSelect = (document.getElementById('tagsLocs-select') as HTMLSelectElement);
        const tagsDocsSelect = (document.getElementById('tagsDocs-select') as HTMLSelectElement);
        const tagsPsSelect = (document.getElementById('tagsPs-select') as HTMLSelectElement);

        
        this.suggestedTagsLocs.forEach(tag => {
          var opt = (document.createElement('option') as HTMLOptionElement)
          opt.appendChild(document.createTextNode(tag))
          opt.value = tag
          tagsLocsSelect.appendChild(opt)
        });
        this.suggestedTagsDocs.forEach(tag => {
          var opt = (document.createElement('option') as HTMLOptionElement)
          opt.appendChild(document.createTextNode(tag))
          opt.value = tag
          tagsDocsSelect.appendChild(opt)
        });
        this.suggestedTagsPs.forEach(tag => {
          var opt = (document.createElement('option') as HTMLOptionElement)
          opt.appendChild(document.createTextNode(tag))
          opt.value = tag
          tagsPsSelect.appendChild(opt)
        });

        const tagsLocsContainer = (document.getElementById('tagsLocsContainer') as HTMLDivElement);
        const tagsDocsContainer = (document.getElementById('tagsDocsContainer') as HTMLDivElement);
        const tagsPsContainer = (document.getElementById('tagsPsContainer') as HTMLDivElement);
        //const input = (document.querySelector('.tag-map-container input') as HTMLInputElement);
        let tagsLocs = [];
        let tagsDocs = [];
        let tagsPs = [];

        (<HTMLInputElement>document.getElementById('conservationPlace')).checked = true;
        (<HTMLInputElement>document.getElementById('origin')).checked = false;
        (<HTMLInputElement>document.getElementById('provenance')).checked = false;
        filterData();

        function createTag(label, f) {
          const div = document.createElement('div');
          if(f == 1){
            div.setAttribute('class', 'tag locsT');
          } else if(f == 2){
            div.setAttribute('class', 'tag docsT');
          } else if(f == 3){
            div.setAttribute('class', 'tag psT');
          }
          const span = document.createElement('span');
          span.innerHTML = label;
          const closeIcon = document.createElement('i');
          closeIcon.innerHTML = 'close';
          if(f == 1){
            closeIcon.setAttribute('class', 'material-icons locsI');
          } else if(f == 2){
            closeIcon.setAttribute('class', 'material-icons docsI');
          } else if(f == 3){
            closeIcon.setAttribute('class', 'material-icons psI');
          }
          closeIcon.setAttribute('data-item', label);
          div.appendChild(span);
          div.appendChild(closeIcon);
          return div;
        }

        function clearTagsLocs() {
          document.querySelectorAll('.locsT').forEach(tag => {
            tag.parentElement.removeChild(tag);
          });
        }

        function clearTagsDocs() {
          document.querySelectorAll('.docsT').forEach(tag => {
            tag.parentElement.removeChild(tag);
          });
        }
        function clearTagsPs() {
          document.querySelectorAll('.psT').forEach(tag => {
            tag.parentElement.removeChild(tag);
          });
        }

        function addTagsLocs() {
          clearTagsLocs();
          tagsLocs.slice().reverse().forEach(tag => {
            tagsLocsContainer.prepend(createTag(tag,1));
          });
        }

        function addTagsDocs() {
          clearTagsDocs();
          tagsDocs.slice().reverse().forEach(tag => {
            tagsDocsContainer.prepend(createTag(tag,2));
          });
        }

        function addTagsPs() {
          clearTagsPs();
          tagsPs.slice().reverse().forEach(tag => {
            tagsPsContainer.prepend(createTag(tag,3));
          });
        }

        /*input.addEventListener('keyup', (e) => {
            if (e.key === 'Enter') {
              (e.target as HTMLInputElement).value.split(',').forEach(tag => {
                tags.push(tag);  
              });
              
              addTags();
              input.value = '';
            }
        });*/

        document.addEventListener('click', (e) => {
          if ((e.target as HTMLInputElement).tagName === 'I' && (e.target as HTMLInputElement).classList.contains('locsI')) {
            const tagLabel = (e.target as HTMLInputElement).getAttribute('data-item');
            const index = tagsLocs.indexOf(tagLabel);
            //tagsLocs = [...tagsLocs.slice(0, index), ...tagsLocs.slice(index+1)];
            addTagsLocs();
          }
        })
        document.addEventListener('click', (e) => {
          if ((e.target as HTMLInputElement).tagName === 'I' && (e.target as HTMLInputElement).classList.contains('docsI')) {
            const tagLabel = (e.target as HTMLInputElement).getAttribute('data-item');
            const index = tagsDocs.indexOf(tagLabel);
            //tagsDocs = [...tagsDocs.slice(0, index), ...tagsDocs.slice(index+1)];
            addTagsDocs();
          }
        })
        document.addEventListener('click', (e) => {
          if ((e.target as HTMLInputElement).tagName === 'I' && (e.target as HTMLInputElement).classList.contains('psI')) {
            const tagLabel = (e.target as HTMLInputElement).getAttribute('data-item');
            const index = tagsPs.indexOf(tagLabel);
            //tagsPs = [...tagsPs.slice(0, index), ...tagsPs.slice(index+1)];
            addTagsPs();
          }
        })

        tagsLocsSelect.addEventListener('change', (e) => {
          if(!tagsLocs.includes((e.target as HTMLInputElement).value)){
            tagsLocs.push((e.target as HTMLInputElement).value);
            tagsLocsContainer.prepend(createTag((e.target as HTMLInputElement).value, 1))
          }
        })

        tagsDocsSelect.addEventListener('change', (e) => {
          if(!tagsDocs.includes((e.target as HTMLInputElement).value)){
            tagsDocs.push((e.target as HTMLInputElement).value);
            tagsDocsContainer.prepend(createTag((e.target as HTMLInputElement).value, 2))
          }
        })

        tagsPsSelect.addEventListener('change', (e) => {
          if(!tagsPs.includes((e.target as HTMLInputElement).value)){
            tagsPs.push((e.target as HTMLInputElement).value);
            tagsPsContainer.prepend(createTag((e.target as HTMLInputElement).value, 3))
          }
        })

      function defineFeature(feature, latlng) {
        var categoryVal = feature.properties[categoryField],
          iconVal = feature.properties[iconField];
          var myClass = 'marker category-'+categoryVal+' icon-'+iconVal;
          var myIcon = L.divIcon({
              className: myClass,
              iconSize:null
          });
          return L.marker(latlng, {icon: myIcon});
      }

      function defineFeaturePopup(feature, layer) {
        var props = feature.properties,
          fields = metadata.fields,
          popupContent = '';
          
        popupFields.map( function(key) {
          if (props[key]) {
            var val = props[key],
              label = fields[key].name;
            if (fields[key].lookup) {
              val = fields[key].lookup[val];
            }
            popupContent += '<span class="attribute"><span class="label">'+label+':</span> '+val+'</span>';
          }
        });
        if(props['5098'] != undefined){
          popupContent += '<span class="attribute"><span class="label">Document:</span> '+props['5100']+'</span>';
          popupContent += '<span class="attribute"><a href="/docdetail/'+props['5098']+'" target="_blank">Open Document</a></span>'
        }
        if(props['5097'] != undefined){
          popupContent += '<span class="attribute"><span class="label">Material Support:</span> '+props['5099']+'</span>';
          popupContent += '<span class="attribute"><a href="/psdetail/'+props['5097']+'" target="_blank">Open Material Support</a></span>'
        }
        popupContent += '<span class="attribute"><a href="/placedetail/'+props['5095']+'" target="_blank">Open City Detail</a></span>'
        if (props['5096'] != null) {
          popupContent += '<span class="attribute"><a href="'+props['5096']+'" target="_blank">Open on Wikipedia</a></span>'
        }
        popupContent = '<div class="map-popup">'+popupContent+'</div>';
        layer.bindPopup(popupContent,{offset: L.point(1,-2)});
      }

      function defineClusterIcon(cluster) {
          var children = cluster.getAllChildMarkers(),
              n = children.length, //Get number of markers in cluster
              strokeWidth = 1, //Set clusterpie stroke width
              r = rmax-2*strokeWidth-(n<10?12:n<100?8:n<1000?4:0), //Calculate clusterpie radius...
              iconDim = (r+strokeWidth)*2, //...and divIcon dimensions (leaflet really want to know the size)
              data = d3.nest() //Build a dataset for the pie chart
                .key(function(d) { return d.feature.properties[categoryField]; })
                .entries(children, d3.map),
              //bake some svg markup
              html = bakeThePie({data: data,
                                  valueFunc: function(d){return d.values.length;},
                                  strokeWidth: 1,
                                  outerRadius: r,
                                  innerRadius: r-10,
                                  pieClass: 'cluster-pie',
                                  pieLabel: n,
                                  pieLabelClass: 'marker-cluster-pie-label',
                                  pathClassFunc: function(d){return "category-"+d.data.key;},
                                  pathTitleFunc: function(d){return metadata.fields[categoryField].lookup[d.data.key]+' ('+d.data.values.length+' accident'+(d.data.values.length!=1?'s':'')+')';}
                                }),
              //Create a new divIcon and assign the svg markup to the html property
              myIcon = new L.DivIcon({
                  html: html,
                  className: 'marker-cluster', 
                  iconSize: new L.Point(iconDim, iconDim)
              });
          return myIcon;
      }

      /*function that generates a svg markup for the pie chart*/
      function bakeThePie(options) {
          /*data and valueFunc are required*/
          if (!options.data || !options.valueFunc) {
              return '';
          }
          var data = options.data,
              valueFunc = options.valueFunc,
              r = options.outerRadius?options.outerRadius:28, //Default outer radius = 28px
              rInner = options.innerRadius?options.innerRadius:r-10, //Default inner radius = r-10
              strokeWidth = options.strokeWidth?options.strokeWidth:1, //Default stroke is 1
              pathClassFunc = options.pathClassFunc?options.pathClassFunc:function(){return '';}, //Class for each path
              pathTitleFunc = options.pathTitleFunc?options.pathTitleFunc:function(){return '';}, //Title for each path
              pieClass = options.pieClass?options.pieClass:'marker-cluster-pie', //Class for the whole pie
              pieLabel = options.pieLabel?options.pieLabel:d3.sum(data,valueFunc), //Label for the whole pie
              pieLabelClass = options.pieLabelClass?options.pieLabelClass:'marker-cluster-pie-label',//Class for the pie label
              
              origo = (r+strokeWidth), //Center coordinate
              w = origo*2, //width and height of the svg element
              h = w,
              donut = d3.layout.pie(),
              arc = d3.svg.arc().innerRadius(rInner).outerRadius(r);
              
          //Create an svg element
          var svg = document.createElementNS(d3.ns.prefix.svg, 'svg');
          //Create the pie chart
          var vis = d3.select(svg)
              .data([data])
              .attr('class', pieClass)
              .attr('width', w)
              .attr('height', h);
              
          var arcs = vis.selectAll('g.arc')
              .data(donut.value(valueFunc))
              .enter().append('svg:g')
              .attr('class', 'arc')
              .attr('transform', 'translate(' + origo + ',' + origo + ')');
          
          arcs.append('svg:path')
              .attr('class', pathClassFunc)
              .attr('stroke-width', strokeWidth)
              .attr('d', arc)
              .append('svg:title')
                .text(pathTitleFunc);
                      
          vis.append('text')
              .attr('x',origo)
              .attr('y',origo)
              .attr('class', pieLabelClass)
              .attr('text-anchor', 'middle')
              //.attr('dominant-baseline', 'central')
              /*IE doesn't seem to support dominant-baseline, but setting dy to .3em does the trick*/
              .attr('dy','.3em')
              .text(pieLabel);
              
          //Return the svg-markup rather than the actual element
          return serializeXmlNode(svg);
      }

      

      /*Helper function*/
      function serializeXmlNode(xmlNode) {
          if (typeof (<any>window).XMLSerializer != "undefined") {
              return (new (<any>window).XMLSerializer()).serializeToString(xmlNode);
          } else if (typeof xmlNode.xml != "undefined") {
              return xmlNode.xml;
          }
          return "";
      }

      function filterData(){
        var fData = [],
            res = [];
        var checkO = d3.select('#origin').property('checked')
        var checkP = d3.select('#provenance').property('checked')
        var checkCP = d3.select('#conservationPlace').property('checked')
        if(!checkO && !checkP && !checkCP){
          alert('Select location type!')
          return
        }
        geojson.features = featuresBackup
        
        if (checkO && checkP && checkCP){
          fData = geojson.features
        } else {
          geojson.features.forEach(function(f){
            if (checkO && f.properties['5065'] == '1'){
              fData.push(f)
            } else if (checkP && f.properties['5065'] == '2'){
              fData.push(f)
            } else if (checkCP && f.properties['5065'] == '3'){
              fData.push(f)
            }
          });
        }
          fData.forEach(function(f){
            var check = true;
            
            tagsLocs.forEach(function(t){
              if (suggestedTagsLocsWithIds[f.properties['5095']] != undefined){
                if(!suggestedTagsLocsWithIds[f.properties['5095']].includes(t)){
                  check = false
                }
              } else {
                check = false
              }
            });
            tagsDocs.forEach(function(t){
              if (suggestedTagsDocsWithIds[f.properties['5098']] != undefined){
                if(!suggestedTagsDocsWithIds[f.properties['5098']].includes(t)){
                  check = false
                }
              } else {
                check = false
              }
            });
            tagsPs.forEach(function(t){
              if (suggestedTagsPsWithIds[f.properties['5097']] != undefined){
                if(!suggestedTagsPsWithIds[f.properties['5097']].includes(t)){
                  check = false
                }
              } else {
                check = false
              }
            });
            if(check == true){
              res.push(f)
            }
          });
          
          geojson.features.forEach(function(f){
            if(!res.includes(f)){
              filteredData.push(f)
            } else {
              if(filteredData.indexOf(f)){
                filteredData = filteredData.splice(filteredData.indexOf(f),1)
              }
            }
          })
          if(tagsLocs != []){
            geojson.features = res;
            filteredFeaturesBackup = res
          } else {
            geojson.features = fData;
            filteredFeaturesBackup = fData;
          }
          
          markerclusters.removeLayer(markers)
          markers = L.geoJSON(geojson, {
            pointToLayer: defineFeature,
            onEachFeature: defineFeaturePopup
          });
          markerclusters.addLayer(markers);
          if(geojson.features.length > 0 && geojson.features){
            map.fitBounds(markers.getBounds());
          }
          updateBarChart()

      }

      function resetFilters(){
        geojson.features = featuresBackup
        markerclusters.removeLayer(markers)
        markers = L.geoJSON(geojson, {
          pointToLayer: defineFeature,
          onEachFeature: defineFeaturePopup
        });
        markerclusters.addLayer(markers);
        if(geojson.features.length > 0 && geojson.features){
          map.fitBounds(markers.getBounds());
        }
        tagsLocs = [];
        tagsDocs = [];
        tagsPs = [];
        (<HTMLSpanElement>document.getElementById('titleFiltersLocs')).innerHTML = '';
        (<HTMLSpanElement>document.getElementById('titleFiltersDocs')).innerHTML = '';
        (<HTMLSpanElement>document.getElementById('titleFiltersPs')).innerHTML = '';
        filterData();
        buildTimelineData();
        updateBarChart();
      }

      function updateBarChart(){
        // Update the X axis
        let dataobj = new Map()
        geojson.features.forEach(function(f){
          if(f.properties['5096'] != null && f.properties['5096'] != ''){
            if (!Object.keys(dataobj).includes(f.properties['5096'].replace('https://en.wikipedia.org/wiki/','').replace(',','').replace('_',' '))){
              var key = f.properties['5096'].replace('https://en.wikipedia.org/wiki/','').replace(',','').replace('_',' ')
              var value = countLocsBarChart(f)
              dataobj[key] = value
            }
          }
          
        });
        let data = []
        Object.keys(dataobj).forEach(function(k){
          data.push({"properties":{'name':k,'value':dataobj[k]}})
        })
        xBC.domain(data.map(function(d) { return d.properties['name']; }))
        xAxisBC.call(d3.svg.axis().scale(xBC))
        var ticks = xAxisBC.selectAll("text")
          .attr("y", 0)
          .attr("x", 9)
          .attr("dy", ".50em")
          .attr("transform", "rotate(90)")
          .style("text-anchor", "start");

        // Update the Y axis
        yBC.domain([0, d3.max(data, function(d) { return d.properties['value'] }) ]);
        yAxisBC.transition().duration(1000).call(d3.svg.axis().scale(yBC).orient('left'));
        svgBC.selectAll("rect").remove()
        // Create the u variable
        var u = svgBC
        data.forEach(function(d){
          u
            .append("rect") // Add a new rect for each new elements
            .transition() // and apply changes to all of them
            .duration(1000)
              .attr("x", function() { return xBC(d.properties['name']); })
              .attr("y", function() { return yBC(d.properties['value']); })
              .attr("id", function() { return d.properties['name']+ '#' + d.properties['value']; })
              .attr("width", xBC.rangeBand())
              .attr("height", function() { return heightBC - yBC(d.properties['value']); })
              .attr("fill", "#69b3a2")
              
          })
          svgBC.selectAll('rect')
            .on('mouseover', mouseoverBC)
            .on('mousemove', mousemoveBC)
            .on('mouseout', mouseoutBC)
            .on('click', filterDatabyBar);

          var div = d3.select('#barchart-container').append('div')
              .attr('class', 'tooltipBC')
              .style('display', 'none');
          function mouseoverBC(){
            d3.select(this)
              .attr('fill','#007bff')
              .style("cursor","pointer")

            div.style('display', 'inline');
          }
          function mousemoveBC(){
              var d = d3.select(this).attr("id")
              var name = d.split('#')[0]
              var value = d.split('#')[1]
              div
                .html(name + '<hr/>' + value)
                .style('left', (d3.event.pageX + 20) + 'px')
                .style('top', (d3.event.pageY - 20) + 'px');
          }
          function mouseoutBC(){
            d3.select(this)
              .attr("fill", "#69b3a2")
              .style("cursor","default")
            div.style('display', 'none');
          }
          function filterDatabyBar(){
            d3.select('#map-container').style("display","block")
            d3.select('#barchart-container').style("display","none")
            var newData = [];
            var d = d3.select(this).attr("id")
            var name = d.split('#')[0]
            geojson.features.forEach(function(f){
              if(f.properties['5096'] != null && f.properties['5096'] != '' && f.properties['5096'].replace('https://en.wikipedia.org/wiki/','').replace(',','').replace('_',' ') == name){
                newData.push(f)
              }
            });
            geojson.features = newData;
            markerclusters.removeLayer(markers)
            markers = L.geoJSON(geojson, {
              pointToLayer: defineFeature,
              onEachFeature: defineFeaturePopup
            });
            markerclusters.addLayer(markers);
            if(geojson.features.length > 0 && geojson.features){
              map.fitBounds(markers.getBounds());
            }
          }

      }

      function makeTimeline() {
        var margin = { top: 10, right: 10, bottom: 20, left: 40 },
        width  = window.screen.width/3.5 - margin.left - margin.right,
        height = 100 - margin.top  - margin.bottom;
        
        var timelineSvg = d3.select("#timeline-container").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);
        
        var timeline = timelineSvg.append("g")
        .attr("class", "timeline")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
        var x = d3.scale.linear()
        .domain(d3.extent(dataForTimeline.map(function(d) {return d.YEAR })))
        .range([0, width]);

        var y = d3.scale.linear()
        .domain([0,d3.extent(dataForTimeline.map(function(d) { return d.TOT; }))[1]])
        .range([height, 0]);
        
        var xAxis = d3.svg.axis()
        .scale(x)
        .orient("bottom");
        
        var yAxis = d3.svg.axis()
        .scale(y)
        .orient("left")
        .ticks(3);
        
        var area = d3.svg.area()
        .interpolate("linear")
        .x(function(d) { return x(d.YEAR); })
        .y0(height)
        .y1(function(d) { return y(d.TOT); });
        

        var minMax = d3.extent(dataForTimeline.map(function(d) { return d.YEAR; }))
        d3.select('#timelineFrom').attr('min', minMax[0])
        d3.select('#timelineFrom').attr('max', minMax[1])
        d3.select('#timelineTo').attr('min', minMax[0])
        d3.select('#timelineTo').attr('max', minMax[1])

        d3.select('#timelineFrom').on('change',function(value){
          var val = parseInt((<HTMLInputElement>document.getElementById('timelineFrom')).value)
          var min = parseInt(minMax[0])
          var max = parseInt(minMax[1])
          if(val < min){
            (<HTMLInputElement>document.getElementById('timelineFrom')).value = minMax[0]
          }
          if(val > max){
            (<HTMLInputElement>document.getElementById('timelineFrom')).value = minMax[1]
          }
        })
        d3.select('#timelineTo').on('change',function(value){
          var val = parseInt((<HTMLInputElement>document.getElementById('timelineTo')).value)
          var min = parseInt(minMax[0])
          var max = parseInt(minMax[1])          
          if(val < min){
            (<HTMLInputElement>document.getElementById('timelineTo')).value = minMax[0]
          }
          if(val > max){
            (<HTMLInputElement>document.getElementById('timelineTo')).value = minMax[1]
          }
        })
        
        timeline.append("path")
        .datum(dataForTimeline)
        .attr("class", "area")
        .attr("d", area);
        
        timeline.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
        
        /*timeline.append("g")
        .attr("class", "y axis")
        .call(yAxis);*/
        
        /*timeline.append("text")
        .attr("transform", "rotate(-90)")
        .attr("dy", "1em")
        .style("text-anchor", "end")
        .text("Qty");*/

        timeline.append("text")
        .attr("dy", "6.5em")
        .attr("dx", width-5)
        .style("text-anchor", "end")
        .text("Years");
        
        // Add brush to timeline, hook up to callback
        var brush = d3.svg.brush()
        .x(x)
        .on("brush", function() { brushCallback(brush); })
        //.extent([300, 350]); // initial value
        
        timeline.append("g")
        .attr("class", "x brush")
        .call(brush)
        .selectAll("rect")
        .attr("y", -6)
        .attr("height", height + 7);

        d3.select('#btnTimelineAges').on('click', function(){
          var from = (<HTMLInputElement>document.getElementById('timelineFrom')).value;
          var to = (<HTMLInputElement>document.getElementById('timelineTo')).value;
          brush.extent([from,to])
          brush(d3.select(".brush").transition().duration(500));
          brush.event(d3.select(".brush").transition().delay(0).duration(500));
          (<HTMLSpanElement>document.getElementById('titleYears')).innerHTML = from + ' - ' + to;
        })
        
        brush.event(timeline.select('g.x.brush')); // dispatches a single brush event
      };
      
      // Called whenever the timeline brush range (extent) is updated
      // Filters the map data to those points that fall within the selected timeline range
      function brushCallback(brush) {
        if (brush.empty()) {
          (<HTMLSpanElement>document.getElementById('titleYears')).innerHTML = '';
          updateMapPoints(filteredFeaturesBackup);
          //updateTitleText();
        } else {
          var newDateRange = brush.extent();
          var newdata = [];
          geojson.features = filteredFeaturesBackup
          geojson.features.forEach(function(d) {
            if (d.properties['5055'] >= newDateRange[0] && d.properties['5055'] <= newDateRange[1]) {
              newdata.push(d);
            }
          });
          (<HTMLSpanElement>document.getElementById('titleYears')).innerHTML = parseInt(newDateRange[0]) + ' - ' + parseInt(newDateRange[1]);
          updateMapPoints(newdata);
        }
      }
      
      
      // Updates the points displayed on the map, to those in the passed data array
      function updateMapPoints(data) {
        geojson.features = data
        markerclusters.removeLayer(markers)
        markers = L.geoJSON(geojson, {
          pointToLayer: defineFeature,
          onEachFeature: defineFeaturePopup
        });
        markerclusters.addLayer(markers);
        if(geojson.features.length > 0 && geojson.features){
          map.fitBounds(markers.getBounds());
        }
        updateBarChart()
      };


      function buildTimelineData(){
        d3.select("#timeline-container").html("")
        dataForTimeline = [];
        dateToDocCount = {};
        if(filteredFeaturesBackup.length < 2){
          return
        }
        geojson.features = filteredFeaturesBackup
        geojson.features.forEach(function(d) {
          if (!dateToDocCount[d.properties['5055']]) {
            dateToDocCount[d.properties['5055']] = countLocsYear(d);
          }
        });
        Object.keys(dateToDocCount).forEach(function(year) {
          if(year != 'null'){
            dataForTimeline.push({ YEAR: year, TOT: dateToDocCount[year] });
          }
          
          //alert(year+'__'+dateToDocCount[year])
        });
        dataForTimeline.sort(function(a,b) { return a.year - b.year; });
        makeTimeline();
      }

      function countLocsYear(feature){
        var c = 0;
        geojson.features.forEach(function(d) {
          if(feature.properties['5055'] == d.properties['5055'])
            c = c +1
        });
        return c;
      }
      function countLocsBarChart(feature){
        var c = 0;
        geojson.features.forEach(function(d) {
          if(feature.properties['5096'] == d.properties['5096']){
            c = c +1
          }
        });
        return c;
      }
    }
      
      loadLocations(filterValue: string = '', pageIndexReset = true) {
        this.lastFilter = filterValue
        if (pageIndexReset == true) {
          this.paginator.pageIndex = 0
        }
        this.dataSource.loadLocations(
          filterValue,
          this.sort.active,
          this.sort.direction,
          this.paginator.pageIndex,
          this.paginator.pageSize
          )
        }
        refresh(): void {
          this.dataSource.loadLocations(
            this.lastFilter,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
            )
          }
          selectLocation(location: any): void {
            window.open("/placedetail/" + location.id, "_blank")
          }
          delete(locid: number): void {
            let theUserObj = this
            if (confirm('Are you sure you want to remove this location?')) {
              this.documentApi.deleteLocById(locid, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
                value => {
                  console.log(value);
                  if (value.success == 0) {
                    alert(value.description)
                  }
                  this.dataSource.loadLocations(
                    this.lastFilter,
                    this.sort.active,
                    this.sort.direction,
                    this.paginator.pageIndex,
                    this.paginator.pageSize
                    )
                  }
                  )
                }
              }
            }
            