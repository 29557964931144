import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SocialAuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login'
import { ActivatedRoute, Router } from '@angular/router';

//declare var googleyolo: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'notae-frontend';
  auth2: any;
  public user: SocialUser;
  constructor(private socialAuthService: SocialAuthService) { 
    
  }
  public authorized: boolean = false;

  ngOnInit() {
    let provid = new GoogleLoginProvider("934850580232-fcj0tjbn99v18vtsi0bbmfng8s6ii12a.apps.googleusercontent.com")
    provid.initialize().then(() => {
      provid.getLoginStatus().then((user) => {
        this.user = user;
      if (user != null && (user.email == 'leotta@diag.uniroma1.it' || user.email == 'mecella@diag.uniroma1.it'
        || user.email == 'antonella.ghignoli@uniroma1.it' || user.email == 'nina.sietis@uniroma1.it' || user.email == 'anna.monte@uniroma1.it'
        || user.email == 'maria.boccuzzi@uniroma1.it' || user.email == 'mireboccuzzi@gmail.com' || user.email == 'veneruso@diag.uniroma1.it'
        || user.email == 'livia.briasco@uniroma1.it'  || user.email == 'miguel.ceriani@gmail.com'
        || user.email == 'notaeproject.guest@gmail.com' || user.email == 'zahra.ziran@uniroma1.it' || user.email == 'aneta.skalec@uniroma1.it'
        || user.email == 'marta.marucci@uniroma1.it' || user.email == 'luciaconsuelo.colella@uniroma1.it')) {
          this.authorized = true
          //Handle redirect from rewrite url
          /*let newUrl = this.route.snapshot.queryParams['returnUrl']
          if (newUrl == null || newUrl == "/") {
            newUrl = '/documents'
          }
          console.log(newUrl)
          this.router.navigate([newUrl])*/
      } else {
        this.authorized = false
      }
      },error => console.log(JSON.stringify(error)))
    })
    /*this.socialAuthService.authState.subscribe((user) => {
      
    });*/
  }
  onActivate(event) {
    window.scroll(0,0);
  }
  public socialSignIn(socialPlatform: string) {

    let socialPlatformProvider;
    if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (user) => {
        console.log(socialPlatform + " sign in data : ", user);
        // Now sign-in with userData        
        if (user != null && (user.email == 'leotta@diag.uniroma1.it' || user.email == 'mecella@diag.uniroma1.it'
          || user.email == 'antonella.ghignoli@uniroma1.it' || user.email == 'nina.sietis@uniroma1.it' || user.email == 'anna.monte@uniroma1.it'
          || user.email == 'maria.boccuzzi@uniroma1.it' || user.email == 'mireboccuzzi@gmail.com' || user.email == 'veneruso@diag.uniroma1.it'
          || user.email == 'livia.briasco@uniroma1.it' || user.email == 'miguel.ceriani@gmail.com'
          || user.email == 'notaeproject.guest@gmail.com' || user.email == 'zahra.ziran@uniroma1.it' || user.email == 'aneta.skalec@uniroma1.it'
          || user.email == 'marta.marucci@uniroma1.it' || user.email == 'luciaconsuelo.colella@uniroma1.it')) {
          this.authorized = true;
          this.user = user;
        } else {
          this.authorized = false
        }

      }
    );
  }
  public signOut() {
    if (confirm("Are you sure you want to logout?")) {
      this.socialAuthService.signOut();
      this.authorized = false;
    }
  }



}
