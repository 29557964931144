import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormControl, FormArray, FormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'tagDate-field-control',
    templateUrl: './tagDate-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagDateFieldControlComponent),
            multi: true
        }
    ],
    styleUrls: ['./tagDate-field.component.css']
})
export class TagDateFieldControlComponent implements ControlValueAccessor {
  propagateChange = (value: any) => {
    if (value !== undefined && value != null) {
        this.dateList = value;
    }
  };

  writeValue(value: any): void {
      setTimeout(() => {
          if (value !== undefined && value != null) {
              this.dateList = value;
          }
      })
  }
  registerOnChange(fn: any): void {
      this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {
      throw new Error('Method not implemented.');
  }

  name = 'Angular 6';
  public separatorKeysCodes = [ENTER];
  @Input() dateList: string[] = [];
  removable = true;
  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();
  invalidDate = ''

  add(event): void {
    if ((event.value || '').trim()) {
      if (this.validateDate(event.value)) {
        //this.dateList.push({ value: event.value, invalid: false });
        console.log(event.value)
        this.dateList.push(event.value.trim());
      } else {
        //this.dateList.push({ value: event.value, invalid: true });
        this.invalidDate = event.value
        this.rulesForm.controls['dates'].setErrors({'incorrectDate': true});
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }


  removeDate(data: any): void {
    console.log('Removing ' + data)
    if (this.dateList.indexOf(data) >= 0) {
      this.dateList.splice(this.dateList.indexOf(data), 1);
    }
  }

  ngOnInit() {
    this.rulesForm = this.fb.group({
      dates: this.fb.array([], [this.validateArrayNotEmpty]),
    });
  }

  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  private validateDate(date) {
    // regular expression to validate date's format
    var re = /^(?<!ad|bc)(ad|bc)(?!ad|bc)\s([0-9]{3,4})(-[0-9]{3,4})?(, [a-z]{3}(?:.)?(\s([1-9]|[12]\d|3[01]))?)?(?:\s)?(ca.)?(?:\s)?(\(\?\))?$/;
    return re.test(String(date).toLowerCase());
  }

}


