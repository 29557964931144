/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Hand { 
    id?: number;
    /**
     * document id of the doc which the hand belongs to
     */
    documentId?: number;
    /**
     * person id of the person which the hand belongs to
     */
    personId?: number;
    script?: string;
    ordinal?: number;
    /**
     * comment
     */
    comment?: string;
    /**
     * position of the hand
     */
    position?: string;
    /**
     * tags divisi da un separatore tipo ,
     */
    tags?: string;
    role?: string;
    monthCreation?: string;
    monthLastUpdate?: string;
    userCreation?: string;
    userLastUpdate?: string;
    uncertainAssignment?: boolean;
}
