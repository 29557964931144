import { Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import { DefaultService} from '../rest';
import {Location} from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import {AppComponent} from '../app.component'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Observable, fromEvent, merge, of } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { startWith, map, tap, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import * as d3 from 'd3';
import *  as L from 'leaflet';
import { DocumentDataSource } from '../documents/documents.component';


@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit {
  displayedColumns = ['id', 'name', 'wikiUrl', 'latitude', 'longitude', 'year', 'createdAt', 'userCreation', 'role', 'DELETE'];
  displayedDocColumnsOrigin = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'provenance', 'maybeSymbols', 'state', 'digitalReproductions', 'createdAt', 'userCreation'];
  displayedDocColumnsProvenance = ['id', 'PrincipalIdentifier', 'title', 'date', 'language', 'origin', 'provenance', 'maybeSymbols', 'state', 'digitalReproductions', 'createdAt', 'userCreation'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('placePaginator', { read: MatPaginator, static: false }) placePaginator: MatPaginator;
  @ViewChild('placeSort', { read: MatSort, static: false }) placeSort: MatSort;
  @ViewChild('documentPaginatorOri', { read: MatPaginator, static: false }) documentPaginatorOri: MatPaginator;
  @ViewChild('documentSortOri', { read: MatSort, static: false }) documentSortOri: MatSort; 
  @ViewChild('documentPaginatorPro', { read: MatPaginator, static: false }) documentPaginatorPro: MatPaginator;
  @ViewChild('documentSortPro', { read: MatSort, static: false }) documentSortPro: MatSort;
  @ViewChild('lastDocumentFilterVCOri', { static: false }) lastDocumentFilterVCOri: ElementRef;
  @ViewChild('lastDocumentFilterVCPro', { static: false }) lastDocumentFilterVCPro: ElementRef;
  lastDocumentFilter: string = ''
  lastDocumentFilterOri: string = ''
  lastDocumentFilterPro: string = ''
  dataSource = new MatTableDataSource();
  docDataSourceOrigin: DocumentDataSource;
  docDataSourceProvenance: DocumentDataSource;
  documents: any;
  id: any;
  model: any = {
  };
  closeResult: string;
  submitted = false;
  locationId: number = null;
  
  tags: string[] = [];
  suggestedTags: string[] = [];
  alternativeNamesTags: string[] = [];
  urlsTrismegistos: string[] = [];
  urlsPleiades: string[] = [];
  urlsSeeFurther: string[] = [];
  minimap: any = undefined;
  mapMarker: any = undefined;
  // Wiki URL tags variables
  /*visibleWIKI = true;
  selectableWIKI = true;
  removableWIKI = true;
  addOnBlurWIKI = true;
  readonly separatorKeysCodesWIKI: number[] = [ENTER, COMMA];
  urlsWIKI: string[] = [];*/

  constructor(
    private defaultApi: DefaultService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private mainApp: AppComponent,
    private modalService: NgbModal,
    private documentApi: DefaultService
  ) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id') == null) {
      this.id = null
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
    if (this.id != null) {
      this.defaultApi.readLocById(parseInt(this.id), this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          this.model = value;
          this.initOtherTags(this.model)
          this.loadPlaceDocumentsOri()
          this.loadPlaceDocumentsPro()
          this.makeMap()
          //console.log(this.model);
          /*if (this.model.wikiUrl != null){
            if (this.model.wikiUrl.split('||^||').filter(Boolean) > 1) {
              this.urlsWIKI = this.model.wikiUrl.split('||^||').filter(Boolean); // fill wiki url list
            } else { this.urlsWIKI.push(this.model.wikiUrl);}
          } else {this.urlsWIKI = [];} // wiki url list is empty*/
        },
        error => console.error(JSON.stringify(error)),
        //() => console.log('done')
      );
      this.addTagsFromDB();
      this.addSuggestedTagsFromDB();
      this.docDataSourceOrigin = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
      this.docDataSourceProvenance = new DocumentDataSource(this.documentApi, this.mainApp.user.email, this.mainApp.user.idToken)
    } else {
      this.model.state = 'inprocess'
      this.addSuggestedTagsFromDB();
      this.makeMap()
      //this.urlsWIKI = [];
    }
    console.log(history.state)
    //this.makeMap()
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page

    merge(this.documentSortOri.sortChange, this.documentPaginatorOri.page)
      .pipe(
        tap(() => this.loadPlaceDocumentsOri(this.lastDocumentFilterOri, false))
      )
      .subscribe();
    
    merge(this.documentSortPro.sortChange, this.documentPaginatorPro.page)
    .pipe(
      tap(() => this.loadPlaceDocumentsPro(this.lastDocumentFilterPro, false))
    )
    .subscribe();

    fromEvent(this.lastDocumentFilterVCOri.nativeElement, 'keyup').pipe(
        // get value
        map((evt: any) => evt.target.value),
        // text length must be > 2 chars
        //.filter(res => res.length > 2)
        // emit after 1s of silence
        debounceTime(500),
        // emit only if data changes since the last emit       
        distinctUntilChanged())
        // subscription
        .subscribe((text: string) => {
          this.loadPlaceDocumentsOri(text)});

    fromEvent(this.lastDocumentFilterVCPro.nativeElement, 'keyup').pipe(
      // get value
      map((evt: any) => evt.target.value),
      // text length must be > 2 chars
      //.filter(res => res.length > 2)
      // emit after 1s of silence
      debounceTime(500),
      // emit only if data changes since the last emit       
      distinctUntilChanged())
      // subscription
      .subscribe((text: string) => {
        this.loadPlaceDocumentsPro(text)});

  }


  onSubmit(saved) {
    var theUserObj = this;
    //this.submitted = true;
    this.updateOtherTags()
    if (this.id == null) {
      this.defaultApi.createLoc(this.model, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          theUserObj.locationId = value.id;
          this.updateGeneralTagsToDB(value.id, this.tags, "locations", "tags-loc");
          //this.createTagsToDB(value.id);  // create tags entry for the new ps
          //console.log(value);
          theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            theUserObj.router.navigate(['/placedetail/' + theUserObj.locationId])
            theUserObj.closeResult = 'Closed with: ${result}';
          }, (reason) => {
            
            theUserObj.router.navigate(['/placedetail/' + theUserObj.locationId])
            theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
          });
        }
      )
    } else {
      //console.log(this.model)
      this.defaultApi.updateLocById(this.model.id, this.model, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {
          console.log(value);
          this.updateGeneralTagsToDB(value.id, this.tags, "locations", "tags-loc");
          //this.createTagsToDB(value.id);  // create tags entry for the new ps
          theUserObj.modalService.open(saved, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            theUserObj.closeResult = 'Closed with: ${result}';
            theUserObj.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {theUserObj.router.navigate(['/placedetail/' + theUserObj.model.id])});
          }, (reason) => {
            theUserObj.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
            theUserObj.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {theUserObj.router.navigate(['/placedetail/' + theUserObj.model.id])});
          });
        });
    }
  }
  
  goBack(): void {
    if (history.state.docData != null) {
      if (history.state.docData.id != null) {
        this.router.navigate(['/docdetail/' + history.state.docData.id], { state: { docData: history.state.docData } });
      } else {
        this.router.navigate(['/docdetail'], { state: { docData: history.state.docData } });
      }
    } else {
      this.location.back();
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadPlaceDocumentsOri(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentFilterOri = filterValue
    if (pageIndexReset == true) {
      this.documentPaginatorOri.pageIndex = 0
    }
    this.docDataSourceOrigin.loadPlaceDocuments(
      this.model.id,
      filterValue + '**^**origin',
      this.documentSortOri.active,
      this.documentSortOri.direction,
      this.documentPaginatorOri.pageIndex,
      this.documentPaginatorOri.pageSize
    )
  }

  loadPlaceDocumentsPro(filterValue: string = '', pageIndexReset = true) {
    this.lastDocumentFilterPro = filterValue
    if (pageIndexReset == true) {
      this.documentPaginatorPro.pageIndex = 0
    }
    this.docDataSourceProvenance.loadPlaceDocuments(
      this.model.id,
      filterValue + '**^**provenance',
      this.documentSortPro.active,
      this.documentSortPro.direction,
      this.documentPaginatorPro.pageIndex,
      this.documentPaginatorPro.pageSize
    )
  }

  modifyDocument(doc): void {
    window.open('/docdetail/' + doc.id, '_blank');
  }
  
  private addTagsFromDB(): void {
    this.defaultApi.retrieveTags('locations', this.id, 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value.value);
          this.tags = value.value.split('||^||').filter(Boolean);
        }
      }
    );
  }

  private addSuggestedTagsFromDB(): void {
    this.defaultApi.retrieveSuggestedTags('locations', 'tags-loc', this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
      value => {
        if (value != null) {
          //console.log(value);
          this.suggestedTags = value.suggestions.split('||^||').filter(Boolean);
          this.suggestedTags = this.suggestedTags.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;}) // delete duplicates
        }
      }
    );
  }

  private updateGeneralTagsToDB(id: any, tags: any, table_name: string, table_field_name: string): void {
    let updatedTags = {
      table: table_name,
      table_id: id,
      tagged_field: table_field_name,
      value: ""
    }
    if (tags != null) {
      tags.forEach(function (item, index) {
        updatedTags.value = updatedTags.value.concat(item);
        updatedTags.value = updatedTags.value.concat('||^||');
      });
      this.defaultApi.updateTags(id, updatedTags, this.mainApp.user.email, this.mainApp.user.idToken).subscribe(
        value => {}
      )
    }
  }

  private initOtherTags(model){
    if (model.alternativeNames != '' && model.alternativeNames != null){
      this.alternativeNamesTags = model.alternativeNames.split('||^||').filter(Boolean);
    }
    if (model.urlsTrismegistos != '' && model.urlsTrismegistos != null){
      this.urlsTrismegistos = model.urlsTrismegistos.split('||^||').filter(Boolean);
    }
    if (model.urlsPleiades != '' && model.urlsPleiades != null){
      this.urlsPleiades = model.urlsPleiades.split('||^||').filter(Boolean);
    }
    if (model.urlsSeeFurther != '' && model.urlsSeeFurther != null){
      this.urlsSeeFurther = model.urlsSeeFurther.split('||^||').filter(Boolean);
    }
  }

  private updateOtherTags(){
    // alternative names
    var typeBUFFER = ''
    this.alternativeNamesTags.forEach(name => {
      typeBUFFER = typeBUFFER.concat(name)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      this.model.alternativeNames = typeBUFFER
    } else {this.model.alternativeNames = ''}
    //trismegistos url
    typeBUFFER = ''
    this.urlsTrismegistos.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      this.model.urlsTrismegistos = typeBUFFER
    } else {this.model.urlsTrismegistos = ''}
    //pleiades url
    typeBUFFER = ''
    this.urlsPleiades.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      this.model.urlsPleiades = typeBUFFER
    } else {this.model.urlsPleiades = ''}
    //see further url
    typeBUFFER = ''
    this.urlsSeeFurther.forEach(url => {
      typeBUFFER = typeBUFFER.concat(url)
      typeBUFFER = typeBUFFER.concat('||^||')//delimiter
    });
    if (typeBUFFER != ''){
      this.model.urlsSeeFurther = typeBUFFER
    } else {this.model.urlsSeeFurther = ''}
  }

  openURLWindow(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }

  /*makeMap(){
    var latitude, longitude;
    if (this.model.latitude!='' && this.model.longitude!=''){
      latitude = this.model.latitude;
      longitude = this.model.longitude;
    }
    var geojson,
      //tileServer = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileServer = 'http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      tileAttribution = 'Map data: <a href="http://openstreetmap.org">OSM</a>',
      marker,
      latitude,
      longitude,
      map = L.map('map').setView([20, 30], 4);

    //Add basemap
    L.tileLayer(tileServer, {attribution: tileAttribution,  maxZoom: 15, noWrap: true}).addTo(map);

    var psIcon = L.icon({
      iconUrl: './assets/images/psIcon.png'
    });

    var event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });


    map.on('click', function(e) {
      if(marker){
        map.removeLayer(marker)
      }
      marker = L.marker((<any>e).latlng, {icon: psIcon}).addTo(map);
      latitude = (<any>e).latlng.lat;
      longitude = (<any>e).latlng.lng;
      var popupContent = '<span class="attribute"><span class="label">'+'place'+':</span> '+(<HTMLInputElement>document.getElementById('name')).value+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Latitude'+':</span> '+latitude+'</span>'
      popupContent +=  '<span class="attribute"><span class="label">'+'Longitude'+':</span> '+longitude+'</span>'
      popupContent = '<div class="map-popup">'+popupContent+'</div>';
      marker.bindPopup(popupContent,{offset: L.point(1,-2)});
      (<HTMLInputElement>document.getElementById('longitude')).value = longitude;
      (<HTMLInputElement>document.getElementById('latitude')).value = latitude;
      document.getElementById('latitude').dispatchEvent(new Event('input'))
      document.getElementById('longitude').dispatchEvent(new Event('input'))
    });

    d3.select("#map-container")
      .style('display','none')
      .style('position','fixed')
      .style('top','20%')
      .style('right',0)
      .style('left',0)
      .style('bottom',0)
      .style('z-index','1');
      
    d3.select('#close')
      .style('position','absolute')
      .style('display','block')
      .style('top','1%')
      .style('right','17.5%')
      .style('background-color','red')
      .style('border-radius','10px')
      .style('color','white')
      .style('padding','10px 15px 10px 15px')
      .style('margin','auto')
      .style('z-index','1000')
      .on('mouseover',function (d){
        d3.select(this).style('cursor','pointer')
      });

    d3.select('#showMapBtn').on('click', function(){
      d3.select("#map-container").style('display','block');
      d3.select("#addPSLocContainer").style('filter','blur(12px)');
    });
    d3.select('#close').on('click', function(){
      d3.select("#map-container").style('display','none');
      d3.select("#addPSLocContainer").style('filter','none');
    })

  }*/
  
  makeMap(){
    this.minimap = L.map('map').setView([20, 40], 2 );
    
    if (this.model.latitude!=undefined && this.model.longitude!=undefined){
      this.minimap = this.minimap.remove()
      this.minimap = L.map('map').setView([this.model.latitude, this.model.longitude], 4);
    }
    L.tileLayer('http://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Map data: <a href="http://openstreetmap.org">OSM</a>'
    }).addTo(this.minimap);
    var icon = {
      icon: L.icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 0 ],
        iconUrl: './assets/images/marker-icon.png',
        shadowUrl: './assets/images/marker-shadow.png'
      })
    };
      if (this.model.latitude!=undefined  && this.model.longitude!=undefined){
        this.mapMarker = L.marker([this.model.latitude, this.model.longitude], icon).addTo(this.minimap);
      }
  }

  updateMapLatitude() {
    if (this.model.longitude == undefined){
      this.minimap = this.minimap.setView([this.model.latitude, 0], 4);
    } else {
      this.minimap = this.minimap.setView([this.model.latitude, this.model.longitude], 4);
    }
    if (this.model.latitude!=undefined  && this.model.longitude!=undefined){
      if (this.mapMarker!=undefined){
        this.minimap.removeLayer(this.mapMarker)
      }
      var icon = {
        icon: L.icon({
          iconSize: [ 25, 41 ],
          iconAnchor: [ 13, 0 ],
          iconUrl: './assets/images/marker-icon.png',
          shadowUrl: './assets/images/marker-shadow.png'
        })
      };
      this.mapMarker = L.marker([this.model.latitude, this.model.longitude], icon).addTo(this.minimap);
    }
  }

  updateMapLongitude() {
    if (this.model.latitude == undefined ){
      this.minimap = this.minimap.setView([0, this.model.longitude], 4);
    } else {
      this.minimap = this.minimap.setView([this.model.latitude, this.model.longitude], 4);
    }
    if (this.model.latitude!=undefined  && this.model.longitude!=undefined){
      if (this.mapMarker!=undefined){
        this.minimap.removeLayer(this.mapMarker)
      }
      var icon = {
        icon: L.icon({
          iconSize: [ 25, 41 ],
          iconAnchor: [ 13, 0 ],
          iconUrl: './assets/images/marker-icon.png',
          shadowUrl: './assets/images/marker-shadow.png'
        })
      };
      this.mapMarker = L.marker([this.model.latitude, this.model.longitude], icon).addTo(this.minimap);
    }
  }


  // Wiki URL tags code
  /*addWIKITag(event: MatChipInputEvent): void {
    const input = event.input == null ? event : event.input;
    const value = event.value;

    if ((value || '').trim()) { // Add the url
      this.urlsWIKI.push(value.trim());
    }
    if (input) { // Reset the input value
      input.value = '';
    }
  }

  removeWIKITag(url: string): void {
    const index = this.urlsWIKI.indexOf(url);

    if (index >= 0) {
      this.urlsWIKI.splice(index, 1);
    }
  }

  openURLWIKI(url: string): void {
    let fixed_url: string = '';
    if (!/^http[s]?:\/\//.test(url.trim())) {
      fixed_url += 'http://';
    }
    fixed_url += url.trim();
    window.open(fixed_url, '_blank');
  }*/

}
