import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, startWith } from 'rxjs/operators';

@Component({
    selector: 'tag-field-control',
    templateUrl: './tag-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagFieldControlComponent),
            multi: true
        }
    ],
    styleUrls: ['./tag-field.component.css']
})
export class TagFieldControlComponent implements ControlValueAccessor {
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

    propagateChange = (value: any) => {
        if (value !== undefined && value != null) {
            this.rawValue = value;
        }
    };

    writeValue(value: any): void {
        setTimeout(() => {
            if (value !== undefined && value != null) {
                this.rawValue = value;
            }
        })
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {

    }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    @Input() rawValue: String[] = []
    @Input() editorLabel = "NO LABEL"
    @Input() suggestedTags: string[] = null;
    @Input() removable = true
    @Input() clickable = false
    @Input() autocompleteActive = true
    @Input() insertPlaceholder = "Add new TAG..."

    @Output() onChipClicked: EventEmitter<any> = new EventEmitter();

    chipClicked(clickedEntry: string): void {
        if (this.clickable == true) {
            this.onChipClicked.emit([clickedEntry]);
        }
    }

    lastFingerSelection = null
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new FormControl();
    filteredTags: Observable<string[]>  = this.tagCtrl.valueChanges.pipe(
            startWith(''),
            map((tag: string | null) => tag ? this._filter(tag) : this.suggestedTags.slice())
            );

    add(event: MatChipInputEvent): void {
        const input = event.input == null ? event : event.input;
        const value = event.value;
        if (this.lastFingerSelection == null || !this.lastFingerSelection.startsWith(value.trim())) {
            // Add tag
            if ((value || '').trim()) {
                this.rawValue.push(value.trim());
            }
            // Reset the input value
            if (input) {
                input.value = '';
            }
            this.tagCtrl.setValue(null);
        }
        this.lastFingerSelection = null
    }

    remove(tag: string): void {
        const index = this.rawValue.indexOf(tag);
        if (index >= 0) {
            this.rawValue.splice(index, 1);
        }
    }

    selected(selectedValue, tagInput): void {
        this.rawValue.push(selectedValue.option.viewValue);
        tagInput.value = '';
        this.tagCtrl.setValue(null);
        this.lastFingerSelection = null
    }

    activated(selectedValue, tagInput): void {
        if (selectedValue.option != null) {
            tagInput.value = selectedValue.option.viewValue;
            this.lastFingerSelection = selectedValue.option.viewValue;
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (this.suggestedTags != null) {
            if (value == '') {
                return this.suggestedTags.slice()
            } else {
                return this.suggestedTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
            }
        } else {
            return []
        }
    }

    addOnBlur(event: FocusEvent, tagInput) {
        const target: HTMLElement = event.relatedTarget as HTMLElement;
        if (!target || target.tagName !== 'MAT-OPTION') {
          const matChipEvent: MatChipInputEvent = {input: tagInput, value : tagInput.value};
          this.add(matChipEvent);
        }
      }

      focusFunction(event: FocusEvent) {
        this.tagCtrl.setValue(null)
      }
}