/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Location { 
    /**
     * ID of location
     */
    id?: number;
    /**
     * location
     */
    name?: string;
    /**
     * alternative names with delimiter ||^||
     */
    alternativeNames?: string;
    /**
     * link to the wikipedia page of the location
     */
    wikiUrl?: string;
    /**
     * latitude
     */
    latitude?: string;
    /**
     * longitude
     */
    longitude?: string;
    /**
     * year
     */
    year?: string;
    monthCreation?: string;
    monthLastUpdate?: string;
    userCreation?: string;
    userLastUpdate?: string;
}
