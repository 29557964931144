/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookSeries { 
    /**
     * internal ID of the book
     */
    id?: number;
    /**
     * The abbreviation of the series
     */
    abbreviation?: string;
    /**
     * The extended title of the series
     */
    extendedTitle?: string;
    monthCreation?: string;
    monthLastUpdate?: string;
    userCreation?: string;
    userLastUpdate?: string;
    containerBookSeries?: BookSeries;
}
