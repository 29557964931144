import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'advanced-editor-control',
    templateUrl: './advanced-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdvancedEditorControlComponent),
            multi: true
        }
    ]
})
export class AdvancedEditorControlComponent implements ControlValueAccessor {
    propagateChange = (value: any) => {
        if (value !== undefined) {
            this.rawValue = value;
        }
    };

    writeValue(value: any): void {
        if (value !== undefined) {
            this.rawValue = value;
        }
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {

    }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    @Input() rawValue = ""
    @Input() editorLabel = "NO LABEL"

    //CUSTOM METHODS
    /* Person comment Editor - Formatting Styles */
    isEditBtnClicked = false;
    editorSelectionStart = 0;
    editorSelectionEnd = 0;

    preventLosingFocus(event) {
        event.preventDefault()
    }
    
    showEditor(textArea) {
        this.isEditBtnClicked = true;
        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            textArea.focus();
          },0); 
    }
    
    hideEditor(textArea) {
        this.setCaretPosition(textArea, 0, 0);
        this.editorSelectionStart = 0;
        this.editorSelectionEnd = 0;
        this.isEditBtnClicked = false;
    }

    getSelectedText(textArea) {
        var elem = textArea as HTMLTextAreaElement;
        this.editorSelectionStart = elem.selectionStart;
        this.editorSelectionEnd = elem.selectionEnd;
        //console.log(this.editorSelectionStart)
        //console.log(this.editorSelectionEnd)
    }

    setCaretPosition(textArea, initPos, endPos) {
        if(textArea.createTextRange) {
            var range = textArea.createTextRange();
            range.moveEnd('character', initPos)
            range.moveStart('character', endPos)
            range.select()
        } else {
            textArea.focus();
            textArea.setSelectionRange(initPos, endPos);
        }
    }

    ApplyModificator(textArea, modOpen) {
        if ((this.editorSelectionEnd - this.editorSelectionStart) > 0) {

            var isSelectionModified = false;
            if (this.rawValue.slice(this.editorSelectionStart - 3, this.editorSelectionStart) == "<" + modOpen + ">"
                && this.rawValue.slice(this.editorSelectionEnd, this.editorSelectionEnd + 4) == "</" + modOpen + ">")
                isSelectionModified = true;

            if (!isSelectionModified) { //add modifier tags
                var elem = textArea as HTMLTextAreaElement;
                var moddedText = elem.value.slice(0, this.editorSelectionStart) +
                "<" + modOpen + ">" + elem.value.slice(this.editorSelectionStart, this.editorSelectionEnd) + "</" + modOpen + ">"
                    + elem.value.slice(this.editorSelectionEnd);
                this.editorSelectionStart += 3;
                this.editorSelectionEnd += 3;
            } else { //remove modifier tags
                var elem = textArea as HTMLTextAreaElement;
                var moddedText = elem.value.slice(0, this.editorSelectionStart - 3)
                    + elem.value.slice(this.editorSelectionStart, this.editorSelectionEnd)
                    + elem.value.slice(this.editorSelectionEnd + 4);
                this.editorSelectionStart -= 3;
                this.editorSelectionEnd -= 3;
            }
            this.rawValue = moddedText;
            setTimeout(()=>{ // this will make the execution after the above boolean has changed
                this.setCaretPosition(textArea, this.editorSelectionStart, this.editorSelectionEnd)
              },0);
        }
        this.propagateChange(this.rawValue);
    }
}