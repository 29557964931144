/**
 * Notae App
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Bibliography } from '../model/bibliography';
import { Book } from '../model/book';
import { Location } from '../model/location';
import { BookSeries } from '../model/bookSeries';
import { Document } from '../model/document';
import { DownloadFileResponse } from '../model/downloadFileResponse';
import { ErrorResponse } from '../model/errorResponse';
import { GeneralResponse } from '../model/generalResponse';
import { GetBibliographyListResponse } from '../model/getBibliographyListResponse';
import { GetBookListResponse } from '../model/getBookListResponse';
import { GetBookSeriesListResponse } from '../model/getBookSeriesListResponse';
import { GetDocumentListResponse } from '../model/getDocumentListResponse';
import { GetGraphicSymbolListResponse } from '../model/getGraphicSymbolListResponse';
import { GetHandListResponse } from '../model/getHandListResponse';
import { GetPersonListResponse } from '../model/getPersonListResponse';
import { GetPhysicalSupportsListResponse } from '../model/getPhysicalSupportsListResponse';
import { GetPublicationListResponse } from '../model/getPublicationListResponse';
import { GetReferenceListResponse } from '../model/getReferenceListResponse';
import { GraphicSymbol } from '../model/graphicSymbol';
import { Hand } from '../model/hand';
import { Person } from '../model/person';
import { PhysicalSupport } from '../model/physicalSupport';
import { Publication } from '../model/publication';
import { Reference } from '../model/reference';
import { Tags } from '../model/tags';
import { TagsSuggestionResponse } from '../model/tagsSuggestionResponse';
import { UploadFileResponse } from '../model/uploadFileResponse';
import { OriginalArchiveListResponse } from '../model/originalArchiveListResponse';
import { HandRoleListResponse } from '../model/handRoleListResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { GetLocationsListResponse } from '../model/getLocationsListResponse';


@Injectable()
export class DefaultService {

    protected basePath = 'http://localhost:10010';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * add a new physical support to the list
     * @param physicalSupport PhysicalSupport properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<PhysicalSupport>;
    public create(physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PhysicalSupport>>;
    public create(physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PhysicalSupport>>;
    public create(physicalSupport: PhysicalSupport, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (physicalSupport === null || physicalSupport === undefined) {
            throw new Error('Required parameter physicalSupport was null or undefined when calling create.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling create.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PhysicalSupport>(`${this.basePath}/physicalSupport`,
            physicalSupport,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new book to the list
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBibliography(book: Bibliography, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Bibliography>;
    public createBibliography(book: Bibliography, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bibliography>>;
    public createBibliography(book: Bibliography, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bibliography>>;
    public createBibliography(book: Bibliography, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling createBibliography.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createBibliography.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createBibliography.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Bibliography>(`${this.basePath}/bibliography`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new book to the list
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBook(book: Book, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Book>;
    public createBook(book: Book, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Book>>;
    public createBook(book: Book, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Book>>;
    public createBook(book: Book, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling createBook.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createBook.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createBook.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Book>(`${this.basePath}/book`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new book to the list
     * @param bookSeries book series properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBookSeries(bookSeries: BookSeries, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<BookSeries>;
    public createBookSeries(bookSeries: BookSeries, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookSeries>>;
    public createBookSeries(bookSeries: BookSeries, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookSeries>>;
    public createBookSeries(bookSeries: BookSeries, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookSeries === null || bookSeries === undefined) {
            throw new Error('Required parameter bookSeries was null or undefined when calling createBookSeries.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createBookSeries.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createBookSeries.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookSeries>(`${this.basePath}/bookSeries`,
            bookSeries,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of locations
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public readAllLocations(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetLocationsListResponse>;
     public readAllLocations(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetLocationsListResponse>>;
     public readAllLocations(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetLocationsListResponse>>;
     public readAllLocations(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling readAllLocations.');
         }
 
         if (key === null || key === undefined) {
             throw new Error('Required parameter key was null or undefined when calling readAllLocations.');
         }
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (offset !== undefined && offset !== null) {
             queryParameters = queryParameters.set('offset', <any>offset);
         }
         if (limit !== undefined && limit !== null) {
             queryParameters = queryParameters.set('limit', <any>limit);
         }
         if (sortingField !== undefined && sortingField !== null) {
             queryParameters = queryParameters.set('sortingField', <any>sortingField);
         }
         if (sortingOrder !== undefined && sortingOrder !== null) {
             queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
         }
         if (filter !== undefined && filter !== null) {
             queryParameters = queryParameters.set('filter', <any>filter);
         }
 
         let headers = this.defaultHeaders;
         if (username !== undefined && username !== null) {
             headers = headers.set('username', String(username));
         }
         if (key !== undefined && key !== null) {
             headers = headers.set('key', String(key));
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get<GetLocationsListResponse>(`${this.basePath}/location`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     } 

    /**
     * 
     * get a location
     * @param lid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public readLocById(lid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Location>;
     public readLocById(lid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
     public readLocById(lid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
     public readLocById(lid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (lid === null || lid === undefined) {
             throw new Error('Required parameter lid was null or undefined when calling readLocById.');
         }
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling readLocById.');
         }
 
         if (key === null || key === undefined) {
             throw new Error('Required parameter key was null or undefined when calling readLocById.');
         }
 
         let headers = this.defaultHeaders;
         if (username !== undefined && username !== null) {
             headers = headers.set('username', String(username));
         }
         if (key !== undefined && key !== null) {
             headers = headers.set('key', String(key));
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get<Location>(`${this.basePath}/location/${encodeURIComponent(String(lid))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
     * 
     * add a new document to the list
     * @param pSid physicalSupport id
     * @param document document part properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDPPS(pSid: number, document: Document, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createDPPS(pSid: number, document: Document, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createDPPS(pSid: number, document: Document, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createDPPS(pSid: number, document: Document, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling createDPPS.');
        }

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling createDPPS.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createDPPS.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createDPPS.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}/document`,
            document,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new document to the list
     * @param document document properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDoc(document: Document, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Document>;
    public createDoc(document: Document, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Document>>;
    public createDoc(document: Document, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Document>>;
    public createDoc(document: Document, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling createDoc.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createDoc.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Document>(`${this.basePath}/document`,
            document,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new graphic symbol to the list
     * @param docid document  id
     * @param hid Hand id
     * @param graphicSymbol graphic symbol properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGS(docid: number, hid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GraphicSymbol>;
    public createGS(docid: number, hid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GraphicSymbol>>;
    public createGS(docid: number, hid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GraphicSymbol>>;
    public createGS(docid: number, hid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling createGS.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling createGS.');
        }

        if (graphicSymbol === null || graphicSymbol === undefined) {
            throw new Error('Required parameter graphicSymbol was null or undefined when calling createGS.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createGS.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createGS.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GraphicSymbol>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}/graphicSymbol`,
            graphicSymbol,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new hand to the list
     * @param docid document id
     * @param hand hand properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createH(docid: number, hand: Hand, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Hand>;
    public createH(docid: number, hand: Hand, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hand>>;
    public createH(docid: number, hand: Hand, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hand>>;
    public createH(docid: number, hand: Hand, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling createH.');
        }

        if (hand === null || hand === undefined) {
            throw new Error('Required parameter hand was null or undefined when calling createH.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createH.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createH.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Hand>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand`,
            hand,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new person to the list
     * @param person Person properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createP(person: Person, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createP(person: Person, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createP(person: Person, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createP(person: Person, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (person === null || person === undefined) {
            throw new Error('Required parameter person was null or undefined when calling createP.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createP.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createP.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/person`,
            person,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new book to the list
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPublication(book: Publication, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Publication>;
    public createPublication(book: Publication, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Publication>>;
    public createPublication(book: Publication, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Publication>>;
    public createPublication(book: Publication, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling createPublication.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createPublication.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createPublication.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Publication>(`${this.basePath}/publication`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new book to the list
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createReference(book: Reference, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Reference>;
    public createReference(book: Reference, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reference>>;
    public createReference(book: Reference, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reference>>;
    public createReference(book: Reference, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling createReference.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createReference.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createReference.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Reference>(`${this.basePath}/reference`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create tags
     * 
     * @param tagsEntry Tags entry properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTags(tagsEntry: Tags, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Tags>;
    public createTags(tagsEntry: Tags, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tags>>;
    public createTags(tagsEntry: Tags, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tags>>;
    public createTags(tagsEntry: Tags, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tagsEntry === null || tagsEntry === undefined) {
            throw new Error('Required parameter tagsEntry was null or undefined when calling createTags.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling createTags.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling createTags.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Tags>(`${this.basePath}/tags`,
            tagsEntry,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a book
     * @param pid publication id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBibliographyId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteBibliographyId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteBibliographyId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteBibliographyId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling deleteBibliographyId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteBibliographyId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteBibliographyId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/bibliography/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a book
     * @param bid book id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBookId(bid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteBookId(bid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteBookId(bid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteBookId(bid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling deleteBookId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteBookId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteBookId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/book/${encodeURIComponent(String(bid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a book
     * @param bid book id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBookSeriesById(bid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteBookSeriesById(bid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteBookSeriesById(bid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteBookSeriesById(bid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling deleteBookSeriesById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteBookSeriesById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteBookSeriesById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/bookSeries/${encodeURIComponent(String(bid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a physical support
     * @param pSid physicalSupport id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteById(pSid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteById(pSid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteById(pSid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteById(pSid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling deleteById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a documente part of a physical support
     * @param pSid physical support id
     * @param docid document part id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDPPSid(pSid: number, docid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteDPPSid(pSid: number, docid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteDPPSid(pSid: number, docid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteDPPSid(pSid: number, docid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling deleteDPPSid.');
        }

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling deleteDPPSid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteDPPSid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteDPPSid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}/document/${encodeURIComponent(String(docid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a document
     * @param docid document id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDoc(docid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteDoc(docid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteDoc(docid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteDoc(docid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling deleteDoc.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteDoc.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a graphic symbol
     * @param docid document id
     * @param hid Hand id
     * @param gSid graphic symbol id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling deleteGSid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling deleteGSid.');
        }

        if (gSid === null || gSid === undefined) {
            throw new Error('Required parameter gSid was null or undefined when calling deleteGSid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteGSid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteGSid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}/graphicSymbol/${encodeURIComponent(String(gSid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a hand
     * @param docid Document id
     * @param hid hand id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteHid(docid: number, hid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteHid(docid: number, hid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteHid(docid: number, hid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteHid(docid: number, hid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling deleteHid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling deleteHid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteHid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteHid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a person
     * @param pid person id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePid(pid: string, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deletePid(pid: string, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deletePid(pid: string, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deletePid(pid: string, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling deletePid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deletePid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deletePid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/person/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a book
     * @param pid publication id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePublicationId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deletePublicationId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deletePublicationId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deletePublicationId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling deletePublicationId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deletePublicationId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deletePublicationId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/publication/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a book
     * @param pid publication id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReferenceId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
    public deleteReferenceId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
    public deleteReferenceId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
    public deleteReferenceId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling deleteReferenceId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteReferenceId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteReferenceId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GeneralResponse>(`${this.basePath}/reference/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download a file.
     * 
     * @param fileId 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileDownload(fileId: string, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<DownloadFileResponse>;
    public fileDownload(fileId: string, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadFileResponse>>;
    public fileDownload(fileId: string, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadFileResponse>>;
    public fileDownload(fileId: string, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling fileDownload.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling fileDownload.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling fileDownload.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DownloadFileResponse>(`${this.basePath}/fileservice/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a file.
     * 
     * @param username username
     * @param key key to the API
     * @param upfile The file to upload.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileUpload(username: string, key: string, upfile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadFileResponse>;
    public fileUpload(username: string, key: string, upfile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFileResponse>>;
    public fileUpload(username: string, key: string, upfile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFileResponse>>;
    public fileUpload(username: string, key: string, upfile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling fileUpload.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling fileUpload.');
        }


        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (upfile !== undefined) {
            formParams = formParams.append('upfile', <any>upfile) || formParams;
        }

        return this.httpClient.post<UploadFileResponse>(`${this.basePath}/fileservice`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

/**
     * Upload an enhanced file (GS).
     * 
     * @param username username
     * @param key key to the API
     * @param upfile The file to upload.
     * @param originalImageID The id of the original image version.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
public fileENHANCEDUpload(username: string, key: string, upfile?: Blob, originalImageID?: string, observe?: 'body', reportProgress?: boolean): Observable<UploadFileResponse>;
public fileENHANCEDUpload(username: string, key: string, upfile?: Blob, originalImageID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFileResponse>>;
public fileENHANCEDUpload(username: string, key: string, upfile?: Blob, originalImageID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFileResponse>>;
public fileENHANCEDUpload(username: string, key: string, upfile?: Blob, originalImageID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (username === null || username === undefined) {
        throw new Error('Required parameter username was null or undefined when calling fileENHANCEDUpload.');
    }

    if (key === null || key === undefined) {
        throw new Error('Required parameter key was null or undefined when calling fileENHANCEDUpload.');
    }



    let headers = this.defaultHeaders;
    if (username !== undefined && username !== null) {
        headers = headers.set('username', String(username));
    }
    if (key !== undefined && key !== null) {
        headers = headers.set('key', String(key));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams; };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
        formParams = new FormData();
    } else {
        formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (upfile !== undefined) {
        formParams = formParams.append('upfile', <any>upfile) || formParams;
    }
    if (originalImageID !== undefined) {
        formParams = formParams.append('originalImageID', <any>originalImageID) || formParams;
    }

    return this.httpClient.post<UploadFileResponse>(`${this.basePath}/fileservice/enhanced`,
        convertFormParamsToString ? formParams.toString() : formParams,
        {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
}

    /**
     * Retrieve Hand by its ID
     * 
     * @param handId 
     * @param username 
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHand(handId: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Hand>;
    public getHand(handId: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hand>>;
    public getHand(handId: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hand>>;
    public getHand(handId: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (handId === null || handId === undefined) {
            throw new Error('Required parameter handId was null or undefined when calling getHand.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getHand.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling getHand.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (handId !== undefined && handId !== null) {
            queryParameters = queryParameters.set('hand_id', <any>handId);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Hand>(`${this.basePath}/hand`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of physical supports
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAll(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetPhysicalSupportsListResponse>;
    public readAll(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPhysicalSupportsListResponse>>;
    public readAll(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPhysicalSupportsListResponse>>;
    public readAll(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAll.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAll.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetPhysicalSupportsListResponse>(`${this.basePath}/physicalSupport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of publications
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param bookSeriesList 
     * @param bookList 
     * @param typeList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllBibliographies(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, typeList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetBibliographyListResponse>;
    public readAllBibliographies(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, typeList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBibliographyListResponse>>;
    public readAllBibliographies(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, typeList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBibliographyListResponse>>;
    public readAllBibliographies(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, typeList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllBibliographies.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllBibliographies.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (bookSeriesList) {
            bookSeriesList.forEach((element) => {
                queryParameters = queryParameters.append('bookSeriesList', <any>element);
            })
        }
        if (bookList) {
            bookList.forEach((element) => {
                queryParameters = queryParameters.append('bookList', <any>element);
            })
        }
        if (typeList) {
            typeList.forEach((element) => {
                queryParameters = queryParameters.append('typeList', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetBibliographyListResponse>(`${this.basePath}/bibliography`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of document
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllDoc(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetDocumentListResponse>;
    public readAllDoc(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDocumentListResponse>>;
    public readAllDoc(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDocumentListResponse>>;
    public readAllDoc(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllDoc.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDocumentListResponse>(`${this.basePath}/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of graphic symbol
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllGS(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGraphicSymbolListResponse>;
    public readAllGS(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGraphicSymbolListResponse>>;
    public readAllGS(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGraphicSymbolListResponse>>;
    public readAllGS(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllGS.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllGS.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetGraphicSymbolListResponse>(`${this.basePath}/graphicSymbol`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of document
     * 
     * @param pid 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllPersonDoc(pid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetDocumentListResponse>;
    public readAllPersonDoc(pid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDocumentListResponse>>;
    public readAllPersonDoc(pid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDocumentListResponse>>;
    public readAllPersonDoc(pid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling readAllPersonDoc.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllPersonDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllPersonDoc.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDocumentListResponse>(`${this.basePath}/person/${encodeURIComponent(String(pid))}/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of publications
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param bookSeriesList 
     * @param bookList 
     * @param citedAsList 
     * @param typeList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllPublications(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetPublicationListResponse>;
    public readAllPublications(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPublicationListResponse>>;
    public readAllPublications(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPublicationListResponse>>;
    public readAllPublications(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllPublications.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllPublications.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (bookSeriesList) {
            bookSeriesList.forEach((element) => {
                queryParameters = queryParameters.append('bookSeriesList', <any>element);
            })
        }
        if (bookList) {
            bookList.forEach((element) => {
                queryParameters = queryParameters.append('bookList', <any>element);
            })
        }
        if (citedAsList) {
            citedAsList.forEach((element) => {
                queryParameters = queryParameters.append('citedAsList', <any>element);
            })
        }
        if (typeList) {
            typeList.forEach((element) => {
                queryParameters = queryParameters.append('typeList', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetPublicationListResponse>(`${this.basePath}/publication`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of publications
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param bookSeriesList 
     * @param bookList 
     * @param citedAsList 
     * @param typeList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAllReferences(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetReferenceListResponse>;
    public readAllReferences(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetReferenceListResponse>>;
    public readAllReferences(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetReferenceListResponse>>;
    public readAllReferences(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookList?: Array<number>, citedAsList?: Array<string>, typeList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllReferences.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllReferences.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (bookSeriesList) {
            bookSeriesList.forEach((element) => {
                queryParameters = queryParameters.append('bookSeriesList', <any>element);
            })
        }
        if (bookList) {
            bookList.forEach((element) => {
                queryParameters = queryParameters.append('bookList', <any>element);
            })
        }
        if (citedAsList) {
            citedAsList.forEach((element) => {
                queryParameters = queryParameters.append('citedAsList', <any>element);
            })
        }
        if (typeList) {
            typeList.forEach((element) => {
                queryParameters = queryParameters.append('typeList', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetReferenceListResponse>(`${this.basePath}/reference`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a publication
     * @param pid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBibliographyId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Bibliography>;
    public readBibliographyId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bibliography>>;
    public readBibliographyId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bibliography>>;
    public readBibliographyId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling readBibliographyId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readBibliographyId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readBibliographyId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Bibliography>(`${this.basePath}/bibliography/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of book
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param bookSeriesList 
     * @param bookId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBook(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookId?: number, observe?: 'body', reportProgress?: boolean): Observable<GetBookListResponse>;
    public readBook(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBookListResponse>>;
    public readBook(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBookListResponse>>;
    public readBook(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesList?: Array<number>, bookId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readBook.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readBook.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (bookSeriesList) {
            bookSeriesList.forEach((element) => {
                queryParameters = queryParameters.append('bookSeriesList', <any>element);
            })
        }
        if (bookId !== undefined && bookId !== null) {
            queryParameters = queryParameters.set('bookId', <any>bookId);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetBookListResponse>(`${this.basePath}/book`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a book
     * @param bid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBookId(bid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Book>;
    public readBookId(bid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Book>>;
    public readBookId(bid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Book>>;
    public readBookId(bid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling readBookId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readBookId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readBookId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Book>(`${this.basePath}/book/${encodeURIComponent(String(bid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of book series
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param bookSeriesId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBookSeries(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesId?: number, observe?: 'body', reportProgress?: boolean): Observable<GetBookSeriesListResponse>;
    public readBookSeries(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBookSeriesListResponse>>;
    public readBookSeries(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBookSeriesListResponse>>;
    public readBookSeries(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, bookSeriesId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readBookSeries.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readBookSeries.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (bookSeriesId !== undefined && bookSeriesId !== null) {
            queryParameters = queryParameters.set('bookSeriesId', <any>bookSeriesId);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetBookSeriesListResponse>(`${this.basePath}/bookSeries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a book
     * @param bid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBookSeriesById(bid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<BookSeries>;
    public readBookSeriesById(bid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookSeries>>;
    public readBookSeriesById(bid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookSeries>>;
    public readBookSeriesById(bid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling readBookSeriesById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readBookSeriesById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readBookSeriesById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookSeries>(`${this.basePath}/bookSeries/${encodeURIComponent(String(bid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a physicalSupport
     * @param pSid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readById(pSid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<PhysicalSupport>;
    public readById(pSid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PhysicalSupport>>;
    public readById(pSid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PhysicalSupport>>;
    public readById(pSid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling readById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PhysicalSupport>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of documents of a physical support
     * 
     * @param pSid physicalSupport id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDPPS(pSid: string, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GetDocumentListResponse>;
    public readDPPS(pSid: string, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDocumentListResponse>>;
    public readDPPS(pSid: string, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDocumentListResponse>>;
    public readDPPS(pSid: string, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling readDPPS.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readDPPS.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readDPPS.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDocumentListResponse>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}/document`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a document
     * @param docid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDocId(docid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Document>;
    public readDocId(docid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Document>>;
    public readDocId(docid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Document>>;
    public readDocId(docid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readDocId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readDocId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readDocId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Document>(`${this.basePath}/document/${encodeURIComponent(String(docid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of graphic symbol of a document part
     * 
     * @param docid document id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readGS(docid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GetGraphicSymbolListResponse>;
    public readGS(docid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGraphicSymbolListResponse>>;
    public readGS(docid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGraphicSymbolListResponse>>;
    public readGS(docid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readGS.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readGS.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readGS.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetGraphicSymbolListResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/graphicSymbol`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of graphic symbol of a hand
     * 
     * @param docid doc id
     * @param hid Hand id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readGSH(docid: number, hid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GetGraphicSymbolListResponse>;
    public readGSH(docid: number, hid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGraphicSymbolListResponse>>;
    public readGSH(docid: number, hid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGraphicSymbolListResponse>>;
    public readGSH(docid: number, hid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readGSH.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling readGSH.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readGSH.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readGSH.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetGraphicSymbolListResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}/graphicSymbol`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a graphic symbol
     * @param docid 
     * @param hid Hand id
     * @param gSid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GraphicSymbol>;
    public readGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GraphicSymbol>>;
    public readGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GraphicSymbol>>;
    public readGSid(docid: number, hid: number, gSid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readGSid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling readGSid.');
        }

        if (gSid === null || gSid === undefined) {
            throw new Error('Required parameter gSid was null or undefined when calling readGSid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readGSid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readGSid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GraphicSymbol>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}/graphicSymbol/${encodeURIComponent(String(gSid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return data to initialize map
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public readGeoMapData(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetLocationsListResponse>;
     public readGeoMapData(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetLocationsListResponse>>;
     public readGeoMapData(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetLocationsListResponse>>;
     public readGeoMapData(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling readGeoMapData.');
         }
 
         if (key === null || key === undefined) {
             throw new Error('Required parameter key was null or undefined when calling readGeoMapData.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (offset !== undefined && offset !== null) {
             queryParameters = queryParameters.set('offset', <any>offset);
         }
         if (limit !== undefined && limit !== null) {
             queryParameters = queryParameters.set('limit', <any>limit);
         }
         if (sortingField !== undefined && sortingField !== null) {
             queryParameters = queryParameters.set('sortingField', <any>sortingField);
         }
         if (sortingOrder !== undefined && sortingOrder !== null) {
             queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
         }
         if (filter !== undefined && filter !== null) {
             queryParameters = queryParameters.set('filter', <any>filter);
         }
 
         let headers = this.defaultHeaders;
         if (username !== undefined && username !== null) {
             headers = headers.set('username', String(username));
         }
         if (key !== undefined && key !== null) {
             headers = headers.set('key', String(key));
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.get<GetLocationsListResponse>(`${this.basePath}/location/map`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
     * return a list of hand
     * 
     * @param docid document id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readH(docid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GetHandListResponse>;
    public readH(docid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetHandListResponse>>;
    public readH(docid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetHandListResponse>>;
    public readH(docid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readH.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readH.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readH.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetHandListResponse>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a hand
     * @param docid 
     * @param hid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readHid(docid: number, hid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Hand>;
    public readHid(docid: number, hid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hand>>;
    public readHid(docid: number, hid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hand>>;
    public readHid(docid: number, hid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling readHid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling readHid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readHid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readHid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Hand>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return a list of person
     * 
     * @param username username
     * @param key key to the API
     * @param offset The number of items to skip before starting to collect the result set.
     * @param limit The numbers of items to return.
     * @param sortingField 
     * @param sortingOrder 
     * @param filter Filter Query
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readP(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetPersonListResponse>;
    public readP(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPersonListResponse>>;
    public readP(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPersonListResponse>>;
    public readP(username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readP.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readP.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetPersonListResponse>(`${this.basePath}/person`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a person
     * @param pid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readPid(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Person>;
    public readPid(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Person>>;
    public readPid(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Person>>;
    public readPid(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling readPid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readPid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readPid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Person>(`${this.basePath}/person/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a publication
     * @param pid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readPublicationId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Publication>;
    public readPublicationId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Publication>>;
    public readPublicationId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Publication>>;
    public readPublicationId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling readPublicationId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readPublicationId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readPublicationId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Publication>(`${this.basePath}/publication/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * get a publication
     * @param pid 
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readReferenceId(pid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Reference>;
    public readReferenceId(pid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reference>>;
    public readReferenceId(pid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reference>>;
    public readReferenceId(pid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling readReferenceId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readReferenceId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readReferenceId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Reference>(`${this.basePath}/reference/${encodeURIComponent(String(pid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve suggested tags for a certain tagged-field
     * 
     * @param table 
     * @param taggedField 
     * @param username 
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveSuggestedTags(table: string, taggedField: string, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<TagsSuggestionResponse>;
    public retrieveSuggestedTags(table: string, taggedField: string, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagsSuggestionResponse>>;
    public retrieveSuggestedTags(table: string, taggedField: string, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagsSuggestionResponse>>;
    public retrieveSuggestedTags(table: string, taggedField: string, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (table === null || table === undefined) {
            throw new Error('Required parameter table was null or undefined when calling retrieveSuggestedTags.');
        }

        if (taggedField === null || taggedField === undefined) {
            throw new Error('Required parameter taggedField was null or undefined when calling retrieveSuggestedTags.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling retrieveSuggestedTags.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling retrieveSuggestedTags.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (table !== undefined && table !== null) {
            queryParameters = queryParameters.set('table', <any>table);
        }
        if (taggedField !== undefined && taggedField !== null) {
            queryParameters = queryParameters.set('tagged_field', <any>taggedField);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TagsSuggestionResponse>(`${this.basePath}/tags/suggestions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve tags
     * 
     * @param table 
     * @param tableId 
     * @param taggedField 
     * @param username 
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveTags(table: string, tableId: number, taggedField: string, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Tags>;
    public retrieveTags(table: string, tableId: number, taggedField: string, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tags>>;
    public retrieveTags(table: string, tableId: number, taggedField: string, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tags>>;
    public retrieveTags(table: string, tableId: number, taggedField: string, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (table === null || table === undefined) {
            throw new Error('Required parameter table was null or undefined when calling retrieveTags.');
        }

        if (tableId === null || tableId === undefined) {
            throw new Error('Required parameter tableId was null or undefined when calling retrieveTags.');
        }

        if (taggedField === null || taggedField === undefined) {
            throw new Error('Required parameter taggedField was null or undefined when calling retrieveTags.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling retrieveTags.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling retrieveTags.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (table !== undefined && table !== null) {
            queryParameters = queryParameters.set('table', <any>table);
        }
        if (tableId !== undefined && tableId !== null) {
            queryParameters = queryParameters.set('table_id', <any>tableId);
        }
        if (taggedField !== undefined && taggedField !== null) {
            queryParameters = queryParameters.set('tagged_field', <any>taggedField);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Tags>(`${this.basePath}/tags`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get entity tuple
     * 
     * @param entity The table from the db that I want back
     * @param tags Tags to filter by
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchGET(entity: string, tags: Array<string>, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public searchGET(entity: string, tags: Array<string>, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public searchGET(entity: string, tags: Array<string>, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public searchGET(entity: string, tags: Array<string>, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entity === null || entity === undefined) {
            throw new Error('Required parameter entity was null or undefined when calling searchGET.');
        }

        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling searchGET.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling searchGET.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling searchGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (entity !== undefined && entity !== null) {
            queryParameters = queryParameters.set('entity', <any>entity);
        }
        if (tags) {
            tags.forEach((element) => {
                queryParameters = queryParameters.append('tags', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a publication
     * @param pid book id
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBibliographyId(pid: number, book: Bibliography, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Bibliography>;
    public updateBibliographyId(pid: number, book: Bibliography, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bibliography>>;
    public updateBibliographyId(pid: number, book: Bibliography, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bibliography>>;
    public updateBibliographyId(pid: number, book: Bibliography, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling updateBibliographyId.');
        }

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling updateBibliographyId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateBibliographyId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateBibliographyId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Bibliography>(`${this.basePath}/bibliography/${encodeURIComponent(String(pid))}`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a book
     * @param bid book id
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookId(bid: number, book: Book, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Book>;
    public updateBookId(bid: number, book: Book, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Book>>;
    public updateBookId(bid: number, book: Book, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Book>>;
    public updateBookId(bid: number, book: Book, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling updateBookId.');
        }

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling updateBookId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateBookId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateBookId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Book>(`${this.basePath}/book/${encodeURIComponent(String(bid))}`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a book
     * @param bid book id
     * @param bookSeries book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookSeriesById(bid: number, bookSeries: BookSeries, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<BookSeries>;
    public updateBookSeriesById(bid: number, bookSeries: BookSeries, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookSeries>>;
    public updateBookSeriesById(bid: number, bookSeries: BookSeries, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookSeries>>;
    public updateBookSeriesById(bid: number, bookSeries: BookSeries, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bid === null || bid === undefined) {
            throw new Error('Required parameter bid was null or undefined when calling updateBookSeriesById.');
        }

        if (bookSeries === null || bookSeries === undefined) {
            throw new Error('Required parameter bookSeries was null or undefined when calling updateBookSeriesById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateBookSeriesById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateBookSeriesById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BookSeries>(`${this.basePath}/bookSeries/${encodeURIComponent(String(bid))}`,
            bookSeries,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a physical support
     * @param pSid physicalSupport id
     * @param physicalSupport physical support properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateById(pSid: number, physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<PhysicalSupport>;
    public updateById(pSid: number, physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PhysicalSupport>>;
    public updateById(pSid: number, physicalSupport: PhysicalSupport, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PhysicalSupport>>;
    public updateById(pSid: number, physicalSupport: PhysicalSupport, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pSid === null || pSid === undefined) {
            throw new Error('Required parameter pSid was null or undefined when calling updateById.');
        }

        if (physicalSupport === null || physicalSupport === undefined) {
            throw new Error('Required parameter physicalSupport was null or undefined when calling updateById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PhysicalSupport>(`${this.basePath}/physicalSupport/${encodeURIComponent(String(pSid))}`,
            physicalSupport,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a document
     * @param docid document id
     * @param document document properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDoc(docid: number, document: Document, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Document>;
    public updateDoc(docid: number, document: Document, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Document>>;
    public updateDoc(docid: number, document: Document, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Document>>;
    public updateDoc(docid: number, document: Document, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling updateDoc.');
        }

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling updateDoc.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateDoc.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Document>(`${this.basePath}/document/${encodeURIComponent(String(docid))}`,
            document,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a graphic Symbol
     * @param docid document id
     * @param hid Hand id
     * @param gSid graphic symbol id
     * @param graphicSymbol graphic symbol properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGSid(docid: number, hid: number, gSid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GraphicSymbol>;
    public updateGSid(docid: number, hid: number, gSid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GraphicSymbol>>;
    public updateGSid(docid: number, hid: number, gSid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GraphicSymbol>>;
    public updateGSid(docid: number, hid: number, gSid: number, graphicSymbol: GraphicSymbol, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling updateGSid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling updateGSid.');
        }

        if (gSid === null || gSid === undefined) {
            throw new Error('Required parameter gSid was null or undefined when calling updateGSid.');
        }

        if (graphicSymbol === null || graphicSymbol === undefined) {
            throw new Error('Required parameter graphicSymbol was null or undefined when calling updateGSid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateGSid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateGSid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GraphicSymbol>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}/graphicSymbol/${encodeURIComponent(String(gSid))}`,
            graphicSymbol,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a hand
     * @param docid Document id
     * @param hid hand id
     * @param hand hand properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHid(docid: number, hid: number, hand: Hand, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Hand>;
    public updateHid(docid: number, hid: number, hand: Hand, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hand>>;
    public updateHid(docid: number, hid: number, hand: Hand, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hand>>;
    public updateHid(docid: number, hid: number, hand: Hand, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docid === null || docid === undefined) {
            throw new Error('Required parameter docid was null or undefined when calling updateHid.');
        }

        if (hid === null || hid === undefined) {
            throw new Error('Required parameter hid was null or undefined when calling updateHid.');
        }

        if (hand === null || hand === undefined) {
            throw new Error('Required parameter hand was null or undefined when calling updateHid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateHid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateHid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Hand>(`${this.basePath}/document/${encodeURIComponent(String(docid))}/hand/${encodeURIComponent(String(hid))}`,
            hand,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a person
     * @param pid person id
     * @param person person properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePid(pid: string, person: Person, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Person>;
    public updatePid(pid: string, person: Person, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Person>>;
    public updatePid(pid: string, person: Person, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Person>>;
    public updatePid(pid: string, person: Person, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling updatePid.');
        }

        if (person === null || person === undefined) {
            throw new Error('Required parameter person was null or undefined when calling updatePid.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updatePid.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updatePid.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Person>(`${this.basePath}/person/${encodeURIComponent(String(pid))}`,
            person,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a publication
     * @param pid book id
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublicationId(pid: number, book: Publication, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Publication>;
    public updatePublicationId(pid: number, book: Publication, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Publication>>;
    public updatePublicationId(pid: number, book: Publication, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Publication>>;
    public updatePublicationId(pid: number, book: Publication, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling updatePublicationId.');
        }

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling updatePublicationId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updatePublicationId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updatePublicationId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Publication>(`${this.basePath}/publication/${encodeURIComponent(String(pid))}`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * add a new location to the list
     * @param location Location properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public createLoc(location: Location, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Location>;
     public createLoc(location: Location, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
     public createLoc(location: Location, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
     public createLoc(location: Location, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (location === null || location === undefined) {
             throw new Error('Required parameter location was null or undefined when calling createLoc.');
         }
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling createLoc.');
         }
 
         if (key === null || key === undefined) {
             throw new Error('Required parameter key was null or undefined when calling createLoc.');
         }
 
         let headers = this.defaultHeaders;
         if (username !== undefined && username !== null) {
             headers = headers.set('username', String(username));
         }
         if (key !== undefined && key !== null) {
             headers = headers.set('key', String(key));
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.post<Location>(`${this.basePath}/location`,
             location,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     * 
     * update a location
     * @param lid location id
     * @param location location properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLocById(lid: number, location: Location, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public updateLocById(lid: number, location: Location, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public updateLocById(lid: number, location: Location, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    public updateLocById(lid: number, location: Location, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lid === null || lid === undefined) {
            throw new Error('Required parameter lid was null or undefined when calling updateLocById.');
        }

        if (location === null || location === undefined) {
            throw new Error('Required parameter location was null or undefined when calling updateLocById.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateLocById.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateLocById.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Location>(`${this.basePath}/location/${encodeURIComponent(String(lid))}`,
            location,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * update a publication
     * @param pid book id
     * @param book book properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReferenceId(pid: number, book: Reference, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Reference>;
    public updateReferenceId(pid: number, book: Reference, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reference>>;
    public updateReferenceId(pid: number, book: Reference, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reference>>;
    public updateReferenceId(pid: number, book: Reference, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling updateReferenceId.');
        }

        if (book === null || book === undefined) {
            throw new Error('Required parameter book was null or undefined when calling updateReferenceId.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateReferenceId.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateReferenceId.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Reference>(`${this.basePath}/reference/${encodeURIComponent(String(pid))}`,
            book,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tags
     * 
     * @param tagId 
     * @param tags Tags entry properties
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTags(tagId: number, tags: Tags, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Tags>;
    public updateTags(tagId: number, tags: Tags, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tags>>;
    public updateTags(tagId: number, tags: Tags, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tags>>;
    public updateTags(tagId: number, tags: Tags, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling updateTags.');
        }

        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling updateTags.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateTags.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling updateTags.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tagId !== undefined && tagId !== null) {
            queryParameters = queryParameters.set('tag_id', <any>tagId);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Tags>(`${this.basePath}/tags`,
            tags,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * delete a location
     * @param lid location id
     * @param username username
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public deleteLocById(lid: number, username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GeneralResponse>;
     public deleteLocById(lid: number, username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeneralResponse>>;
     public deleteLocById(lid: number, username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeneralResponse>>;
     public deleteLocById(lid: number, username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (lid === null || lid === undefined) {
             throw new Error('Required parameter lid was null or undefined when calling deleteLocById.');
         }
 
         if (username === null || username === undefined) {
             throw new Error('Required parameter username was null or undefined when calling deleteLocById.');
         }
 
         if (key === null || key === undefined) {
             throw new Error('Required parameter key was null or undefined when calling deleteLocById.');
         }
 
         let headers = this.defaultHeaders;
         if (username !== undefined && username !== null) {
             headers = headers.set('username', String(username));
         }
         if (key !== undefined && key !== null) {
             headers = headers.set('key', String(key));
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
 
         return this.httpClient.delete<GeneralResponse>(`${this.basePath}/location/${encodeURIComponent(String(lid))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }


    /**
     * Retrieve the list of original archives
     * 
     * @param username 
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getOriginalArchiveList(username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<OriginalArchiveListResponse>;
         public getOriginalArchiveList(username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OriginalArchiveListResponse>>;
         public getOriginalArchiveList(username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OriginalArchiveListResponse>>;
         public getOriginalArchiveList(username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (username === null || username === undefined) {
                 throw new Error('Required parameter username was null or undefined when calling getOriginalArchiveList.');
             }
     
             if (key === null || key === undefined) {
                 throw new Error('Required parameter key was null or undefined when calling getOriginalArchiveList.');
             }
     
             let headers = this.defaultHeaders;
             if (username !== undefined && username !== null) {
                 headers = headers.set('username', String(username));
             }
             if (key !== undefined && key !== null) {
                 headers = headers.set('key', String(key));
             }
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
                 'application/json'
             ];
     
             return this.httpClient.get<OriginalArchiveListResponse>(`${this.basePath}/originalArchiveList`,
                 {
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }

         
        /**
             * Retrieve the list of roles from hands
             * 
             * @param username 
             * @param key key to the API
             * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
             * @param reportProgress flag to report request and response progress.
             */
        public getHandRoleList(username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<HandRoleListResponse>;
        public getHandRoleList(username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HandRoleListResponse>>;
        public getHandRoleList(username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HandRoleListResponse>>;
        public getHandRoleList(username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

            if (username === null || username === undefined) {
                throw new Error('Required parameter username was null or undefined when calling getHandRoleList.');
            }

            if (key === null || key === undefined) {
                throw new Error('Required parameter key was null or undefined when calling getHandRoleList.');
            }

            let headers = this.defaultHeaders;
            if (username !== undefined && username !== null) {
                headers = headers.set('username', String(username));
            }
            if (key !== undefined && key !== null) {
                headers = headers.set('key', String(key));
            }

            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }

            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];

            return this.httpClient.get<HandRoleListResponse>(`${this.basePath}/handRoleList`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }


        /**
     * Retrieve the list of persons
     * 
     * @param username 
     * @param key key to the API
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPersonList(username: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<GetPersonListResponse>;
    public getPersonList(username: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPersonListResponse>>;
    public getPersonList(username: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPersonListResponse>>;
    public getPersonList(username: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getPersonList.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling getPersonList.');
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetPersonListResponse>(`${this.basePath}/personList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
         * return a list of document for the Place Lid
         * 
         * @param lid 
         * @param username username
         * @param key key to the API
         * @param offset The number of items to skip before starting to collect the result set.
         * @param limit The numbers of items to return.
         * @param sortingField 
         * @param sortingOrder 
         * @param filter Filter Query
         * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
         * @param reportProgress flag to report request and response progress.
         */
    public readAllPlaceDoc(lid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetDocumentListResponse>;
    public readAllPlaceDoc(lid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDocumentListResponse>>;
    public readAllPlaceDoc(lid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDocumentListResponse>>;
    public readAllPlaceDoc(lid: number, username: string, key: string, offset?: number, limit?: number, sortingField?: string, sortingOrder?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lid === null || lid === undefined) {
            throw new Error('Required parameter lid was null or undefined when calling readAllPlaceDoc.');
        }

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling readAllPlaceDoc.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling readAllPlaceDoc.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sortingField !== undefined && sortingField !== null) {
            queryParameters = queryParameters.set('sortingField', <any>sortingField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;
        if (username !== undefined && username !== null) {
            headers = headers.set('username', String(username));
        }
        if (key !== undefined && key !== null) {
            headers = headers.set('key', String(key));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDocumentListResponse>(`${this.basePath}/location/${encodeURIComponent(String(lid))}/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
         
}